import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';

import { MyUtil } from '../../../libs/MyUtil';

@Component({
  selector: 'page-location-map',
  templateUrl: 'location-map.html',
  styleUrls: ['location-map.scss']
})
export class LocationMapPage {
  pageData: any = {};

  constructor(private viewCtrl: ModalController, public router: Router, public navParams: NavParams) {
    this.pageData.venue = navParams.get('venue');
    this.pageData.lat = navParams.get('lat');
    this.pageData.long = navParams.get('long');
  }

  ionViewDidLoad() {
    MyUtil.firebaseSetScreenName('location-map');
  }

  ionViewDidEnter() {
    MyUtil.firebaseLogEvent('view_did_enter', {name: 'location-map', data: this.navParams.data});
  }

  ionViewDidLeave() {
    MyUtil.firebaseLogEvent('view_did_leave', {name: 'location-map', data: this.navParams.data});
  }

  dismiss() {
    this.viewCtrl.dismiss();
  }
}
