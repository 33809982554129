import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavController, NavParams } from '@ionic/angular';
import { NgxCopilotService } from 'ngx-copilot';
import { Appcalc } from 'src/app/providers/appcalc';
import { WalkthroughService } from 'src/app/services/walkthrough.service';
import { MyUtil } from 'src/libs/MyUtil';
import { Appapi } from '../../providers/appapi';
import { DesktopChecker } from '../../providers/desktopChecker';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'page-user',
  templateUrl: 'user.html'
})
export class UserPage implements OnInit {

  // Component variables
  @ViewChild('Content', { static: true }) content;
  pageData: any = {};
  pageLabel = 'UserPage';

  constructor(
    public appcalc: Appcalc,
    public navCtrl: NavController,
    private copilot: NgxCopilotService,
    public router: Router,
    public navParams: NavParams,
    public zone: NgZone,
    public appapi: Appapi,
    public desktopChecker: DesktopChecker,
    private titleService:Title) {
      this.titleService.setTitle("My Skills"); 
    }

  filterToggle = false;

  setFilterToggle(){
      this.filterToggle = !this.filterToggle;
  }

  ngOnInit(): void {

    if (!this.appapi.isLoggedIn()) {
      return;
    }

    let loading = MyUtil.presentLoading();

    // expand help for first time landing on the page
    this.pageData.helperToggle = false;
    this.pageData.pdpHelperToggle = false;
    this.pageData.badgesHelperToggle = false;
    this.pageData.displayHelpIcon = true;

    this.pageData.badges = {};

    if (!MyUtil.context.helpStatus[MyUtil.HELP_ID.USER]) {
      // avoid expand if redirect from goals
      if (this.navParams.get('segment') !== 'pdp') {
        this.pageData.helperToggle = true;
        this.appapi.setAppHelpStatus(MyUtil.HELP_ID.USER, true);
      }
    }

    if (!MyUtil.context.helpStatus[MyUtil.HELP_ID.PDP]) {
      if (this.navParams.get('segment') !== 'user') {
        this.pageData.pdpHelperToggle = true;
        this.appapi.setAppHelpStatus(MyUtil.HELP_ID.PDP, true);
      }
    }

    if (!MyUtil.context.helpStatus[MyUtil.HELP_ID.BADGES]) {
      if (this.navParams.get('segment') !== 'badges') {
        this.pageData.badgesHelperToggle = true;
        this.appapi.setAppHelpStatus(MyUtil.HELP_ID.BADGES, true);
      }
    }

    this.pageData.segment = this.navParams.get('segment');
    if (!this.pageData.segment) {
      this.pageData.segment = 'profile';
    }

    this.pageData.filter = {};
    this.pageData.activePaths = [];
    this.pageData.paths = [];

    
    // init intervals for display
    this.pageData.chartIntervals = this.getFilterIntervals();
    let chartInterval = +MyUtil.retrieveFromLocalStorage(MyUtil.DOC_ID.USER_PROFILE_CHART_INTERVAL);
    if(chartInterval === undefined) {
      if(this.pageData.chartIntervals.length > 0) {
        chartInterval = this.pageData.chartIntervals[0].id;
      } 
    }

    // init organizations for display
    this.pageData.chartOrgs = this.getFilterOrganizations();
    let chartOrgId = MyUtil.retrieveFromLocalStorage(MyUtil.DOC_ID.USER_PROFILE_CHART_ORG_ID);
    if(!chartOrgId) {
      //Default to the first in the array - the first affiliation
      if(this.pageData.chartOrgs.length > 0) {
        chartOrgId = this.pageData.chartOrgs[0].id;
      } 
    } else {
      //Check if this chartOrgId is in the this.pageData.chartOrgs array. (Might not be if orgs switched)
      let found = false;
      this.pageData.chartOrgs.forEach(item => {
        if (chartOrgId == item.id) {
          found = true;
        }
      });
      if (!found) {
        chartOrgId = this.pageData.chartOrgs[0].id;
      }
    }

    this.updateFilters(chartInterval, chartOrgId);

    this.appcalc.refreshCompletedActivities().subscribe(() => {

      MyUtil.firebaseSetScreenName('user');

      MyUtil.firebaseLogEvent('view_did_enter', { name: 'user', data: this.navParams.data });

      // refresh organizations for display
      this.pageData.chartOrgs = this.getFilterOrganizations();

      //Careers now defunct
      /* // refresh user PDP saved careers
      let loading = MyUtil.presentLoading();
      this.appapi.getSavedCareerPaths()
        .then(async res => {
          this.pageData.activePaths = res['#data'].filter((path) => path.pivot.active === 1);
          this.pageData.paths = res['#data'].filter((path) => path.pivot.active === 0);
          (await loading).dismiss();
        })
        .catch(async err => (await loading).dismiss()); */

      
        
      this.appapi.getUserBadges().then(async (res) => {
        if (res['#status'] === 'success'
          && res['#data']) {
          this.pageData.badges = res['#data'];
        }

        (await loading).dismiss();
      });
    });

    this.startWalkthrough()

  }

  /** 
  * Start walkthrough.
  */
  startWalkthrough() {
    if (!WalkthroughService.isWalkthroughComplete(this.pageLabel) && !WalkthroughService.allWalkthroughsDisabled()) {
      setTimeout(() => {
        this.copilot.checkInit('24');
      }, 1000);
    }
    this.pageData.displayHelpIcon = !WalkthroughService.allWalkthroughsDisabled();
  }

  /** 
  * Re initialize and specify step.
  * @param stepNumber   stepNumber: string.
  */
  initPosition = (stepNumber: any) => this.copilot.checkInit(stepNumber);

  /** 
  * Next step.
  * @param stepNumber   stepNumber: string.
  */
  nextStep = (stepNumber: any) => this.copilot.next(stepNumber);

  /** 
  * Finish copilot walkthroughs.
  */
  done = () => this.copilot.removeWrapper();

  /** 
  * Set Walkthrough state. 
  * @param pageName   Name of page
  * @param value      Boolean - (Has been visited or not)
  */
  setWalkthroughStateHandler(pageName: string, value: boolean) {
    WalkthroughService.setWalkthroughState(pageName, value)
  }

  /** 
  * Update filters.
  * @param chartInterval
  * @param chartOrgId
  */
  updateFilters(chartInterval, chartOrgId) {

    // default home institute data
    if (!chartOrgId) {
      chartOrgId = MyUtil.getRootOrganization().id; 
    }

    this.pageData.chartPrograms = MyUtil.getPrograms(chartOrgId);
    
    let compactArray = [];
    for (let i in this.pageData.chartPrograms) {
      if (chartOrgId == this.pageData.chartPrograms[i].oid) {
        compactArray.push(this.pageData.chartPrograms[i]);
      }
    }

    this.pageData.chartPrograms = compactArray.filter(el => MyUtil.getProfileProgramIds().includes(el.id));
    this.pageData.chartPrograms.push({ id: 0, name: "Show all my skills", oid: null });
    let chartProgramId = compactArray.filter(el => MyUtil.getProfileProgramIds().includes(el.id)).map(elem => elem.id)[0];

    this.pageData.chartOptions = {
      interval: chartInterval ? chartInterval : 0, // default all data
      orgId: chartOrgId,
      programId: chartProgramId ? chartProgramId : 0,
    };
    
    // init intervals for display
    this.pageData.chartIntervals = this.getFilterIntervals();
  }

  /** 
  * Update programe Id's.
  */
  updateProgramIds() {
    this.updateFilters(this.pageData.chartOptions.interval, this.pageData.chartOptions.orgId);
  }

  /** 
  * On segment change.
  */
  onSegmentChange() {
    if (!MyUtil.context.helpStatus[MyUtil.HELP_ID.USER]) {
      if (this.pageData.segment === 'profile') {
        this.pageData.helperToggle = true;
        this.pageData.pdpHelperToggle = false;
        this.pageData.badgesHelperToggle = false;
        this.appapi.setAppHelpStatus(MyUtil.HELP_ID.USER, true);
      }
    }

    if (!MyUtil.context.helpStatus[MyUtil.HELP_ID.PDP]) {
      if (this.pageData.segment === 'pdp') {
        this.pageData.helperToggle = false;
        this.pageData.pdpHelperToggle = true;
        this.pageData.badgesHelperToggle = false;
        this.appapi.setAppHelpStatus(MyUtil.HELP_ID.PDP, true);
      }
    }

    if (!MyUtil.context.helpStatus[MyUtil.HELP_ID.BADGES]) {
      if (this.pageData.segment === 'badges') {
        this.pageData.helperToggle = false;
        this.pageData.pdpHelperToggle = false;
        this.pageData.badgesHelperToggle = true;
        this.appapi.setAppHelpStatus(MyUtil.HELP_ID.BADGES, true);
      }
    }
  }

  ionViewDidLeave() {
    MyUtil.firebaseLogEvent('view_did_leave', { name: 'user', data: this.navParams.data });
  }

  /** 
  * Scroll handler.
  */
  scrollHandler(event) {
    this.zone.run(() => {
      //close filter when scrolling
      if (this.pageData.filter.toggle && event.scrollTop > 120) {
        this.pageData.filter.toggle = false;
      }
    })
  }

  /** 
  * Get filter intervals.
  */
  private getFilterIntervals() {
    return [
      {
        id: 0,
        name: 'All data'
      },
      {
        id: 1,
        name: 'Last year'
      },
      {
        id: 2,
        name: 'Last quarter'
      },
      {
        id: 3,
        name: 'Last month'
      },
    ];
  }

  /** 
  * Get filter Organisations.
  */
  private getFilterOrganizations() {
    let result = [];

    //Add affiliate organisations (list first because these more relevant to user with an affiliation)
    MyUtil.lodash.forEach(MyUtil.getProfile().fundings, (item) => {
      //TODO: change to pass in root_funding_id when back-end API refactor sorted
      let fundingRoot = MyUtil.getRootOrganization(item.funding_id, true);
      // console.log('funding', item)
      if (fundingRoot && !fundingRoot.pending) {
        let org = MyUtil.cache[MyUtil.DOC_ID.USER_FUNDING_ORGANIZATIONS][fundingRoot.id];
        if (org) {
          result.push({
            id: org.id,
            name: org.name
          });
        }
      }
    });

    // add home institute organization
    let homeOrg = MyUtil.getRootOrganization();
    result.push({
      id: homeOrg.id,
      name: homeOrg.name
    });

    return result;
  }

  
}
