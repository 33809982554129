import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-terms-modal',
  templateUrl: './terms-modal.component.html',
  styleUrls: ['./terms-modal.component.scss'],
})
export class TermsModalComponent implements OnInit {

  // Component variables.
  public data: any;
  public label: any = 0;
  enabled = false;

  constructor(public params: NavParams, public viewCtrl: ModalController) {
    if (params) {
      this.data = params.data;
    }
  }

  // Handle action.
  handleAction() {
    this.viewCtrl.dismiss(this.label);
  }

  checkboxChange(event){
    this.enabled = event.checked;
  }

  ngOnInit() {}

  // Close Modal.
  closeModal() {
    this.viewCtrl.dismiss();
  }

}
