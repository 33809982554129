import { Diagnostic } from "src/app/valueObjects/diagnostics.vo";
import * as activityActions from "../actions/view.action";
export interface ViewState {
  entities: { [id: number]: Activity },
  loaded: boolean;
  loading: boolean;
  selectedActivityId?:number;
  displayMode?:string;
  displayTheme?:string;
  fontSize?:string;
  walkthroughLabel?:string;
  redirectTarget?:string;
  branding?:string;
  username?:string;
  organization?:string;
  menuStateSet?:string;
  sso: any;
  appToOnboarding:any;
  findActivitiesFilters:FindActivitiesFilters;
  diagnostics: Diagnostic[];
}



export const initialState: ViewState = {
  entities: {},
  loaded: false,
  loading: false,
  selectedActivityId: null,
  displayMode: 'dark-mode',
  displayTheme:'static-theme',
  fontSize: 'small-font',
  walkthroughLabel: '',
  redirectTarget: '',
  branding: '',
  username: '',
  organization: '',
  menuStateSet: 'inactive-mobile-menu',
  sso: {},
  appToOnboarding:{},
  findActivitiesFilters: undefined,
  diagnostics: []
}

export interface FindActivitiesFilters {
  searchTerm: string;
  organisations: any[];
  attendanceType: any[];
  skills: any[];
  start: string;
  end: string;
}

export interface Activity {
  id: number;
  title: string;
}

export function reducer(
  state = initialState,
  action: activityActions.ViewAction
): ViewState {

  switch (action.type) {

    
    case activityActions.DIAGNOSTICS_SET: {
      return {
        ...state,
        diagnostics: action.payload
      }
    };
    break;
    
    case activityActions.REDIRECT_TARGET_SET: {
      return {
        ...state,
        redirectTarget: action.payload
      }
    };
    break;
    
    case activityActions.USERNAME_SET: {
      return {
        ...state,
        username: action.payload
      }
    };
    break;

    case activityActions.ORGANIZATION_SET: {
      return {
        ...state,
        organization: action.payload
      }
    };
    break;
    
    case activityActions.FIND_ACTIVITIES_FILTER_STATE_SET: {
      return {
        ...state,
        findActivitiesFilters: action.payload
      }
    };
    break;

    case activityActions.MENU_STATE_SET: {
      return {
        ...state,
        menuStateSet: action.payload
      }
    };
    break;

    case activityActions.APP_TO_ONBOARDING_SET: {
      return {
        ...state,
        appToOnboarding: action.payload
      }
    };
    break;

    case activityActions.SSO_SET: {
      return {
        ...state,
        sso: action.payload
      }
    };
    break;

    case activityActions.BRANDING_SET: {
      return {
        ...state,
        branding: action.payload
      }
    };
    break;
    case activityActions.WALKTHROUGH_LABEL_SET: {
      return {
        ...state,
        walkthroughLabel: action.payload
      }
    };
    break;

    case activityActions.FONT_SIZE_SET: {
      return {
        ...state,
        loading: true,
        fontSize: action.payload
      }
    };
    break;

    case activityActions.DISPLAY_MODE_SET: {
      return {
        ...state,
        loading: true,
        displayMode: action.payload
      }
    };
    break;

    case activityActions.DISPLAY_THEME_SET: {
      return {
        ...state,
        loading: true,
        displayTheme: action.payload
      }
    };
    break;

    case activityActions.ACTIVITY_SELECTED_ID_SET: {
      return {
        ...state,
        loading: true,
        selectedActivityId: action.payload
      }
    };
    break;

    case activityActions.LOAD_ACTIVITY: {
      return {
        ...state,
        loading: true
      }
    };
    break;

    case activityActions.LOAD_ACTIVITY_SUCCESS: {
      const data = action.payload;
      const entities = data.reduce((entities: { [id: number]: Activity }, activity) => {
        return {
          ...entities,
          [activity.id]: activity
        };
      }, {
        ...state.entities
      })

      return {
        ...state,
        loading: false,
        loaded: true,
        entities
      }
    };
      break;

    case activityActions.LOAD_ACTIVITY_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false
      }
    };
      break;
  }
  return state;
}
