import { Injectable } from '@angular/core';
import { CoursesService } from '../courses-service';

import { Router } from '@angular/router';
import { MyDb } from 'src/libs/MyDb';
import { MyUtil } from 'src/libs/MyUtil';
import { Appapi } from '../appapi';
import { Store } from '@ngrx/store';
import * as appStore from '../../store';

@Injectable({
  providedIn: 'root',
})
export class RedirectProvider {
  readonly REDIRECT_TARGET = 'REDIRECT_TARGET';

  constructor(
    public courseService:CoursesService, 
    public appApi: Appapi, 
    public router: Router,
    private store: Store<appStore.AppViewState>) {

  }

  setRedirectTarget(data: any) {

    try {
      MyUtil.debug(data);
      if (data) {

        let target: any = atob(data);
        target = JSON.parse(target);
        MyUtil.debug(target);
    
        // handle login
        if (target && target.params && target.name) {
          if(target.name == 'login') {
            let appUser: any = { _id: MyUtil.DOC_ID.APP_USER };
            MyUtil.lodash.merge(appUser, target.params);
            MyDb.appSave(appUser).then(userDoc => {
              this.reloadApp();
            }).catch(err => {
              MyUtil.error(['Login failed for redirect user', err]);
            });
          } else {
            MyUtil.saveToLocalStorage(this.REDIRECT_TARGET, JSON.stringify(target));
            this.router.navigate(['/home', {}]);
          }
        } else {
          // fail to get redirect target
          MyUtil.debug('Redirect target name not found');
        }
      } else {
        // fail to get redirect target
        MyUtil.debug('Redirect target not found');
      }
    } catch (err) {
      MyUtil.error(['Failed to set redirect target', err]);
    }
  }

  private reloadApp() {
    let href: string = window.location.href;
    let idx:any = href.indexOf('#/redirect');

    if (idx !== false) {
      href = href.substr(0,idx);
    } else {
      href = "/";
    }

    MyUtil.debug(['reload app', href]);

    window.location.href = href;
  }

  processRedirectTarget(router: Router, options: any) {

    try {
      let rawTarget = MyUtil.retrieveFromLocalStorage(this.REDIRECT_TARGET);

      if (rawTarget) {
        let target: any = JSON.parse(rawTarget);
        MyUtil.debug(target);

        if (this.appApi.isLoggedIn() || target.name == 'ResetPasswordComponent' || target.name == 'SetPasswordComponent') {

          // Reset redirect
          MyUtil.removeFromLocalStorage("REDIRECT_TARGET");
          this.store.dispatch(new appStore.RedirectSet(''));

          // process the target
          switch (target.name) {
            case 'ResetPasswordComponent':
              router.navigate(['/SetPasswordComponent', JSON.stringify(target)]);
              break;
            case 'SetPasswordComponent':
              router.navigate(['/SetPasswordComponent', JSON.stringify(target)]);
              break;
            case 'ActivityDetailPage':
              this.appApi.syncAllActivities().then((res) => {
                if (target.params && MyUtil.getActivity(target.params.id)) {
                  //router.navigate(['/ActivityListPage']);
                  router.navigate(['/ActivityDetailPage', JSON.stringify({ id: target.params.id, calendar: target.params.calendar })]);
                } else {
                  MyUtil.presentAlert({
                    message: 'Failed to match the activity. If you are signed up to multiple Inkpath organisations, please ensure you are logged into the correct one.',
                    cssClass: 'error',
                    buttons: [
                      {
                        text: 'Close',
                        handler: () => {
                          return;
                        }
                      }
                    ],
                  });
                }
              });
              break;
            case 'CourseDetailPage':
              if (target.params && target.params.id) {
                this.courseService.checkUserIsBooked(target.params.id).then(csres => {
                  //router.navigate(['/ActivityListPage']);

                  let calLink = target.params.calendar ? target.params.calendar : false;

                  if(csres && csres.course_id == target.params.id) {
                    router.navigate(['/CourseDetailPage', JSON.stringify({ courseID: target.params.id, isBooked: csres.is_booked, onWaitingList: csres.on_waiting_list, calendar: calLink })]);
                  }else{
                    router.navigate(['/CourseDetailPage', JSON.stringify({ courseID: target.params.id, isBooked: false, onWaitingList: false, calendar: calLink })]);
                  }
                });
              } else {
                MyUtil.presentToast('Failed to match the course. If you are signed up to multiple Inkpath organisations, please ensure you are logged into the correct one', { cssClass: 'inkpath-toast' });
              }
              break;
            case 'SkillDetailsPage':
              if (target.params && MyUtil.getSkill(target.params.id)) {
                // navCtrl.setRoot(UserPage);
                router.navigate(['/SkillDetailsPage', JSON.stringify(target.params)]);
              } else {
                MyUtil.presentToast('Failed to match the skill. If you are signed up to multiple Inkpath organisations, please ensure you are logged into the correct one', { cssClass: 'inkpath-toast' });
              }
              break;
            case 'EmailSettingsPage':
                // navCtrl.setRoot(UserPage);
                router.navigate(['/EmailSettingsPage', JSON.stringify(target.params)]);
                break;
            default:
              MyUtil.debug('Unknown target name ' + target.name);
              break;
          }
        } else {
          // fail to get redirect target
          MyUtil.debug('Redirect target not found');
        }
      }
    } catch (err) {
      MyUtil.error(['Fail to get redirect target', err]);
    }

    return false;
  }

}
