import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Appapi } from 'src/app/providers/appapi';
import { DesktopChecker } from 'src/app/providers/desktopChecker';
import { UiuxService } from 'src/app/services/uiux.service';
import { MyUtil } from '../../../libs/MyUtil';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'page-goal-list',
  templateUrl: 'goal-list.html'
})
export class GoalListPage implements OnInit, OnDestroy {

  // Component variables.
  @ViewChild('Content', { static: true }) content;
  pageData: any = {};
  pageLabel = "GoalListPage";
  isMobileView = this.uiux.isMobileView();
  phases = [];
  phasekeys = [];
  showFilters = false;

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public uiux: UiuxService,
    public navParams: NavParams,
    public zone: NgZone,
    public appapi: Appapi,
    public desktopChecker: DesktopChecker,
    private titleService:Title) {
      this.titleService.setTitle("Find Goals");
  }

  ngOnInit() {

    if (!this.appapi.isLoggedIn()) {
      return;
    }

    this.pageData.profile = MyUtil.getProfile();
    this.pageData.filterDisplay = {};
    this.pageData.goalsDisplay = [];
    this.pageData.selectedGoalIds = [];
    this.pageData.isIOS = MyUtil.context.isIOS;

    this.phasekeys = [this.pageData.profile.phase_id];
    this.pageData.filter = {
      searchKey: '',
      organizations: MyUtil.getOrgnizationChain(),
      priorities: MyUtil.lodash.map(MyUtil.cache[MyUtil.DOC_ID.APP_META]['GOAL_PRIORITY'], (value, key) => {
        return { id: key, name: value };
      }),
      program: MyUtil.cache[MyUtil.DOC_ID.PROGRAMS][this.pageData.profile.program_id],
      phase: MyUtil.cache[MyUtil.DOC_ID.PHASES][this.pageData.profile.phase_id],
    };

    this.appapi.queryUserGoals().then((result) => {
      this.pageData.selectedGoalIds = MyUtil.lodash.chain(result).map('goal_id').filter((item) => {
        return item;
      }).value();

      this.applyFilter();
    });

    // expand help for first time landing on the page
    if (MyUtil.context.helpStatus[MyUtil.HELP_ID.GOAL_LIST]) {
      this.pageData.helperToggle = false;
    } else {
      this.pageData.helperToggle = true;
      this.appapi.setAppHelpStatus(MyUtil.HELP_ID.GOAL_LIST, true);
    }

    MyUtil.firebaseSetScreenName('goal-list');
    this.appapi.syncUserGoals();

    MyUtil.firebaseLogEvent('view_did_enter', { name: 'goal-list', data: this.navParams.data });
  }

  toggleFilter() {
    this.showFilters =! this.showFilters
  }

  ionViewDidLeave() {
    MyUtil.firebaseLogEvent('view_did_leave', { name: 'goal-list', data: this.navParams.data });
  }

  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  * @param item     Parameter to process in action.
  */
  process(action: string, item?: any) {
    switch (action) {
      case 'search-key':
        this.showFilters = false;
        this.applyFilter();
        return;
      case 'search-organizations':
        this.showFilterCheckbox('Choose organisations to search', this.pageData.filter.organizations);
        return;
      case 'search-priorities':
        this.showFilterCheckbox('Choose Priorities', this.pageData.filter.priorities);
        return;
      case 'goal-detail':
        this.router.navigate(['/GoalDetailPage', item]);
        return;
      default:
        MyUtil.presentToast('"' + action + '" is not handled', { cssClass: 'inkpath-toast' });
        return;
    }
  }

  /** 
  * Scroll handler.
  */
  scrollHandler(event) {
    this.zone.run(() => {
      //close filter when scrolling
      if (this.showFilters && event && event.scrollTop > 120) {
        this.showFilters = false;
      }
      if (event && event.scrollTop > 40) {
        MyUtil.publishEvent(MyUtil.EVENT.APP_TOGGLE_HELP, false);
      }
    })
  }

  /** 
  * Render filter.
  */
  private renderFilter() {
    
    this.pageData.filterDisplay = {
      organizations: MyUtil.lodash.chain(this.pageData.filter.organizations).filter((item) => {
        return !item.hidden;
      }).map('name').value().join(', '),
      priorities: MyUtil.lodash.chain(this.pageData.filter.priorities).filter((item) => {
        return !item.hidden;
      }).map('name').value().join(', '),
      program: this.pageData.filter.program.name,
      phase: this.pageData.filter.phase.name,
    };
  }

  /** 
  * Apply filter.
  */
  private async applyFilter() {

    this.renderFilter();

    let loading = MyUtil.presentLoading();

    // if filter.searchkey exists and is greater than 0, searchkey is set to lower case of filter.searchkey
    let searchKey = (this.pageData.filter.searchKey && this.pageData.filter.searchKey.length > 0 ? this.pageData.filter.searchKey.toLowerCase() : null);

    let searchOrganizationIds: any = null;
    if (this.pageData.filter.organizations) {
      searchOrganizationIds = MyUtil.lodash.chain(this.pageData.filter.organizations).filter((item: any) => {
        return !item.hidden;
      }).map((item: any) => {
        return parseInt(item.id);
      }).value();
    }

    let searchPriorityIds: any = null;
    if (this.pageData.filter.priorities) {
      searchPriorityIds = MyUtil.lodash.chain(this.pageData.filter.priorities).filter((item: any) => {
        return !item.hidden;
      }).map((item: any) => {
        return parseInt(item.id);
      }).value();
    }

    let goals = MyUtil.lodash.chain(MyUtil.cache[MyUtil.DOC_ID.GOALS]).values().filter((item: any) => {
      let result = true;
      if (result && searchKey) {
        result = result && (item.name.toLowerCase().indexOf(searchKey) !== -1);
      }
      if (result && searchOrganizationIds && (!item.profile_id)) {
        result = result && (searchOrganizationIds.indexOf(parseInt(item.oid)) !== -1);
      }
      if (result && searchPriorityIds && (!item.profile_id)) {
        result = result && (searchPriorityIds.indexOf(parseInt(item.priority)) !== -1);
      }

      return result;
    })
    .filter(this.isPartOfProgram(MyUtil.getProfileProgramIds()))
    .filter(goal => {
      let contains = false;
      if (goal.phases.length > 0) {
        goal.phases.forEach(element => {
          if (this.phasekeys.indexOf(element) > -1) {
            contains = true;
          }
        });
      } else {
        contains = true;
      }
      return contains;
      })
    .map((item: any) => {
      let result: any = {};
      result.profile_id = item.profile_id;
      result.id = item.id;
      result.name = item.name;
      result.organization = MyUtil.getOrgnizationChainName(item.oid);
      result.priorityTitle = MyUtil.cache[MyUtil.DOC_ID.APP_META]['GOAL_PRIORITY'][item.priority];
      result.selected = (this.pageData.selectedGoalIds.indexOf(result.id) !== -1);
      result.badge_details = item.badge_details ? item.badge_details : null;

      return result;
    }).sort((a: any, b: any) => {
      //Sort by name
      return a.name.localeCompare(b.name);
    }).value();
    
    this.pageData.goalsDisplay = goals;

    (await loading).dismiss();
  }

  /** 
  * Show filter checkbox.
  * @param title (string)
  * @param filter (any)
  */
  private showFilterCheckbox(title: string, filter: any) {
    let inputs = MyUtil.lodash.chain(filter).map((item) => {
      let input = {
        type: 'checkbox',
        label: item.name,
        value: item.id,
        checked: !item.hidden,
      };
      return input;
    }).value();

    MyUtil.presentAlert({
      title: title,
      inputs: inputs,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            MyUtil.debug('Cancel clicked');
          }
        },
        {
          text: 'OK',
          handler: data => {
            MyUtil.debug(['Checkbox data:', data]);
            MyUtil.lodash.forEach(filter, (item) => {
              if (data.indexOf(item.id) !== -1) {
                item.hidden = false;
              } else {
                item.hidden = true;
              }
            });
            this.applyFilter();
          }
        }
      ]
    });
  }

   // Check if is part of program.
   isPartOfProgram(programIds) {
    return function (element) {
      let contains = false;
      if (element.programs.length > 0) {
        element.programs.forEach(element => {
          if (programIds.indexOf(element) > -1) {
            contains = true;
          }
        });
      } else {
        contains = true;
      }
      return contains;
    }
  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
