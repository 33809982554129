import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { NavParams } from '@ionic/angular';
import { Appapi } from 'src/app/providers/appapi';
import { DesktopChecker } from 'src/app/providers/desktopChecker';
import { FileService } from 'src/app/providers/file-service';
import { takeUntil } from 'rxjs/operators';
import { MyUtil } from '../../../libs/MyUtil';
import { Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'page-managed-file',
  templateUrl: 'managed-file.html',
  styleUrls: ['managed-file.scss'],
})
export class ManagedFilePage implements OnInit, OnDestroy {

  // Component variables.
  @ViewChild('Content', { static: true }) content;
  pageData: any = {};
  routeData: any = {};

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(public router: Router, public navParams: NavParams,
    private route: ActivatedRoute,
    private _location: Location,
    public camera: Camera,
    public appapi: Appapi,
    public fileService: FileService,
    public desktopChecker: DesktopChecker,
    private titleService:Title) {
      this.titleService.setTitle("Upload"); }

  ngOnInit(): void {

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData = JSON.parse(params.pageData);
    });

    // Expand help for first time landing on the page.
    if (MyUtil.context.helpStatus[MyUtil.HELP_ID.MANAGED_FILE]) {
      this.pageData.helperToggle = false;
    } else {
      this.pageData.helperToggle = true;
      this.appapi.setAppHelpStatus(MyUtil.HELP_ID.MANAGED_FILE, true);
    }

    //Either id or activity object passed in - object if the activity is wrapped in a course
    //TODO: Fix when caching is sorted out properly
    this.pageData.activityObject = this.routeData?.activityObject ? this.routeData.activityObject : null;
    this.pageData.id = this.routeData?.id ? this.routeData.id : null;
    this.pageData.userActivityId = this.routeData.userActivityId;
    this.pageData.file = null;
    this.pageData.imageData = null;
    this.pageData.description = null;
    this.pageData.isMobileAppMode = MyUtil.isMobileAppMode();
  }

  ionViewDidLoad() {
    MyUtil.firebaseSetScreenName('managed-file');
  }

  ionViewDidLeave() {
    MyUtil.firebaseLogEvent('view_did_leave', { name: 'managed-file', data: this.pageData.userActivityId });
  }

  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  */
  process(action) {
    let data: any;

    switch (action) {
      case 'back-to-activity':
        window.history.back();
        return;
      case 'upload':
        if ((!this.pageData.file || this.pageData.file.size > 10 * 1024 * 1024) && !this.pageData.imageData) {
          let msg = this.pageData.isMobileAppMode ? 'Please take a photo or select a file (max size 10MB)' : 'Please select a file (max size 10MB)';
          MyUtil.presentToast(msg, { cssClass: 'inkpath-toast' });
          return;
        }

        data = {
          description : this.pageData.description,
          image_data : this.pageData.imageData,
          user_activity_id : this.routeData.userActivityId
        };
   

        let loading = MyUtil.presentLoading();
        this.fileService.uploadEvidenceFile(this.pageData.file, data).then(async result => {
          (await loading).dismiss();
          if (result['#status'] === 'success') {
            MyUtil.presentToast(result['#message'], { cssClass: 'inkpath-toast' });

            //Pass back either id or activity object - object if the activity is wrapped in a course
            //TODO: Fix when caching is sorted out properly
            if(this.pageData.id) {
              this.router.navigate(['/ActivityDetailPage', JSON.stringify({ id: this.routeData.id })]);
            } else if (this.pageData.activityObject) {
              this.router.navigate(['/ActivityDetailPage', JSON.stringify({ id: this.routeData.activityObject.id, 
                                                                            activityObject: this.routeData.activityObject, 
                                                                            isCourseActivity: this.routeData.isCourseActivity 
                                                                          })]);
            }
            
          } else {
            //MyUtil.presentToast(MyUtil.responseToMessage(result['#message'], result['#errors']), { cssClass: 'inkpath-toast' });
          }
        }).catch(async err => {
          (await loading).dismiss();
          MyUtil.error(err);
          //MyUtil.presentToast('Oops! Service is not available. Please try again later.', { cssClass: 'inkpath-toast' });
        });
        break;
      default:
        MyUtil.presentToast('"' + action + '" is not handled', { cssClass: 'inkpath-toast' });
        return;
    }
  }

  onFileChange(event: any) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      MyUtil.debug(file);
      if (file.size <= 10 * 1024 * 1024) {
        this.pageData.file = file;
        let size: any = Math.round(file.size / 1024 / 1024 * 100) / 100;
        if (size < 1) {
          size = Math.round(file.size / 1024 * 100) / 100 + 'KB';
        } else {
          size = size + 'MB';
        }
        this.pageData.displayFileSize = size;

      } else {
        this.pageData.file = null;
        MyUtil.presentToast('Max file size allowed is 10MB.', { cssClass: 'inkpath-toast' });
      }

      // clear image.
      this.pageData.imageData = null;
    }
  }

  /** 
  * Select file.
  */
  selectFile(event: any) {
    (<HTMLElement>document.querySelector('input[type="file"]')).click();
  }

  /** 
  * Take picture.
  */
  takePicture(event: any) {
    const options: CameraOptions = {
      quality: 50,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      targetWidth: 800,
      targetHeight: 800,
    }
    this.camera.getPicture(options).then((imageData) => {
      MyUtil.debug(['Image data received', imageData.length]);
      this.pageData.imageData = "data:image/jpeg;base64," + imageData;
      // Clear selected file.
      this.pageData.file = null;
    }, (err) => {
      MyUtil.error(err);
    });
  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
