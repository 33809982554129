import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Appapi } from 'src/app/providers/appapi';
import { DesktopChecker } from 'src/app/providers/desktopChecker';
import { MyUtil } from '../../../libs/MyUtil';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import * as appStore from '../../store';
import { getBrandName } from 'src/app/store/selectors/view.selector';
import { MyDb } from '../../../libs/MyDb';

@Component({
  selector: 'page-password',
  templateUrl: 'password.html',
  styleUrls: ['./password.scss'],
})
export class PasswordPage implements OnInit {

  // Component variables.
  showPassword: boolean = false;
  pageData: any = {};
  routeData: any = {};
  brandName$: Observable<string> = this.store.select(getBrandName);
  branding = null;

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public navParams: NavParams, public formBuilder: UntypedFormBuilder,
    public appapi: Appapi, public desktopChecker: DesktopChecker,
    private titleService:Title,
    private store: Store<appStore.AppViewState>,) {
      this.titleService.setTitle("Change Password");
  }

  ngOnInit(): void {

    if (!this.appapi.isLoggedIn()) {
      return;
    }

    this.brandName$.pipe(takeUntil(this.destroy$)).subscribe(brandName => {
      this.branding = brandName;
    })

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData = JSON.parse(params.pageData)
    });

    this.pageData.initialisedProfile = this.routeData.initialisedProfile;
    this.pageData.profile = MyUtil.getProfile();
    this.pageData.profileOrg = MyUtil.getRootOrganization();

    this.pageData.loaded = false;
    this.pageData.otherProfiles = false;
    this.pageData.otherSso = false;
    this.appapi.getAlternativeProfilesData(this.branding).then(async (alternativeProfilesData) => {

      await MyDb.appLoad(MyUtil.DOC_ID.APP_USER).then(appUser => {
        this.pageData.user = appUser;
      });

      let nonSso = alternativeProfilesData.filter((item) => {
        //Exclude sso from count
        return !item.sso;
      });

      if(nonSso.length > 0) {
        this.pageData.otherProfiles = true;
      };

      let sso = alternativeProfilesData.filter((item) => {
        //Exclude sso from count
        return !!item.sso;
      });

      if(sso.length > 0) {
        this.pageData.otherSso = true;
      };

      this.pageData.loaded = true;
    });

    this.pageData.universities = MyUtil.lodash.values(MyUtil.cache[MyUtil.DOC_ID.APP_UNIVERSITIES]);
    this.pageData.segment = this.routeData.segment;
    if (!this.pageData.segment) {
      this.pageData.segment = 'login';
    };

    // define login form validation
    this.pageData.passwordForm = this.formBuilder.group({
      oldPassword: ['', Validators.compose([Validators.maxLength(MyUtil.CONST.INPUT_MAX_LENGTH), Validators.required])],
      passwords: this.formBuilder.group({
        newPassword: ['', Validators.compose([Validators.maxLength(MyUtil.CONST.INPUT_MAX_LENGTH), Validators.required])],
        newPassword_confirmation: ['', Validators.compose([Validators.maxLength(MyUtil.CONST.INPUT_MAX_LENGTH), Validators.required])]
      }, {
        validator: (group: UntypedFormGroup) => {
          return (group.get('newPassword').value === group.get('newPassword_confirmation').value ? null : { 'mismatch': true });
        }
      })
    });
  }

  ionViewDidLoad() {
    MyUtil.firebaseSetScreenName('password');
  }

  ionViewDidEnter() {
    MyUtil.firebaseLogEvent('view_did_enter', { name: 'password', data: this.navParams.data });
  }

  ionViewDidLeave() {
    MyUtil.firebaseLogEvent('view_did_leave', { name: 'password', data: this.navParams.data });
  }

  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  */
  process(action) {
    // validate input and prepare api call
    let data;

    switch (action) {
      case 'change-password':
        this.pageData.passwordFormSubmitAttempt = true;
        if (!this.pageData.passwordForm.valid) {
          let message = 'Please revise input';
          if (this.pageData.passwordForm.value.passwords.newPassword !== this.pageData.passwordForm.value.passwords.newPassword_confirmation) {
            message = "Passwords do not match. ";
          }
          MyUtil.presentToast(message, { cssClass: 'inkpath-toast' });
          return;
        }
        data = this.pageData.passwordForm.value;
        break;
      default:
        MyUtil.presentToast('"' + action + '" is not handled', { cssClass: 'inkpath-toast' });
        return;
    }

    let loading = MyUtil.presentLoading();
    this.appapi.post('/' + action, data).then(async result => {
      (await loading).dismiss();

      if (result['#status'] === 'success') {
        switch (action) {
          case 'change-password':

            MyUtil.presentToast(result['#message'], { cssClass: 'inkpath-toast' });
            this.router.navigate(['/UserSettingsPage', '{}']);

            break;
        }
      } else if (result['#status'] === 'failed') {
        MyUtil.presentToast(MyUtil.responseToMessage(result['#message'], result['#errors']), { cssClass: 'inkpath-toast' });
      }
    }).catch(async err => {
      (await loading).dismiss();

      MyUtil.error(err);
      //MyUtil.presentToast('Oops! Service is not available. Please try again later.', { cssClass: 'inkpath-toast' });
    });
  }


  /** 
  * Toggle password.
  */
    togglePassword(event) {
      event.preventDefault();
      this.showPassword = !this.showPassword;
    }


  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
