import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getBrandName } from 'src/app/store/selectors/view.selector';
import { ActivityListItemVO } from 'src/app/valueObjects/lists.vo';
import * as appStore from '../../store';
import { MyUtil } from 'src/libs/MyUtil';
@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
})
export class ListItemComponent implements OnInit {

  // Component variables.
  brandName$: Observable<string> = this.store.select(getBrandName);
  currencySymbol = "£";
  // Inputs.
  @Input() listItem: ActivityListItemVO;

  /**
  * Clicked event emitter.
  */
  @Output() clicked: EventEmitter<ActivityListItemVO> = new EventEmitter<ActivityListItemVO>();

  constructor(private store: Store<appStore.AppViewState>) { }

  ngOnInit() {
    //TODO: replace oid with root_oid when back-end refactor sorted
    //Or - find a better way of getting the currency symbol. Get API to supply it directly?
    let rootOrg = MyUtil.getRootOrganization(`${this.listItem.oid}`, true);
    this.currencySymbol = (rootOrg && rootOrg.currency_symbol ) ? rootOrg.currency_symbol : '£';
  }

  /** 
  * Click handler.
  * @param item   Emits item data object that has been clicked.
  */
  clickHandler(item: ActivityListItemVO) {
    this.clicked.emit(item);
  }
}
