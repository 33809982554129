import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { MyUtil } from 'src/libs/MyUtil';

@Component({
  selector: 'app-find-activities-modal',
  templateUrl: './find-activities-modal.component.html',
  styleUrls: ['./find-activities-modal.component.scss'],
})
export class FindActivitiesModalComponent implements OnInit {

   // Component variables.
   public data: any;
   public label: any = 0;
 
   constructor(public params: NavParams, public viewCtrl: ModalController) {
     if (params) {
       this.data = params.data;
     }
   }
 
   // Handle action.
   handleAction() {
     this.viewCtrl.dismiss(this.label);
   }

   formatCountText(count){
    let activities = (count === 1) ? 'Activity': 'Activities';
    return `(${count} ${activities})`;
   }
 
   setFindActivitiesTabState(label){
    let findActivitiesTabState:findActivitiesTabStateVO = {
      tab: label,
      timestamp: Date.now()
    }
    MyUtil.saveToLocalStorage('findActivitiesTabState', JSON.stringify(findActivitiesTabState));
    this.viewCtrl.dismiss(label);
   }
 
   // Close Modal.
   closeModal() {
     this.viewCtrl.dismiss();
   }

  ngOnInit() {}

}

export interface findActivitiesTabStateVO {
  tab: string;
  timestamp: number;
}
