export class Questionnaire {
  constructor(data?) {
    return data ? this.hydrate(data) : this;
  }

  id: number;
  started_at: string;
  finished_at: string;
  sections: Array<QuestionnaireSection> = [];

  hydrate(data): Questionnaire {
    Object.assign(this, data);

    return this;
  }
}

export class QuestionnaireSection {
  constructor(data?) {
    return data ? this.hydrate(data) : this;
  }

  title: string;
  questions: Array<QuestionnaireQuestion> = [];

  hydrate(data): QuestionnaireSection {
    Object.assign(this, data);

    data.questions.forEach((questionsData) => {
      const question = new QuestionnaireQuestion();
      question.hydrate(questionsData);
      this.questions.push(question);
    });

    return this;
  }
}

export class QuestionnaireQuestion {
  constructor(data?) {
    return data ? this.hydrate(data) : this;
  }

  id: number;
  skill_level: number;
  interest: number;
  comments: string;
  complete: boolean;
  skill: Skill;

  hydrate(data): QuestionnaireQuestion {
    Object.assign(this, data);

    this.skill = new Skill();
    this.skill.hydrate(data.skill);

    return this;
  }
}

export class Skill {
  constructor(data?) {
    return data ? this.hydrate(data) : this;
  }

  id: number;
  name: string;
  description: string;

  hydrate(data) {
    Object.assign(this, data);

    return this;
  }
}
