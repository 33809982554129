import { ActionReducerMap } from "@ngrx/store";
import { ViewAction } from "../actions/view.action";
import * as viewReducer from "./view.reducer";

export interface AppViewState {
  view: viewReducer.ViewState
}

export const reducers: ActionReducerMap<AppViewState, ViewAction> = {
  view: viewReducer.reducer
}

