import { Injectable } from "@angular/core";
import { MyUtil } from "src/libs/MyUtil";


import { Appapi } from "../appapi";

@Injectable()
export class ReflectionService {

  public static ONBOARDING_SKILL_ID = 2; // corresponds to skill type for onbaording in database


  constructor(public appapi: Appapi) {}


  /**
   * Check whether we need to display the onboarding questions to the user
   */
  checkIfOnboardingReflectionRequired() {

  }


  /**
   * Get the onboarding questions for the user
   */
  public getOnboardingReflectionQuestions(): Promise<any> {
    let data = [];

    return this.appapi
      .get('/skills/onboarding', [])
      .then((result) => {
        if (result["#status"] === "success") {
          data = result["#data"]['data'];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
        return data;
      });
  }


  /**
   * Save the onboarding submission data
   *
   * @returns
   */
  public saveOnboardingReflectionSubmission(data, profile): Promise <any> {
    let response = false;
    return this.appapi
    .post('/onboarding/submissions/process', { data: data, profile_id: profile.id})
    .then((result) => {
      if (result["#status"] === "success") {
        response = true;
      } else {
        MyUtil.error(
          MyUtil.responseToMessage(result["#message"], result["#errors"])
        );
      }
      return response;
    })
    .catch((err) => {
      MyUtil.error(err);
      return response;
    });
  }


  /**
   * Get the latest completed set of onboarding questions and answers
   */
  getLatestOnboardingReflectionQuestionsAndAnswers() {

  }


  /**
   * Get all, historic onboarding questions and answers
   */
  public getHistoricOnboardingReflectionQuestionsAndAnswers(profile): Promise<any> {
    let data = [];

    return this.appapi
      .get('/onboarding/submissions/all/'+profile.id, [])
      .then((result) => {
        if (result["#status"] === "success") {
          data = result["#data"];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
        return data;
      });
  }


  public getOnboardingReflectionData(id, profile_id): Promise<any> {
    let data = {};

    return this.appapi
      .get('/onboarding/submissions/'+id+'/'+profile_id, [])
      .then((result) => {
        if (result["#status"] === "success") {
          data = result["#data"];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
        return data;
      });
  }





}
