import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Appapi } from 'src/app/providers/appapi';
import { MyUtil } from 'src/libs/MyUtil';

@Component({
  selector: 'announcements',
  templateUrl: 'announcements.html',
  styleUrls: ['announcements.scss'],
})
export class AnnouncementsPage implements OnInit, OnDestroy {

  pageData: any = {};

  constructor(public appapi: Appapi,
    private titleService: Title,
    public platform: Platform,
    public router: Router
  ) {
    this.titleService.setTitle("Announcements");
  }

  ngOnInit() {

    this.pageData.loaded = false;

    if (!this.appapi.isLoggedIn()) {
      return;
    }
    
    let homeOrg = MyUtil.getRootOrganization();
    if(!homeOrg.enable_announcements) {
      this.router.navigate(['/']);
    }

    this.pageData.selectedTab = 'all';
    this.pageData.announcements = [];
    this.pageData.newAnnouncements = [];
    this.pageData.tabAnnouncements = [];
    this.pageData.selectedAnnouncement = null;
    this.pageData.clicked = false;

    this.appapi.getAnnouncementList().then((data) => {
      if(data['announcements'] !== undefined) {
        this.pageData.announcements = data['announcements'];
        
        if(this.platform.width() > 820) {
          if(this.pageData.announcements.length > 0) {
            if(!this.pageData.announcements[0].read_at) {
              this.appapi.markAnnouncementAsRead(this.pageData.announcements[0].id).then((result) => {
                if(result && result['read_at']) {
                  this.pageData.announcements[0].read_at = result['read_at'];
                  this.pageData.selectedAnnouncement = this.pageData.announcements[0];
                }
              })
            } else {
              this.pageData.selectedAnnouncement = this.pageData.announcements[0];
            }
          }
        } else if(this.pageData.announcements.length > 0) {
          this.pageData.selectedAnnouncement = this.pageData.announcements[0];
        }

        this.pageData.newAnnouncements = this.pageData.announcements.filter((announcement) => {
          return announcement.new;
        });
        this.pageData.newAnnouncementsCount = this.pageData.newAnnouncements.length;

        if(this.pageData.newAnnouncementsCount > 0) {
          this.setSelectedTab('new');
        } else {
          this.pageData.tabAnnouncements = this.pageData.announcements;
        }
      }

      this.pageData.loaded = true;
    });
  }

  ngOnDestroy() {

  }

  select(id: number) {

    this.pageData.clicked = false;
    this.pageData.announcements.forEach((announcement, i) => {
      if(announcement.id == id) {

        this.pageData.selectedAnnouncement = announcement;

        if(!announcement.read_at) {
          this.appapi.markAnnouncementAsRead(announcement.id).then((result) => {
            if(result && result['read_at']) {
              this.pageData.announcements[i].read_at = result['read_at'];
            }
          });

          this.pageData.newAnnouncements.forEach((newAnnouncement, newI) => {
            if(newAnnouncement.id == id) {
              this.pageData.newAnnouncements[newI].read_at = this.pageData.announcements[i].read_at;
              return;
            }
          });
        }

        if(this.platform.width() <= 820) {
          this.pageData.clicked = true;
        }

        return;
      }
    });
  };

  backToList() {
    this.pageData.clicked = false;
  }

  setSelectedTab(tabName : string) {
    this.pageData.selectedTab = tabName;

    if(tabName == 'new') {
      this.pageData.tabAnnouncements = this.pageData.newAnnouncements;
    } else {
      this.pageData.tabAnnouncements = this.pageData.announcements;
    }

    if(this.pageData.tabAnnouncements.length > 0) {
      if(this.platform.width() > 820) {
        this.select(this.pageData.tabAnnouncements[0].id)
      } else {
        this.pageData.selectedAnnouncement = this.pageData.tabAnnouncements[0];
      }
    }
  }

}