import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, NavParams } from '@ionic/angular';
import { Appapi } from 'src/app/providers/appapi';
import { DesktopChecker } from 'src/app/providers/desktopChecker';
import { MyUtil } from "../../../libs/MyUtil";

/**
 * Generated class for the CareerPathPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

// @IonicPage()
@Component({
  selector: 'page-career-path',
  templateUrl: 'career-path.html'
})
export class CareerPathPage {

  path: any;

  constructor(public router: Router, public navParams: NavParams, public appapi: Appapi, 
    public alertCtrl: AlertController, public desktopChecker: DesktopChecker) {
    this.path = this.navParams.get('path');
  }


    makeActive() {
        let loading = MyUtil.presentLoading();
        this.appapi.setActiveCareer(this.path.id)
            .then(async res => {
                (await loading).dismiss();
                this.path.pivot.active = 1;
                MyUtil.presentToast(res['#message'], { cssClass: 'inkpath-toast' })
            })
            .catch(async err => {
                (await loading).dismiss();
                MyUtil.presentToast(err['#message'], { cssClass: 'inkpath-toast' })
            });
    }

    deactivate() {
        let loading = MyUtil.presentLoading();
        this.appapi.deactivateCareer(this.path.id)
            .then(async res => {
                (await loading).dismiss();
                this.path.pivot.active = 0;
                MyUtil.presentToast(res['#message'], { cssClass: 'inkpath-toast' })
            })
            .catch(async err => {
                (await loading).dismiss();
                MyUtil.presentToast(err['#message'], { cssClass: 'inkpath-toast' })
            });
    }

  saveToPDP() {
    let loading = MyUtil.presentLoading();
    this.appapi.saveCareerToPDP(this.path.id)
        .then(async res => {
          (await loading).dismiss();
          this.path.pdp = 1;
          this.presentConfirm();
        })
        .catch(async err => {
          (await loading).dismiss();
          MyUtil.presentToast(err['#message'], { cssClass: 'inkpath-toast' })
        });
  }

  async presentConfirm() {
    let alert = this.alertCtrl.create({
      //title: this.path.title,
      message: 'has been added to your Personal Development Plan. You can see your careers in your profile.',
      buttons: [
        {
          text: 'Continue to Inkpath App',
          handler: () => {
            this.router.navigate(['/UserPage', {segment: 'pdp'}]);
          }
        },
        {
          text: 'Add another career',
          handler: () => {

          }
        }
      ]
    });
     (await alert).present();
  }

  removeFromPDP() {
    let loading = MyUtil.presentLoading();
    this.appapi.removeCareerFromPDP(this.path.id)
        .then(async res => {
          (await loading).dismiss();
          this.path.pdp = 0;
          this.path.pivot.active = 0;
          MyUtil.presentToast(res['#message'], { cssClass: 'inkpath-toast' })
        })
        .catch(async err => {
          (await loading).dismiss();
          MyUtil.presentToast(err['#message'], { cssClass: 'inkpath-toast' })
        });
  }

}
