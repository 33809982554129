import { Injectable } from "@angular/core";
import { MyUtil } from "src/libs/MyUtil";

import { Appapi } from "../appapi";

@Injectable()
export class OnboardingService {
  constructor(public appapi: Appapi) {}


  /**
   * Check whether we need to display the onboarding pages
   * Ignore if the user has already viewed them
   */
  public checkDisplayOnboardingPages(): Promise<any> {

    let data = [];

    return this.appapi
      .get('/onboarding/pages/display-check', [])
      .then((result) => {
        if (result["#status"] === "success") {
          data = result["#data"];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
        return data;
      });

  }

  /**
   * Check if there are onboarding pages available for the org
   * Returns the page data if available
   */
  public getOnboardingPages(): Promise<any> {

    let data = [];

    return this.appapi
      .get('/onboarding/pages', [])
      .then((result) => {
        if (result["#status"] === "success") {
          data = result["#data"];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
        return data;
      });

  }

  /**
   * Set the flag to highlight that the user has viewed the onboarding pages
   */
  public setUserHasViewedOnboardingPagesFlag(profile_id): Promise<any> {
    let data = [];

    return this.appapi
      .post('/onboarding/pages/seen/' + profile_id, {})
      .then((result) => {
        if (result["#status"] === "success") {
          data = result["#data"]['data'];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
        return data;
      });
  }

}
