import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { ActivityListItemVO } from 'src/app/valueObjects/lists.vo';

@Component({
  selector: 'app-index-list',
  templateUrl: './index-list.component.html',
  styleUrls: ['./index-list.component.scss'],
})
export class IndexListComponent implements OnInit {

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
  ) { }

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();
  
  // Inputs.
  @Input() loading?: boolean;
  @Input() list$: Observable<ActivityListItemVO[]>;
  @Input() title: string;
  @Input() isGrid = false;
  @Input() enableSearch = false;

  // Outputs.
  @Output()

  /**
  * Parameter name on the generated component to which to pass the item in.
  */
  @Input() paramName: string;

  /**
  * Generates component for displaying objects in our list.
  */
  @Input() component:any;

  /**
  * Item search to search in list item's VO.
  */
  @Input() searchValue?:string;

  /**
  * Label in VO to use as the title in a standard list.
  */
  @Input() label?:string;
  
  /**
  * Clicked event emitter.
  */
  @Output() clicked: EventEmitter<ActivityListItemVO> = new EventEmitter<ActivityListItemVO>();

  /**
  * Form to control how the list is dispayed on screen.
  */
  searchForm: UntypedFormGroup = this.formBuilder.group({
    searchFormControl: new UntypedFormControl('')
  });
  filteredList$: Observable<ActivityListItemVO[]>;

  // On init
  ngOnInit() {
    this.filteredList$ = combineLatest([
      this.searchForm.controls.searchFormControl.valueChanges.pipe(startWith('')),
      this.list$
    ]).pipe(takeUntil(this.destroy$), map(([searchTerm, list]) => {
      // If empty return full list.
      if (searchTerm === '') {return list;}
      // Filter results by searchValue.
      let filteredList = list.filter((item) => {
        return item[this.searchValue].toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
      })
      return filteredList;
    }))
  }

  /** 
  * Click handler.
  * @param $event   Emits item data object that has been clicked.
  */
  clickHandler($event) {
    this.clicked.emit($event);
  }

  // On destroy
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
