import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { QuestionnaireDataProvider } from 'src/app/providers/questionnaire-data/questionnaire-data';
import { MyUtil } from "../../../libs/MyUtil";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

/**
 * Generated class for the QuestionnaireIntroPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

// @IonicPage()
@Component({
  selector: 'page-questionnaire-intro',
  templateUrl: 'questionnaire-intro.html',
  styleUrls: ['questionnaire-intro.scss'],
})
export class QuestionnaireIntroPage implements OnInit, OnDestroy{

  page = 0;
  pageData:any={};
  routeData:any  = {};
  
  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute, 
    public router: Router, 
    public navParams: NavParams, 
    public questionnaireData: QuestionnaireDataProvider) {
  }

  ngOnInit() {

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData =JSON.parse(params.pageData);
    });
    
    this.pageData.inReview = this.routeData.inReview;
    this.pageData.id = this.routeData.id
    this.pageData.fromSelect = this.routeData.fromSelect;
    
    // force to do the profile first
    if (!MyUtil.validateProfile()) {
      this.router.navigate(['UserSettingsPage']);
    }

    let loading = MyUtil.presentLoading();
    this.questionnaireData.fetchData()
      .then(async (res) => {
        (await loading).dismiss();
      });
  }

  next() {
    this.page++;
  }

  continue() {

        if (this.questionnaireData.setActiveQuestionNumberOnIntroContinue(this.pageData.id)) {
          this.router.navigate(['/QuestionnairePage', JSON.stringify({fromSelect:this.pageData.fromSelect, id:this.pageData.id})]);
        } else {
          MyUtil.presentToast('Questionnaire is not available. Please try again later.', { cssClass: 'inkpath-toast' });
        }
    
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
