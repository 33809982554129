import { Injectable } from '@angular/core';
import {Appapi} from "../appapi";
import {Questionnaire} from "./models";

/*
  Generated class for the QuestionnaireDataProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/


class questionnaireTrack  {
  questionnaire: Questionnaire;

  activeSectionIndex;
  activeQuestionIndex;

  constructor(data){
    this.questionnaire = new Questionnaire(data);
    this.activeSectionIndex= 0;
    this.activeQuestionIndex= -1;
  }
}



@Injectable()
export class QuestionnaireDataProvider {

  // tracking multiple questionnaires progress


  //questionnaires array
  public questionnaires = [];

  constructor(public appapi: Appapi) {
    this.questionnaires = [];
  }

  public fetchData() {
    this.questionnaires = [];
    return new Promise((resolve, reject) => {
      this.appapi.getQuestionnaire().then((res) => {
        if (res['#status'] === 'success') {

          //for each questionnaire returned
          Object.keys(res['#data']).forEach(key => {

            let qt =  new questionnaireTrack(res['#data'][key]);
            this.questionnaires.push(qt);

            this.continueInProgress(qt.questionnaire.id);
          });
          return resolve(res);
        }
      })
    });
  }

  public getQuestionnaire(questionnaireID: number){
    let quesObj = this.questionnaires.find( item=>{
    return  item.questionnaire.id ==questionnaireID ;
    });
    return quesObj;
  }


/*  not used for the moment anyway
  restart(oid:number) {
    return new Promise((resolve, reject) => {
      this.appapi.restartQuestionnaire(oid).then((res) => {
        if (res['#status'] ===  'success') {
          this.questionnaire = new Questionnaire(res['#data']);
          this.activeSectionIndex = 0;
          this.activeQuestionIndex = -1;
          resolve(this.questionnaire);
        } else {
          reject();
        }
      });
    });
  }
  */

  getSection(questionnaireID: number,index?: number) {
    let quesObj = this.questionnaires.find( item=>{
      return item.questionnaire.id ==questionnaireID ;
    });
    if(quesObj != undefined){
      return quesObj.questionnaire.sections[index == undefined ? quesObj.activeSectionIndex : index] || null;
    }
  }

  getQuestion(questionnaireID: number,index?: any) {

    let quesObj = this.questionnaires.find( item=>{
      return item.questionnaire.id ==questionnaireID ;
    });
    
    if (!this.getSection(questionnaireID)) {
      return null;
    }
    return this.getSection(questionnaireID).questions[index == undefined ? quesObj.activeQuestionIndex : index] || null;
  }

  previous(questionnaireID: number) {

    let quesObj = this.questionnaires.find( item=>{
      return item.questionnaire.id ==questionnaireID ;
    });

    quesObj.activeQuestionIndex--;

    if (!this.getQuestion(questionnaireID)) {
      // See if we have a new section to go to
      quesObj.activeSectionIndex--;
      quesObj.activeQuestionIndex = this.getSection(questionnaireID).questions.length - 1;

    }

    return quesObj.activeQuestionIndex;
  }

  next(questionnaireID: number) {
    let quesObj = this.questionnaires.find( item=>{
      return item.questionnaire.id ==questionnaireID ;
    });
    quesObj.activeQuestionIndex++;

    if (!this.getQuestion(questionnaireID)) {
      // See if we have a new section to go to
      quesObj.activeSectionIndex++;
      quesObj.activeQuestionIndex = -1;

      if (!this.getSection(questionnaireID)) {
        throw Error('end of questionnaire');
      }
    }

    return quesObj.activeQuestionIndex;
  }

  continueInProgress(questionnaireID: number): void {
    // Set the active section and question number by looking at questionnaire object
    let quesObj = this.questionnaires.find( item=>{
      return item.questionnaire.id ==questionnaireID ;
    });
    for (let i = 0; i < quesObj.questionnaire.sections.length; i++) {
      for (let j = 0; j < quesObj.questionnaire.sections[i].questions.length; j++) {
        if (quesObj.questionnaire.sections[i].questions[j].complete === false) {
          quesObj.activeSectionIndex = i;
          quesObj.activeQuestionIndex = (j === 0) ? -1 : j;
          return;
        }
      }
    }

  }

  setActiveQuestionNumberOnIntroContinue(questionnaireID: number) {
    
    let quesObj = this.questionnaires.find( item=>{
      return item.questionnaire.id ==questionnaireID ;
    });
      let section = this.getSection(questionnaireID);
      if (section && section.questions && section.questions.length > 0) {
        let questions = section.questions;
        for (let j = 0; j < questions.length; j++) {
          if (questions[j].complete === false) {
            quesObj.activeQuestionIndex = j;
            return true;
          }
        }
      }

      quesObj.activeQuestionIndex = 0;
      return true;
  }
}
