import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
  })
export class DesktopChecker {
    public isDesktop: boolean;

    constructor() {
        this.isDesktopView();
        window.onresize = (e) => {
            this.isDesktopView();
        };
    }

    private isDesktopView() {
        if(window.innerWidth >= 1000) {
            this.isDesktop = true;
        } else {
            this.isDesktop = false;
        }
    }
}