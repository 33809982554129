import { Injectable } from '@angular/core';
//import 'rxjs/add/operator/timeout';
//import 'rxjs/add/operator/map';
import { Appapi } from './appapi';
import { CoursesService } from './courses-service';
import { Observable } from 'rxjs';
import { MyDb } from 'src/libs/MyDb';
import { MyUtil } from 'src/libs/MyUtil';



/**
 * General calculation engine service to calculate performance of the user
 * calculate in an asynchronized way and notify UI update via observable subscribe
 * cache calculate result for UI to show immediately
 */
@Injectable()
export class Appcalc {
  perSkills = {}; // array of skill completeness indexed timeframe, then by skill id
  perActivities: any = {}; // array of completed user activity indexed by activity id
  perCourses: any = {}; // array of completed user activity indexed by activity id
  performancePerSkills: any = {}; // cache array of user skill performance indexed timeframe, then by skill id
  performancePerGoals: any = {}; // cache array of user goal performance indexed by user goal id
  engineStatus: any = { // current status of the calculation engine
    performancePerSkillsUpdatedAt: null,
    performancePerGoalsUpdatedAt: null,
  };
 
  constructor(public appapi: Appapi, public coursesService: CoursesService) {}
 
  completedCoursesIds = [];
  completedCourseSkills = []; 
   //timeLogged = 0; 
 
  refreshCompletedActivities(): Observable<any> {
    return Observable.create((observer) => {
      // get all completed activity
      let queryOptions: any = {
        key: [MyUtil.DOC_TYPE.USER_ACTIVITY, MyUtil.CONST.APP_META.USER_ACTIVITY_STATUS_DONE],
        include_docs: true
      };
 
      MyDb.userQuery('by_type_status', queryOptions).then(queryResult => {
        this.coursesService.getUsersCourses().then((userCourses) => {
          this.coursesService.getAllUserCourseActivities().then(courseActivities => {
          
            let docs = MyDb.flatQueryResult(queryResult);
    
            let skills: any = {},
              activities: any = {};
    
            //Get the unwrapped activities skills
            MyUtil.lodash.forEach(docs, (doc) => {
              if (doc && !doc.delete && doc.data) {
                let userActivity = doc.data;
                if (userActivity.activity_id) {
                  let activity = MyUtil.getActivity(userActivity.activity_id);
    
                  // only continue if activity is available in cache
                  if (activity) {
                    // build activities structure
                    activities[activity.id] = {
                      time_logged: userActivity.time_logged
                    }
    
                    // build skills structure
                    MyUtil.lodash.forEach(activity.skills, (skillId) => {
                      if (!skills[skillId]) {
                        skills[skillId] = {
                          time_logged: 0,
                          activities: [],
                          courses: [],
                        };
                      }
    
                      skills[skillId].time_logged += userActivity.time_logged;
                      skills[skillId].activities.push({
                        id: userActivity.id,
                        activity_id: activity.id,
                        ts: activity.start_at,
                        time_logged: userActivity.time_logged
                      });
                    });
                  }
                }
              }
            });
 
            if(userCourses.data){
              //Get the course skills
              Object.keys(userCourses.data).forEach((key)=> {
                if(userCourses.data[key].course_complete === 1 && this.completedCoursesIds.indexOf(userCourses.data[key].id) === -1) {            
                  this.completedCoursesIds.push(userCourses.data[key].id);
                  // each course get the skills
                  let courseSkills = [];

                  userCourses.data[key].skills.forEach((id) => {
                    let skilldata = {
                      skill_id: id,
                      course_id: userCourses.data[key].id,
                      course_name: userCourses.data[key].name,
                      ts: userCourses.data[key].start_at,
                      start_at: userCourses.data[key].start_at,
                      end_at: userCourses.data[key].end_at,
                      time_logged: 0
                    }
                    courseSkills.push(skilldata)
                  })

                  let courseData = {
                    course_id: userCourses.data[key].id,
                    skills: courseSkills
                  }
                
                  let courseTimeLogged = 0;
                  courseActivities.forEach(courseActivity => {
                    if(courseData.course_id === courseActivity.course_id) {
                      courseTimeLogged = courseTimeLogged + courseActivity.time_logged;
                    }
                  });

                  courseData.skills.forEach((item) => {
                    item.time_logged = courseTimeLogged;
                  });
                  this.completedCourseSkills.push(courseData);
                }
              })
            }
            
          
            MyUtil.lodash.forEach(this.completedCourseSkills, (courseData) => {
              // build skills structure
              MyUtil.lodash.forEach(courseData.skills, async(skill) => {
                if (!skills[skill.skill_id]) {
                  skills[skill.skill_id] = {
                    time_logged: 0,
                    activities: [],
                    courses: []
                  };
                }

                skills[skill.skill_id].time_logged += skill.time_logged;
                skills[skill.skill_id].courses.push({
                  id: skill.skill_id,
                  course_id: skill.course_id,
                  name: skill.course_name,
                  ts: skill.ts,
                  start_at: skill.start_at,
                  end_at: skill.end_at,
                  time_logged: skill.time_logged
                });
              })
            })

            MyUtil.lodash.forEach(skills, (skill) => {
              skill.activities = MyUtil.lodash.orderBy(skill.activities, ['ts'], ['asc']);
            });
    
            this.perSkills = skills;
            this.perActivities = activities;
            this.perCourses = skills;
    
            observer.next(skills);
          })
        })
      })
    });
      
  }
 
  refreshPerformancePerSkills(interval: number, orgId?: number): Observable<void> {
     return new Observable((observer) => {
        this.refreshCompletedActivities().subscribe((data) => {
         
         let intervalMap = {
           0: 0,
           1: MyUtil.getMoment().subtract(1, 'year').unix(),
           2: MyUtil.getMoment().subtract(1, 'quarter').unix(),
           3: MyUtil.getMoment().subtract(1, 'month').unix(),
         },
           startAt = (intervalMap[interval] ? intervalMap[interval] : 0);
 
         let skillIds = [];
 
         let performance = MyUtil.lodash.chain(this.perSkills).map((skill, id) => {
 
           // calculate by timeframe and
           let timeLogged = MyUtil.lodash.chain(skill.activities).filter((activity) => {
             return (activity.ts >= startAt);
           }).sumBy('time_logged').value();
 
           let timeLoggedCourses = MyUtil.lodash.chain(skill.courses).filter((course) => {
             return (course.ts >= startAt);
           }).sumBy('time_logged').value();
           
           timeLogged = timeLogged + timeLoggedCourses;
 
 
           let activities = MyUtil.lodash.chain(skill.activities).filter((activity) => {
             return (activity.ts >= startAt);
           }).map((item) => {
             return {
               id: item.id,
               activity_id: item.activity_id,
               time_logged: item.time_logged / 60
             }
           }).value();
           
           let courses = MyUtil.lodash.chain(skill.courses).map((item) => {
             return {
               id: item.id,
               name: item.name,
               course_id: item.course_id,
               start_at: item.start_at,
               end_at: item.end_at,
               time_logged: item.time_logged / 60
             }
           }).value();
           // remember included skill
           skillIds.push(id);
           let skObject = MyUtil.getSkillWithoutProgramFiltering(id);
           if (skObject) {
             return {
               id: id,
               name: skObject.name,
               oid: skObject.oid,
               value: timeLogged / 60, // convert to hours
               activities: activities,
               courses: courses,
               programs: skObject.programs,
             };

           } else {
             return null;
           }
         }).filter((item) => {
          
           // filter empty item
           if (item) {
             // filter org
             if (orgId) {
               return (orgId == item.oid);
             } else {
               return true;
             }
           } else {
             return false;
           }
         }).value();
 
         // add missing skills
         MyUtil.lodash.forEach(MyUtil.getSkillsWithoutProgramFiltering(orgId), (skill, id) => {
           if (skillIds.indexOf(id) === -1) {
             performance.push({
               id: id,
               name: skill.name,
               oid: skill.oid,
               value: 0,
               activities: [],
               courses: [],
               programs: skill.programs,
             });
           }
         });
 
         // cache by timeframe
         this.performancePerSkills[interval] = performance;
 
         observer.next(performance);
       })
     });
   }
 
   refreshPerformancePerGoals(): Observable<void> {
     return new Observable((observer) => {
       this.refreshCompletedActivities().subscribe(() => {
         
         // get all user goals and iterate through
         this.appapi.queryUserGoals().then((result) => {
           // get all user completed activities
           let completedActivityIds = MyUtil.lodash.keys(this.perActivities);
 
           completedActivityIds = MyUtil.lodash.map(completedActivityIds, (item) => {
             return Number(item);
           });
 
           MyUtil.lodash.forEach(result, (userGoal) => {
             let goal = MyUtil.getGoal(userGoal.goal_id);
 
             // only continue if goal is availabel in cache
             if (goal) {
               let completeness = 0;
               let completed_activities = {};
 
               if (goal.calc_type == MyUtil.CONST.APP_META.GOAL_CALC_TYPE_ATTEND_COUNT) {
                 // Goal with activities to complete
 
                 let requiredActivityIds = MyUtil.lodash.map(goal.activities, (item) => {
                   return item;
                 });
 
                 let intersectedActivityIds = MyUtil.lodash.filter(completedActivityIds, (id) => {
                   return requiredActivityIds.indexOf(id) > -1;
                 });
 
                 let num_complete_activities = intersectedActivityIds.length;
                 let num_activities = Number(goal.attend_count);
 
                 completed_activities = MyUtil.lodash.map(requiredActivityIds, (id) => {
                   return {
                     id: id,
                     completed: completedActivityIds.indexOf(id) > -1,
                   };
                 });
 
                 // completeness = ( number_complete_activities / total_number_of activities )
                 if (num_complete_activities > num_activities) {
                   completeness = 1;
                 } else {
                   completeness = num_complete_activities / num_activities ;
                 }
 
 
               } else if(goal.calc_type == MyUtil.CONST.APP_META.GOAL_CALC_TYPE_TIME_EFFORT) {
                 // Goal with skills to complete
 
                 let skillId = goal.skills[0];
                 let startedAt = userGoal.started_at;
                 let num_hours = Number(goal.time_effort);
                 let num_skill_hours = 0;
                 let completed_skill_user_activities = MyUtil.lodash.chain({});
 
                 if (this.perSkills[skillId] && this.perSkills[skillId].activities) {
                   // only count activities after goal started
                   completed_skill_user_activities = MyUtil.lodash.chain(this.perSkills[skillId].activities).filter((activity) => {
                     return (activity.ts >= startedAt);
                   });
                   num_skill_hours = completed_skill_user_activities.sumBy('time_logged').value();
                 }
 
                 completed_activities = completed_skill_user_activities.map((user_activity) => {
                   return {
                     id: user_activity.activity_id,
                     time_logged:user_activity.time_logged,
                     completed: true,
                   };
                 }).value();
 
                 if (num_skill_hours > num_hours) {
                   completeness = 1;
                 } else {
                   completeness = num_skill_hours / num_hours ;
                 }
               }
 
               let performace = {
                 completeness: completeness,
                 items: completed_activities,
               };
 
               this.performancePerGoals[userGoal.id] = performace;
             }
           });
           observer.next();
         });
       })
     });
   }
 
   getProfileChartData(interval: number, programId?: number) {
     let result = null;
     if (this.performancePerSkills[interval]) {
       result = MyUtil.lodash.filter(this.performancePerSkills[interval], (item) => {
         return item && (!programId || (item.programs.length === 0 || item.programs.indexOf(programId) !== -1));
       });
     }
     return result;
   }
 
   getGoalChartData(id: number) {
     let result = 0;
 
     if (this.performancePerGoals[id]) {
       result = this.performancePerGoals[id];
     }
 
     return result;
   }
 
 }
 