import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder
} from "@angular/forms";
import { ActivitySelectPage } from '../activity-select/activity-select';
import { MyUtil } from "../../../libs/MyUtil";
import { CourseQuestionsPage } from "../course-questions/course-questions";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { CancelBookingModal } from "src/app/components/cancel-booking-modal/cancel-booking-modal";
import { FileTransfer } from "@awesome-cordova-plugins/file-transfer/ngx";
import { NavParams } from "@ionic/angular";
import { ActivityService } from "src/app/providers/activity-service";
import { Appapi } from "src/app/providers/appapi";
import { CoursesService } from "src/app/providers/courses-service";
import { DesktopChecker } from "src/app/providers/desktopChecker";
import { FileService } from "src/app/providers/file-service";
import { takeUntil } from 'rxjs/operators';
import { Subject } from "rxjs";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { TermsModalComponent } from "src/app/components/terms-modal/terms-modal.component";
import { RecurringEventsModalComponent } from 'src/app/components/recurring-events-modal/recurring-events-modal.component';

declare var google;

@Component({
  selector: "page-course-details",
  templateUrl: "course-detail.html",
  styleUrls: ["course-detail.scss"],
})
export class CourseDetailPage implements OnInit, OnDestroy {

  // Component variables.
  @ViewChild('Content', { static: true }) content;
  pageData: any = {};
  routeData: any = {};
  tabIndex = 0;

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public router: Router,
    public navParams: NavParams,
    public formBuilder: UntypedFormBuilder,
    public appapi: Appapi,
    private route: ActivatedRoute,
    public sanitiser: DomSanitizer,
    public courseService: CoursesService,
    public desktopChecker: DesktopChecker,
    public activityService: ActivityService,
    public fileService: FileService,
    public transfer: FileTransfer,
    public coursesService: CoursesService,
    private titleService: Title) 
  { 
    this.titleService.setTitle("Course Detail");
  }

  async ngOnInit() {

    this.pageData.loaded = false;

    if (!this.appapi.isLoggedIn()) {
      return;
    }
    
    let loading = MyUtil.presentLoading();

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData = JSON.parse(params.pageData);
    });

    //Refresh from back-end
    await this.appapi.syncSingleCourse(this.routeData.id);

    //Check course exists
    this.pageData.course = MyUtil.getCourse(this.routeData.id);
    if(!this.pageData.course) {
      (await loading).dismiss();
      MyUtil.presentToast('Course not found', { cssClass: 'error' });
      this.router.navigate(['/']);
      return;
    }

    this.handleCalendarLinkModal();

    //Get user activity/booking details
    this.pageData.userCourse = MyUtil.getUserCourse(this.pageData.course.id);
    if (this.pageData.userCourse) {
      this.tabIndex = 0;
    } else {
      this.tabIndex = 1;
    }

    //Fetch other data
    await Promise.all([
      this.fetchRecurringGroupDetails(),
      this.fetchCapacityStatusDetails(),
    ]);

    this.pageData.homeOrg = MyUtil.getRootOrganization();
    this.pageData.profile = MyUtil.getProfile();

    //Course started? ie. date/time of first activity
    let currentTimestamp = MyUtil.getUnixTimeStamp();
    this.pageData.courseStarted = currentTimestamp > this.pageData.course.start_ts;
    
    //Course complete?
    this.pageData.courseComplete = !!this.pageData.userCourse?.completed_at;

    //Skills list
    let skillsArray = MyUtil.lodash
      .chain(this.pageData.course.skills)
      .map((id) => {
        return MyUtil.getSkill(id).name;
      })
      .filter((item) => {
        return (item != undefined);
      })
      .value();
      
    this.pageData.skillsList = MyUtil.lodash.chain(skillsArray).value().join(", ");

    //Human-readable date range
    this.pageData.period = MyUtil.getLongDisplayDateTime(this.pageData.homeOrg,
      this.pageData.course.start_at,
      null,
      this.pageData.course.end_at,
      null
    );

    //Disabled text (booking open/closed)
    this.pageData.invalidBookingTimeframe = false;
    if(this.pageData.course.booking_opens_ts > currentTimestamp) {
      this.pageData.invalidBookingTimeframe = "Booking opens at " + this.pageData.activity.booking_opens_formatted;
    } else if(this.pageData.course.booking_closes_ts < currentTimestamp) {
      this.pageData.invalidBookingTimeframe = "Booking closed";
    }

    //Strike ban text (exclude shared activities)
    this.pageData.strikeBanText = false;
    if(this.pageData.profile.strike_ban_text) {
      this.pageData.strikeBanText = this.pageData.profile.strike_ban_text;
    }
    

    //Get the course activities
    this.pageData.activitiesList = [];
    this.pageData.clashingActivities = [];
    let clashableActivities = MyUtil.getClashableActivities();
    if(this.pageData.userCourse) {
      //Course is booked - get the booked course activities only
      this.pageData.courseProgressPercentage = 0;
      let mandatoryActivityCount = 0;
      let mandatoryActivitiesCompleted = 0;
  
      MyUtil.lodash.forEach(this.pageData.course.course_activities, (courseActivity) => {

        let userActivity = MyUtil.getUserActivity(courseActivity.activity_id);
        if(userActivity) {
          let activity = MyUtil.getActivity(courseActivity.activity_id);
          if(activity) {
            // format date/time for display
            activity.period = MyUtil.getShortDisplayDateTime(this.pageData.homeOrg,
              activity.start_at,
              activity.start_time,
              activity.end_at,
              activity.end_time
            );

            activity.completed_at = userActivity.completed_at;
            activity.time_logged = userActivity.time_logged;

            //Increment totals for course progress calculation
            if(courseActivity.is_required) {
              mandatoryActivityCount++;
              if(activity.completed_at) {
                mandatoryActivitiesCompleted++;
              }
            }
      
            this.pageData.activitiesList.push(activity);

            //Get any clashing activities
            let clashingActivities = MyUtil.getClashingActivities(activity, clashableActivities);
            if(clashingActivities.length > 0) {
              this.pageData.clashingActivities[activity.id] = clashingActivities;
            }
          }
        }
      });

      if(mandatoryActivityCount > 0) {
        this.pageData.courseProgressPercentage = (100 * mandatoryActivitiesCompleted) / mandatoryActivityCount;
      }      
    } else {
      //Course is not booked - get all the course activities
      this.pageData.courseProgressPercentage = 0;
      MyUtil.lodash.forEach(this.pageData.course.course_activities, (courseActivity) => {

        let activity = MyUtil.getActivity(courseActivity.activity_id);
        if(activity) {
          //Mandatory?
          activity.is_required = courseActivity.is_required;
    
          // format date/time for display
          activity.period = MyUtil.getShortDisplayDateTime(this.pageData.homeOrg,
            activity.start_at,
            activity.start_time,
            activity.end_at,
            activity.end_time
          );
    
          this.pageData.activitiesList.push(activity);

          //Get any clashing activities
          let clashingActivities = MyUtil.getClashingActivities(activity, clashableActivities);
          if(clashingActivities.length > 0) {
            this.pageData.clashingActivities[activity.id] = clashingActivities;
          }
        }
      });
    }

    //Set the clash message
    this.pageData.bookingClashMessage = '';
    if (this.pageData.clashingActivities.length > 0) {
      if (this.pageData.userCourse) {
        if (this.pageData.userCourse.is_booked) {
          this.pageData.bookingClashMessage = 'WARNING - One or more booked activities conflict with another booking';
        } else {
          this.pageData.bookingClashMessage = 'WARNING - One or more waiting/application list activities conflict with another booking';
        }
      } else {
        this.pageData.bookingClashMessage = 'WARNING - One or more course activities potentially conflict with an existing booking';
      }
    };    

    this.pageData.helperToggle = true;

    this.pageData.loaded = true;
    (await loading).dismiss();
  }

  /**
   * Gets any alternative dates (defined by the recurring group)
   * 
   * @returns Promise<any>
   */
  private fetchRecurringGroupDetails() : Promise<any> {
    this.pageData.recurringRulesRestricted = false;
    this.pageData.recurringGroup = null;
    if(this.pageData.course.recurring_group_id) {
      return this.appapi.getRecurringGroup(this.pageData.course.recurring_group_id, this.pageData.course.id).then((result) => {
        this.pageData.recurringGroup = result;
        this.pageData.recurringRulesRestricted = this.pageData.recurringGroup.booking_restricted;
      });
    } else {
      return;
    }
  }

  /**
   * Get booking capacity details
   * 
   * @returns Promise<any>
   */
  private fetchCapacityStatusDetails() : Promise<any> {
    this.pageData.capacityStatus = false;
    return this.appapi.getCourseCapacityStatus(this.pageData.course.id).then((capacityStatus) => {
      this.pageData.capacityStatus = capacityStatus;
    });
  }
  

  


   /** 
  * Refresh page.
  */
  public refreshPage() {
    //Passing in a UNIX timestamp ensures the page is refreshed every time. (Page doesn't need any other parameters to load itself apart from course id)
    this.router.navigate(['/CourseDetailPage', JSON.stringify({ id: this.pageData.course.id, t: MyUtil.getUnixTimeStamp() })]);
  }


  /** 
  * Open activity.
  * @param id (number)
  */
  public openActivity(id: number) {
    this.router.navigate(['/ActivityDetailPage', JSON.stringify({ id: id })]);
  }



  // for mark as complete widget. will need to be re-added so keep here
  private completeActivity(id) {
    // go to complete page, no pop up
    this.pageData.noBookingWarningPopup = true;

    let params = {
      id: id,
      backToGoal: false,
      attendanceCode: null,
    };
    this.router.navigate(['/ActivityCompletePage', JSON.stringify(params)]);
  }



  /** 
  * Book course.
  * 
  * @param options { firmBooking : boolean, waitingList: boolean, applicationList : boolean, hybridOnline: boolean, convertBooking: boolean }
  */
  public async bookCourse(options) {

    if (this.pageData.course.terms_text) {
      //Display popup asking user to agree to Ts&Cs
      let modal = MyUtil.createModal(TermsModalComponent,
        {
          message: this.sanitiser.bypassSecurityTrustHtml(this.pageData.course.terms_text),
        });

      (await modal).onDidDismiss().then((data: any) => {
        if (data.data !== undefined) {
          this.openCourseQuestionsModal(options);
        }
      });

      (await modal).present();
    } else {
      //No Ts&Cs
      this.openCourseQuestionsModal(options);
    }

    return;
  }


  /** 
  * Book hybrid course/convert hybrid course booking type
  * 
  * @param options { firmBooking : boolean, waitingList: boolean, applicationList : boolean, hybridOnline: boolean, convertBooking: boolean }
  */
  public async bookHybridCourse(options) {

    if (options.convert) {
      //Convert from one hybrid booking type to another
      let convertFrom = options.hybridOnline ? 'in-person' : 'online';
      let convertTo = options.hybridOnline ? 'online' : 'in-person';

      let fromStatus = '';
      if(this.pageData.userCourse.is_booked) {
        fromStatus = 'booking';
      } else if(this.pageData.userCourse.on_waiting_list) {
        fromStatus = 'waiting list';
      } else if(this.pageData.userCourse.on_application_list) {
        fromStatus = 'application list';
      }

      let toStatus = '';
      if(options.firmBooking) {
        toStatus = 'booking';
      } else if(options.waitingList) {
        toStatus = 'waiting list';
      } else if(options.applicationList) {
        toStatus = 'application list';
      }

      if(fromStatus && toStatus) {
        let convertMessage = `<b>Convert from ${convertFrom} ${fromStatus} to ${convertTo} ${toStatus}?</b><br/><br/>This will release your ${convertFrom} place and move you to the ${convertTo} ${toStatus}.`;
        
        if(this.pageData.userCourse.is_booked && !options.firmBooking) {
          convertMessage = convertMessage + " You will no longer have a confirmed place.";
        } else if(this.pageData.userCourse.on_waiting_list) {
          convertMessage = convertMessage + ` The ${convertFrom} event is full, so you may not be able to return to an ${convertFrom} booking.`;
        } else if(this.pageData.userCourse.on_application_list) {
          convertMessage = convertMessage + ` The ${convertFrom} event is by application only, so you may not be able to return to an ${convertFrom} booking.`;
        }
      
        MyUtil.presentAlert({
          message: convertMessage,
          buttons: [
            {
              text: 'Cancel',
              handler: () => {
                return;
              }
            },
            {
              text: 'Confirm',
              handler: () => {
                this.openCourseQuestionsModal(options);
              }
            }
          ],
        });
      }
    } else {
      //New booking
      if (this.pageData.course.terms_text) {
        //Display popup asking user to agree to Ts&Cs
        let modal = MyUtil.createModal(TermsModalComponent, { message: this.sanitiser.bypassSecurityTrustHtml(this.pageData.course.terms_text) });
        (await modal).onDidDismiss().then((data: any) => {
          if (data.data !== undefined) {
            this.openCourseQuestionsModal(options);
          }
        });

        (await modal).present();
      } else {
        //No Ts&Cs
        this.openCourseQuestionsModal(options);
      }
    }
  }


  /** 
  * Open course questions modal
  * 
  * @param options { firmBooking : boolean, waitingList: boolean, applicationList : boolean, hybridOnline: boolean, convertBooking: boolean }
  */
  public openCourseQuestionsModal(options : any) {

    this.appapi.getCourseBookingOptions(this.pageData.course.id).then(async (questionsData) => {

      let filteredQuestionsData = [];
      if (questionsData.length > 0) {
        //If hybridOnline, remove any activity booking questions that don't make sense in an online context (parking etc)
        if (options.hybridOnline) {
          filteredQuestionsData = questionsData.filter((question) => {
            return question.label != 'require_accommodation'
              && question.label != 'require_parking'
              && question.label != 'require_dietary'
              && question.label != 'require_wheelchair'
              && question.label != 'require_accessible_parking'
          })
        } else {
          filteredQuestionsData = questionsData;
        }
      }

      if (filteredQuestionsData.length > 0) {

        // create questions modal
        let courseQuestionsModal = MyUtil.createModal(CourseQuestionsPage, { questions: filteredQuestionsData });
        (await courseQuestionsModal).onDidDismiss().then(questionResponses => {
          if (questionResponses.data != null) {
            if (options.convert) {
              this.convertBooking(questionResponses.data, options);
            } else {
              this.openActivitySelectModal(questionResponses.data, options);
            }
          }
        });

        (await courseQuestionsModal).present();

      } else {
        if (options.convert) {
          this.convertBooking({}, options);
        } else {
          this.openActivitySelectModal({}, options);
        }
      }
    });
  }


  /** 
  * Open activity select modal
  * 
  * @param questionData
  * @param options { firmBooking : boolean, waitingList: boolean, applicationList : boolean, hybridOnline: boolean, convertBooking: boolean }
  */
  public async openActivitySelectModal(questionData: any, options: any) {

    let activitySelectModal = MyUtil.createModal(ActivitySelectPage, { fromGoals: false, activities: this.pageData.activitiesList, clashingActivities: this.pageData.clashingActivities });
    (await activitySelectModal).onDidDismiss().then((selectedActivities: any) => {
      if (selectedActivities.data != null) {
        if (selectedActivities.data && selectedActivities.data.length > 0) {
          let postObj = {
            activities: selectedActivities.data,
            details: questionData?.details,
            hybridOnline: options.hybridOnline,
          }

          let loading = MyUtil.presentLoading();
          this.courseService.bookPlaceOnCourse(this.pageData.course.id, postObj).then(async (result) => {
            if(result) {
              let message = "Booking confirmed";
              if (result["#status"] == "failed") {
                //Display message sent back from server
                message = result["#message"];
              } else if (result["#status"] == "success") {              
                if (options.waitingList) {
                  message = "You have been added to the course waiting list";
                } else if (options.applicationList) {
                  message = "You have been added to the course application list";
                }
              }

              MyUtil.presentToast(message, {
                duration: MyUtil.CONST.DURATION_TOAST_LONG,
                cssClass: 'inkpath-toast'
              });

              //This is a bit sledgehammer but make sure all the course activity bookings are in the cache before the page refreshes
              await this.appapi.syncUserActivities();
              this.refreshPage(); 
            }
            (await loading).dismiss();
          }).catch(async () => {
            (await loading).dismiss();
          });
        }
      }
    });

    (await activitySelectModal).present();
  }


  /**
   * 
   * @param questionData 
   * @param options { firmBooking : boolean, waitingList: boolean, applicationList : boolean, hybridOnline: boolean, convertBooking: boolean }
   */
  convertBooking(questionData: any, options: any) {

    let postObj = {
      details: questionData.details,
      hybridOnline: options.hybridOnline,
    }

    let loading = MyUtil.presentLoading();
    this.courseService.convertHybridCourseBooking(this.pageData.course.id, postObj).then(async (result) => {

      if(result) {
        //Success
        let type = options.hybridOnline ? 'online' : 'in-person';
        let message = `Booking converted to ${type}`;
        if (options.waitingList) {
          message = `You have been added to the ${type} course waiting list`;
        } else if (options.applicationList) {
          message = `You have been added to the ${type} course application list`;
        }

        MyUtil.presentToast(message, {
          duration: MyUtil.CONST.DURATION_TOAST_LONG,
          cssClass: 'inkpath-toast'
        });
        this.refreshPage();  
      }
      (await loading).dismiss();
    }).catch(async (err) => {
      (await loading).dismiss();
    });
  }


  /** 
  * Show booking cancel modal.
  * 
  * @param options { firmBooking : boolean, waitingList: boolean, applicationList : boolean }
  */
  public async showBookingCancelModal(options) {

    let title = "Are you sure you want to cancel your booking?";
    let buttonText = "Confirm";
    if (options.waitingList) {
      title = "Are you sure you want to cancel your place on the waiting list?";
    } else if(options.applicationList) {
      title = "Are you sure you want to cancel your place on the application list?";
    }

    let cancelBookingModal = MyUtil.createModal(CancelBookingModal, {
      title: title,
      message: "Please select the reason for your cancellation:",
      buttons: [
        {
          text: "Close",
        },
        {
          text: buttonText,
        },
      ],
      labels: MyUtil.CANCEL_BOOKINGS_LABELS,
    });

    (await cancelBookingModal).onDidDismiss().then((cancelReason: any) => {
      if (cancelReason.data) {
        this.cancelCourseBooking(options, cancelReason.data);
      }
    });

    (await cancelBookingModal).present();
  }



  /** 
 * Cancel course booking.
 */
  private cancelCourseBooking(options: any, cancelReason: number) {

    let loading = MyUtil.presentLoading();
    this.courseService.cancelBooking(this.pageData.course.id, cancelReason).then(async (res) => {
      if (res) {
        let message = "Course booking cancelled";
        if (options.waitingList || options.applicationList) {
          message = "Place cancelled";
        }

        MyUtil.presentToast(message, {
          duration: MyUtil.CONST.DURATION_TOAST_LONG,
          cssClass: 'inkpath-toast'
        });

        this.refreshPage();
      }

      (await loading).dismiss();
    });
  }
  


  /**
   * 
   * @returns Show other available dates for the recurring course group
   */
  public async showAlternativeDates() {

    if (this.pageData.recurringGroup && this.pageData.recurringGroup.alternative_dates.length > 0) {
      //Display popup showing alternative dates for this course
      let modal = MyUtil.createModal(RecurringEventsModalComponent,
        {
          message: '',
          eventName: this.pageData.course.name,
          type: 'course',
          alternativeDates: this.pageData.recurringGroup.alternative_dates
        });
      (await modal).present();
    }

    return;
  }



  // for ngStyle on activity status labels
  public getStatusStyle(status: string) {
    switch (status) {
      case "Not Started": {
        return {
          "color": "red",
          "background": "#4c181d"
        }
      }
      case "Active": {
        return {
          "color": "yellow",
          "background": "#9e770a"
        }
      }
      case "Done": {
        return {
          "color": "green",
          "background": "#0f4808"
        }
      }
    }
  }


  /** 
   * Checks if profile matches the activity's programme, phase and subsidiary - for direct calenadar and "restricted" links 
  */
  handleCalendarLinkModal() {
    // If is not an admin viewing the page from the admin area ignore the filters and only apply to calendar links.
    if (this.routeData.calendar) {
      // Filter by: phases.
      let prof = MyUtil.getProfile();
      let phasekeys = [prof.phase_id];
      if (!!prof.fundings) {
        let phases = Object.keys(prof.fundings);
        phases.forEach(phase => {
          phasekeys.push(prof.fundings[phase].phase_id);
        });
      }

      // If user has no related phases or programmes relating to the course show a message.
      if ( 
          !this.isPartOfUserOrganisation(this.pageData) ||
          !this.isPartOfPhase(phasekeys, this.pageData) || 
          !this.isPartOfProgram(MyUtil.getProfileProgramIds(), this.pageData)
        ) {
          // redirect to home page with message
          MyUtil.presentToast(
            "You don't have access to this course.", { cssClass: 'inkpath-toast' }
          );

          this.router.navigate(['/']);

      }
    }
  }

  // check if user has access to the activity organisation
  isPartOfUserOrganisation(activity) {
    let orgs = MyUtil.getOrgnizationChain();
    let access = false;

    orgs.forEach( org => {
      if(org.id === activity.oid) {
        access = true;
      }
    })

    return access;
  }

  // Check if is part of program.
  isPartOfProgram(programIds, element) {
    //return function (element) {
      let contains = false;
      if (element.programs && element.programs.length > 0) {
        console.log(element.programs);
        element.programs.forEach(prog => {
          console.log(prog.id);
          if (programIds.indexOf(prog.id) > -1) {
            contains = true;
          }
        });
      } else {
        contains = true;
      }
      return contains;
    //}
  }

  // Check if is part of program.
  isPartOfPhase(phases, element) {
    //return function (element) {
      let contains = false;
      if (element.phases && element.phases.length > 0) {
        element.phases.forEach(phs => {
          if (phases.indexOf(phs.id) > -1) {
            contains = true;
          }
        });
      } else {
        contains = true;
      }
      return contains;
    //}
  }


  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
