import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getBrandName, getDisplayMode, getDisplayTheme, getFontSize } from 'src/app/store/selectors/view.selector';
import { MyUtil } from 'src/libs/MyUtil';
import * as appStore from '../../store';

@Component({
  selector: 'app-accessibility-modal',
  templateUrl: './accessibility-modal.component.html',
  styleUrls: ['./accessibility-modal.component.scss'],
})
export class AccessibilityModalComponent implements OnInit, OnDestroy{

  constructor(
    public viewCtrl: ModalController,
    private store: Store<appStore.AppViewState>
  ) { }

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  // Component variables.
  fontSize$!: Observable<string>;
  branding = null;
  brandName$: Observable<string>   = this.store.select(getBrandName);
  displayMode$!: Observable<string>;
  displayTheme$!: Observable<string>;
  
  ngOnInit() {
    this.displayMode$ = this.store.select(getDisplayMode);
    this.displayTheme$ = this.store.select(getDisplayTheme);
    this.fontSize$ = this.store.select(getFontSize);
  }

  /**
  * Set Display Mode: light-mode, dark-mode, high-contrast-mode.
  * @param string
  */
  setDisplayMode(mode: string){
    this.store.dispatch(new appStore.DisplayModeSet(mode));
    MyUtil.saveToLocalStorage('displayMode', mode);
  }

  /**
  * Set Display Theme: simple-theme, static-theme, animated-theme.
  * @param string
  */
  setDisplayTheme(mode: string){
    this.store.dispatch(new appStore.DisplayThemeSet(mode));
    MyUtil.saveToLocalStorage('displayTheme', mode);
  }

  /**
  * Set Fomt Size: small-font, medium-font, large-font.
  * @param string
  */
  setFontSize(mode: string){
    this.store.dispatch(new appStore.FontSizeSet(mode));
    MyUtil.saveToLocalStorage('fontSize', mode);
  }

  /**
  * Close Modal
  */
  closeModal() {
    this.viewCtrl.dismiss();
  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

