import { Injectable } from "@angular/core";
import { MyUtil } from "src/libs/MyUtil";

import { Appapi } from "../appapi";

@Injectable()
export class DiagnosticService {
  constructor(public appapi: Appapi) {}


  /**
   * Get a list of the available disgnostic tests
   */
   public async getDiagnosticTests(): Promise<any> {

    let data = [];

    return await this.appapi
      .get('/diagnostic/tests', [])
      .then((result) => {
        if (result["#status"] === "success") {
          data = result["#data"];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
        return data;
      });

  }


  /**
   * Get a list of the available disgnostic tests
   */
   public getDiagnosticTestQuestions(id): Promise<any> {

    let data = [];

    return this.appapi
      .get('/diagnostic/test/'+id, [])
      .then((result) => {
        if (result["#status"] === "success") {
          data = result["#data"];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
        return data;
      });

  }


  public saveDiagnosticTestAnswers(id, answers, resumed: boolean = false): Promise<any> {

    let data = [];

    //Adding 'resumed' will prevent backend from saving a new iteration of answers
    let url = '/diagnostic/test/process-answers/' + id;
    if(resumed) {
      url = url = '/diagnostic/test/process-resumed-answers/' + id;
    }

    return this.appapi
      .post(url, {answers: answers})
      .then( (result) => {
        if (result["#status"] === "success") {
          data = result["#data"];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch( (err) => {
        MyUtil.error(err);
        return data;
      });
  }


  public getDiagnosticTestSubmission(id, iteration = null): Promise<any> {

    let data = [];

    let url = '';

    if(iteration) {
      url = '/diagnostic/test/submission/'+id+'/'+iteration;
    } else {
      url = '/diagnostic/test/submission/'+id;
    }

    return this.appapi
      .get(url, {})
      .then( (result) => {
        if (result["#status"] === "success") {
          data = result["#data"];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch( (err) => {
        MyUtil.error(err);
        return data;
      });
  }


  public async getPreviousDiagnosticSubmissions(testId): Promise<any> {
    let data = [];

    try {
      const result = await this.appapi
        .get('/diagnostic/test/submissions/'+testId, {});
      if (result["#status"] === "success") {
        data = result["#data"];
      } else {
        MyUtil.error(
          MyUtil.responseToMessage(result["#message"], result["#errors"])
        );
      }
      return data;
    } catch (err) {
      MyUtil.error(err);
      return data;
    }
  }


  public async sendSummaryEmail(testId): Promise<any> {
    let data = [];

    try {
      const result = await this.appapi.post('/diagnostic/test/submissions/email/'+testId, {});
      if (result["#status"] === "success") {
        data = result["#status"];
      } else {
        MyUtil.error(
          MyUtil.responseToMessage(result["#message"], result["#errors"])
        );
      }
      return data;
    } catch (err) {
      MyUtil.error(err);
      return data;
    }
  }



  // public async export(testId, type): Promise<any> {
  //   let data = [];

  //   try {
  //     const result = await this.appapi.post('/diagnostic/submissions/export/'+testId+'/'+type, {});
  //     if (result["#status"] === "success") {
  //       data = result["#status"];
  //     } else {
  //       MyUtil.error(
  //         MyUtil.responseToMessage(result["#message"], result["#errors"])
  //       );
  //     }
  //     return data;
  //   } catch (err) {
  //     MyUtil.error(err);
  //     return data;
  //   }
  // }


}
