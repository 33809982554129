/**
 * Wrapper for the component that is passed in to display the objects
 * in the index list.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
/**
 * Complete is initilised with a placeholder <div>
 */
@Component({
  selector: 'app-injected-wrapper',
  template: '<div #placeHolder></div>',
  styleUrls: []
})
export class InjectedWrapperComponent implements OnInit {

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  /**
  * The component passed in to create the components for object display - (Class).
  */
  @Input() component: any;

  /**
  * The value object to be rendered.
  */
  @Input() dataVO: any;

  /**
  * The parameter of the created component to which to pass the VO data.
  */
  @Input() paramName: string;

  /**
  * Clicked event emitter.
  */
  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(private readonly viewContainerRef: ViewContainerRef) { }

  ngOnInit() {
    // Create a component using the factory supplied in our wrapper.
    const createdComponent: any = this.viewContainerRef.createComponent(this.component);
    // Apply VO data to the component for display ( if the insertion parameter exists. )
    createdComponent.instance[this.paramName] = this.dataVO;
    createdComponent.instance.clicked.pipe(takeUntil(this.destroy$)).subscribe(item => {
      this.clicked.emit(item);
    });
  }

  // On destroy
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
