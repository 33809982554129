import { Component, OnDestroy, ElementRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { NgxCopilotService } from 'ngx-copilot';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccessibilityModalComponent } from 'src/app/components/accessibility-modal/accessibility-modal.component';
import { Appapi } from 'src/app/providers/appapi';
import { AuthProvider } from 'src/app/providers/auth/auth';
import { OnboardingService } from 'src/app/providers/onboarding/onboarding-service';
import { SsoLoginProvider } from 'src/app/providers/sso-login/sso-login';
import { UiuxService } from 'src/app/services/uiux.service';
import { WalkthroughService } from 'src/app/services/walkthrough.service';
import { appToOnboarding, getBrandName, getDisplayMode, getRedirectTarget, getSSO } from 'src/app/store/selectors/view.selector';
import { MyUtil } from 'src/libs/MyUtil';
import * as appStore from '../../store';
import { Title } from '@angular/platform-browser';
import { RedirectProvider } from 'src/app/providers/redirect/redirect';

declare var window: any;

/**
 * NOTE (IPD-1695)
 * Page segments have been changed to allow selecting of index as per the new Angular Material tabs
 * 0 = Login
 * 1 = SSO
 * 2 = Sign Up
 */

@Component({
  selector: 'page-login',
  templateUrl: 'login.html',
  styleUrls: ['login.scss'],

})
export class LoginPage implements OnInit, OnDestroy {

  // Component variables.
  appToOnboarding$: Observable<any> = this.store.select(appToOnboarding);
  branding = null;
  pageData: any = {};
  showPassword: boolean = false;
  routeData: any = {};
  static SSO_LOGIN_EVENT = 'SSO_LOGIN_EVENT';
  displayMode$: Observable<string> = this.store.select(getDisplayMode);
  brandName$: Observable<string> = this.store.select(getBrandName);
  pageLabel = 'LoginPage';
  //redirectTarget = '';
  sso$: Observable<string> = this.store.select(getSSO);
  isMobileView = this.uiux.isMobileView();
  showEmailPage: boolean = false;
  showPasswordPage: boolean = false;
  showEmailPasswordPage: boolean = false;
  showWelcomePage: boolean = true;
  showRegisterPage: boolean = false;

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();
  
  constructor(
    private el: ElementRef,
    private copilot: NgxCopilotService,
    private store: Store<appStore.AppViewState>,
    private route: ActivatedRoute,
    public onboardingService: OnboardingService,
    public router: Router,
    public navParams: NavParams,
    public redirectProvider: RedirectProvider,
    public uiux: UiuxService,
    public formBuilder: UntypedFormBuilder,
    public appapi: Appapi,
    public auth: AuthProvider,
    public ssoLogin: SsoLoginProvider,
    private titleService:Title) {}


  ngOnInit(): void {

    if(this.router.url == '/reload-login') {
      //Redirect from the app.component page - redirect again to the login url
      this.router.navigate(['/LoginPage']);
      return;
    }

    if (this.appapi.isLoggedIn()) {
      //Already logged in - redirect to home page
      this.router.navigate(['/']);
      return;
    }

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData = params.pageData ? JSON.parse(params.pageData) : {};
    });

    //Check that the branding script has loaded. Attempt ten times, with 0.2 second between each attempt.
    //Give up after 20 attempts
    let tryCount : number = 0;
    if(this.routeData.t) {
      tryCount = this.routeData.t;
    }
    if(tryCount <= 20) {
      if(!window.script_loaded) {
        tryCount++;
        setTimeout(() => this.router.navigate(['/LoginPage', JSON.stringify({ t: tryCount })]), 200);
      }
    } else {
      MyUtil.presentToast('Internet connection required - please try again later', { cssClass: 'error' });
    }

    if(!window.script_loaded) {
      return;
    }

    // define login form validation
    this.pageData.loginForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.maxLength(MyUtil.CONST.INPUT_MAX_LENGTH), Validators.pattern(MyUtil.CONST.REGREX_EMAIL), Validators.required])],
      password: ['', Validators.compose([Validators.maxLength(MyUtil.CONST.INPUT_MAX_LENGTH), Validators.required])],
    });

    this.pageData.emailForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.maxLength(MyUtil.CONST.INPUT_MAX_LENGTH), Validators.pattern(MyUtil.CONST.REGREX_EMAIL), Validators.required])],
    });

    this.pageData.passwordForm = this.formBuilder.group({
      password: ['', Validators.compose([Validators.maxLength(MyUtil.CONST.INPUT_MAX_LENGTH), Validators.required])],
    });

    this.pageData.registerForm = this.formBuilder.group({
      firstName: ['', Validators.compose([Validators.maxLength(MyUtil.CONST.INPUT_MAX_LENGTH), Validators.required])],
      lastName: ['', Validators.compose([Validators.maxLength(MyUtil.CONST.INPUT_MAX_LENGTH), Validators.required])],
    });

    // define sign up form validation
    this.pageData.signupForm = this.formBuilder.group({
      oid: [(window.app_branding ? window.app_branding.org_id : null), Validators.compose([Validators.required])],
      accept: [false, Validators.compose([(control: UntypedFormControl): any => {
        if (control.value) {
          return null;
        }
        return { 'not accepted': true };
      }, Validators.required])]
    });

    this.pageData.ssoForm = this.formBuilder.group({
      oid: [(window.app_branding ? window.app_branding.org_id : null), Validators.required],
      accept: [false, Validators.compose([(control: UntypedFormControl): any => {
        if (control.value) {
          return null;
        }
        return { 'not accepted': true };
      }, Validators.required])],
      accept2: [false, Validators.compose([(control: UntypedFormControl): any => {
        if (control.value) {
          return null;
        }
        return { 'not accepted': true };
      }, Validators.required])],
      accept3: [false, Validators.compose([Validators.required])],
    });

    //Don't do anything else unless local db initialised
    if (!this.appapi.isInitialized()) {
      return;
    }

    //Check for redirects
    if(this.redirectProvider.processRedirectTarget(this.router, null)) {
      return;
    }

    //Check if user already logged in
    if (this.appapi.isLoggedIn()) {
      this.router.navigate(['/home'])
      return;
    }

    this.titleService.setTitle("Login");

    this.pageData.universities = MyUtil.lodash.values(MyUtil.cache[MyUtil.DOC_ID.APP_UNIVERSITIES]);
    this.pageData.universities = MyUtil.lodash.sortBy(this.pageData.universities, ['name']);

    this.brandName$.pipe(takeUntil(this.destroy$)).subscribe(brandName => {
      this.branding = brandName;
      if (brandName === 'imperial' || brandName === 'rmit') {
        this.pageData.segment = 1;
      } else {
        this.pageData.segment = 0;
      }
    });

    // if this is a branded portal hide the org chooser
    this.pageData.hideOrgs = false;

    if(window.app_branding && window.app_branding.org_id) {
      this.pageData.hideOrgs = true;
    }

    //Load the data
    this.appapi.syncUniversities().then(() => {
      this.updateUniversitiesHandler();
    })

    this.tabChange()
  }

  /** 
  * Start walkthrough.
  */
  startWalkthrough() {
    if (!WalkthroughService.isWalkthroughComplete(this.pageLabel) && !WalkthroughService.allWalkthroughsDisabled()) {
      setTimeout(() => {
        this.copilot.checkInit();
      }, 1000);
    }
  }

  /** 
  * Re initialize and specify step.
  * @param stepNumber   stepNumber: string.
  */
  /** 
  * Re initialize and specify step.
  * @param stepNumber   stepNumber: string.
  */
  initPosition = (stepNumber: any) => this.copilot.checkInit(stepNumber);

  /** 
  * Next step.
  * @param stepNumber   stepNumber: string.
  */
  /** 
  * Next step.
  * @param stepNumber   stepNumber: string.
  */
  nextStep = (stepNumber: any) => this.copilot.next(stepNumber);

  /** 
   * Finish copilot walkthroughs.
   */
  done = () => this.copilot.removeWrapper();


  /** 
   * Set Walkthrough state. 
   * @param pageName   Name of page
   * @param value      Boolean - (Has been visited or not)
   */
  setWalkthroughStateHandler(pageName: string, value: boolean) {
    WalkthroughService.setWalkthroughState(pageName, value)
  }
  segmentChanged(ev: any) {
    this.pageData.segment = ev.index;
    this.updateUniversitiesHandler();
  }

  async updateUniversitiesHandler() {

    if (this.pageData.segment === 1) {
      this.pageData.universities = MyUtil.lodash.values(MyUtil.cache[MyUtil.DOC_ID.APP_UNIVERSITIES]);
      this.pageData.universities = MyUtil.lodash.filter(this.pageData.universities, function (el) {
        return el.sso && el.sso.length > 0;
      });
      this.pageData.universities = MyUtil.lodash.sortBy(this.pageData.universities, ['name']);
    } else {
      this.pageData.universities = await MyUtil.lodash.values(MyUtil.cache[MyUtil.DOC_ID.APP_UNIVERSITIES]);
      // Filter out organizations with single-sign-on - these cannot deal with sign-ups from our system
      // this.pageData.universities = MyUtil.lodash.filter(this.pageData.universities, function(org) {
      //   return !org.sso;
      // });
      this.pageData.universities = await MyUtil.lodash.sortBy(this.pageData.universities, ['name']);
    }

    // remove any orgs which are set to be hidden from signup
    this.pageData.universities = MyUtil.lodash.filter(this.pageData.universities, function (el) {
      return el.hide_from_login == 0;
    });

    return this.pageData.universities;
  }


  ionViewDidLoad() {
    MyUtil.firebaseSetScreenName('login');
  }

  // redirect to SSO page if SSO enabled on organisation
  checkSSO(org) {
    let uni = this.pageData.universities.find(u => u.id === org);
    if (uni.id === org && uni.sso !== null && uni.sso.length > 0) {
      this.pageData.signupForm.controls.oid.value = null;
      this.pageData.ssoForm.controls.oid.value = uni.id;
      // having to manually set the oid field to valid due to form changes
      this.pageData.ssoForm.controls['oid'].markAsTouched();
      this.pageData.ssoForm.controls['oid'].setErrors(null);
      this.pageData.segment = 1;
      this.updateUniversitiesHandler();
      return;
    }
  }

  /** 
  * Toggle password.
  */
  togglePassword(event) {
    event.preventDefault();
    this.showPassword = !this.showPassword;
  }

  /**
   * Handle back button
   */
  backAction(page) {

    switch (page) {

      case "org-list":
        this.resetViews();
        this.showWelcomePage = true;
      break;

      case "email-only":
        this.resetViews();
        this.showEmailPage = true;
      break;

    }

  }

  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  */
   process(action) {

    // check if app must be updated
    let upgradeMessage = null;
    this.appapi.syncApp();
    upgradeMessage = MyUtil.getUpgradeMessage();
    if (upgradeMessage !== null) {
      // show alert
      MyUtil.presentAlert({
        title: 'App Requires Update',
        message: upgradeMessage,
        buttons: [
          {
            text: 'Ok',
          }
        ]
      });

      return;
    }

    // validate input and prepare api call
    let data;
    switch (action) {
      case 'step1':
        this.pageData.signupFormSubmitAttempt = true;
        
        if (!this.pageData.signupForm.valid) {
          MyUtil.presentToast('Please review and revise your input', { cssClass: 'inkpath-toast' });
          return;
        }

        data = this.pageData.signupForm.value;
        let loading = MyUtil.presentLoading();
        this.appapi.post('/' + action, data).then(async result => {

          if (result['#status'] === 'success') {
            (await loading).dismiss();

            //Save the selected org as the "current org" in local storage
            MyUtil.saveToLocalStorage('localOid', data.oid);
            
            switch (result['#message']) {
              case 'sso':
              // sso only, so redirect
              data = this.pageData.signupForm.value;
              const organization = MyUtil.cache[MyUtil.DOC_ID.APP_UNIVERSITIES][data.oid];
              this.ssoLogin.initializeSsoLogin(organization);
              return;
              break;

              case "email-sso":
              // email and sso accepted, so ask for email to determine domain rules
              this.resetViews();
              this.showEmailPage = true;
              let domains = Object.keys(result['#domains']).map(key => ({type: key, value: result['#domains'][key]}));
              this.pageData.chosenOrg = MyUtil.cache[MyUtil.DOC_ID.APP_UNIVERSITIES][data.oid];
              this.pageData.domains = domains;
              break;

              case "email":
              // email only, ask for email and password
              this.resetViews();
              this.showEmailPasswordPage = true;
              break;
            }

          } else {
            (await loading).dismiss();
            MyUtil.presentAlert({
              title: 'Error',
              message: MyUtil.responseToMessage(result['#message'], result['#errors']),
              buttons: [
                {
                  text: 'OK',
                }
              ]
            });
          }

        });
        break;

      case 'email':
        // check the domain settings for the supplied email
        if (!this.pageData.emailForm.controls.email.valid) {
          MyUtil.presentToast('Please check your email', { cssClass: 'inkpath-toast' });
          return;
        }

        let emailloading = MyUtil.presentLoading();
        data = Object.assign({}, this.pageData.emailForm.value, this.pageData.signupForm.value);

        this.appapi.post('/step-email', data).then(async result => {
          (await emailloading).dismiss();
          if (result['#status'] === 'success') {
            switch (result['#message']) {
              case 'sso':
              // sso only, so redirect
              data = this.pageData.signupForm.value;
              const organization = MyUtil.cache[MyUtil.DOC_ID.APP_UNIVERSITIES][data.oid];
              this.ssoLogin.initializeSsoLogin(organization);
              return;
              break;

              case "show-password":
              // email and sso accepted, so ask for email to determine domain rules
              this.resetViews();
              this.showPasswordPage = true;
              break;

              case "registration":
                this.resetViews();
                this.showRegisterPage = true;
              break;
            }
          } else {
            MyUtil.presentAlert({
              title: 'Error',
              message: result['#message'],
              buttons: [
                {
                  text: 'OK',
                }
              ]
            });
          }
          
        });
        break;
      
      case 'email-password':
        let email = this.pageData.emailForm.controls.email.value;
        let password = this.pageData.passwordForm.controls.password.value;
        let loginData = {
          oid: this.pageData.signupForm.value.oid,
          email: email,
          password: password
        }
        
        MyUtil.firebaseLogEvent('login', { 'email': loginData.email });
        let passwordLoading = MyUtil.presentLoading();
        this.appapi.post('/login', loginData).then(async result => {
          (await passwordLoading).dismiss();
          if (result['#status'] === 'success') {
            this.auth.loginUser(result['#data']);
          } else {
            MyUtil.presentAlert({
              title: 'Error',
              message: MyUtil.responseToMessage(result['#message'], result['#errors']),
              buttons: [
                {
                  text: 'OK',
                }
              ]
            });
          }
        });
        break;

      case "sign-up":
        if (!this.pageData.registerForm.valid) {
          MyUtil.presentToast('Please review and revise your input', { cssClass: 'inkpath-toast' });
          return;
        }

        let registerloading = MyUtil.presentLoading();

        // need to pass email, oid, first name and last name
        let registerData = {
          oid: this.pageData.signupForm.value.oid,
          email: this.pageData.emailForm.controls.email.value,
          firstName: this.pageData.registerForm.controls.firstName.value,
          lastName: this.pageData.registerForm.controls.lastName.value
        };

        this.appapi.post('/' + action, registerData).then(async result => {

          MyUtil.firebaseLogEvent('sign-up_success', { 'email': registerData.email });
          (await registerloading).dismiss();

          //Set the current localOid to the one just signed up to
          if(result['#data'] && result['#data']['localOid']) {
            MyUtil.saveToLocalStorage('localOid', result['#data']['localOid']);
          }

          MyUtil.presentAlert({
            message: result['#message'],
            cssClass: 'alertSignup',
            buttons: [
              {
                text: 'Close',
                handler: () => {
                  this.pageData.emailForm.reset();
                  this.pageData.registerForm.reset();
                  this.resetViews();
                  this.showWelcomePage = true;
                  return;
                }
              }
            ],
          });

        });
      break;

      case 'reset-password':
        if (!this.pageData.emailForm.controls.email.valid) {
          MyUtil.presentToast('Please enter your sign up email', { cssClass: 'inkpath-toast' });
          return;
        }
        let resetPasswordLoading = MyUtil.presentLoading();
        let resetData = {
          email: this.pageData.emailForm.controls.email.value
        }
        
        this.appapi.post('/' + action, resetData).then(async result => {
          (await resetPasswordLoading).dismiss();
            MyUtil.firebaseLogEvent('reset-password', { 'email': resetData.email });
            MyUtil.presentToast(result['#message'], { cssClass: 'inkpath-toast', duration: MyUtil.CONST.DURATION_TOAST_LONG });
        });
        break;

      case 'show-terms-of-use':
        this.appapi.openAppLawBrowser('terms_and_conditions');
        return;
      case 'show-privacy-policy':
        this.appapi.openAppLawBrowser('privacy_policy');
        return;
      
      default:
        MyUtil.presentToast('"' + action + '" is not handled', { cssClass: 'inkpath-toast' });
        return;
    }
    
  }

  async accessibilityHandler() {
    let accessibilityModal = MyUtil.createModal(AccessibilityModalComponent, {});
    (await accessibilityModal).onDidDismiss().then((data: any) => { });
    (await accessibilityModal).present();
  }


  tabChange(){
    setTimeout(()=>{
      const invalidControl = this.el.nativeElement.querySelector('.input-focus');
      invalidControl.focus();
    }, 200)
  }

  resetViews() {
    this.showEmailPage = false;
    this.showPasswordPage = false;
    this.showEmailPasswordPage = false;
    this.showWelcomePage = false;
    this.showRegisterPage = false;
  }

  formatDomainsMessage(domains) {

    let article = "a ";
    let count = 0;
    let domainsCount = domains.length;
    this.pageData.domainsCount = domainsCount;
    let returnString = "If you have ";
    let domainsString = "";
    
    if(domainsCount < 1 || domainsCount > 5) {
      return "Enter your email address below.";
    }

    domains.forEach(domain => {
      if(count == 0) {

        // if first domain starts with a vowel, set to 'an', otherwise 'a'
        var vowelRegex = '^[aieouAIEOU].*';
        var matched = domain.value.name.match(vowelRegex);

        if(matched) {
          article = "an ";
        }

      }

      // add an 'or' after each domain, if multiple; except the last one
      if(count == domainsCount -1) {
        domainsString = domainsString + domain.value.name;
      } else {
        domainsString = domainsString + domain.value.name + " or ";
      }

      count++;
    });

    returnString = returnString + article + domainsString + " email address, please enter it below.";

    return returnString;


  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /** 
  * Set display mode.
  * @param mode   mode: string.
  */
  setDisplayMode(mode: string) {
    this.store.dispatch(new appStore.DisplayModeSet(mode));
    MyUtil.saveToLocalStorage('displayMode', mode);
  }
}
