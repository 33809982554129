import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NavController, NavParams } from '@ionic/angular';
import { MyUtil } from 'src/libs/MyUtil';
import { Appapi } from "../../providers/appapi";
import { DesktopChecker } from "../../providers/desktopChecker";
import { ReflectionService } from '../../providers/onboarding/reflection-service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'page-reflection-details',
  templateUrl: 'reflection-details.html',
})
export class ReflectionDetailsPage implements OnInit, OnDestroy {

  // Component variables.
  @ViewChild('Content', { static: true }) content;
  pageData: any = {};
  loading: any;
  routeData: any = {};

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public reflectionService: ReflectionService,
    public navCtrl: NavController,
    public navParams: NavParams,
    private route: ActivatedRoute,
    public appapi: Appapi,
    public desktopChecker: DesktopChecker,
    private titleService:Title) {
      this.titleService.setTitle("Reflection Details");
    }

  ngOnInit(): void {
    // expand help for first time landing on the page
    if (MyUtil.context.helpStatus[MyUtil.HELP_ID.REFLECTION_DETAILS]) {
      this.pageData.helperToggle = false;
    } else {
      this.pageData.helperToggle = true;
      this.appapi.setAppHelpStatus(MyUtil.HELP_ID.REFLECTION_DETAILS, true);
    }
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData = JSON.parse(params.pageData);
    });

    let profile = MyUtil.getProfile();
    let subID = this.routeData.id;

    this.getSubmissionData(subID, profile.id).then(resp => {
      if (resp && resp.created_at) {
        this.pageData.submission = resp;
        let dte = MyUtil.getUnixTimeStampFromString(this.pageData.submission.created_at, 'YYYY-MM-DD HH:mm');
        this.pageData.submission.created_at = MyUtil.formatUnixTimeStampDate(dte);
      }
    });
  }

  /** 
  * Get submission data.
  * @param id 
  * @param profile_id  
  */
  async getSubmissionData(id, profile_id) {
    return await this.reflectionService.getOnboardingReflectionData(id, profile_id);
  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
