import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MyUtil } from 'src/libs/MyUtil';
import { Appapi } from '../providers/appapi';
import { Store } from '@ngrx/store';
import * as appStore from '../store';
import { AuthProvider } from '../providers/auth/auth';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
})
export class SetPasswordComponent implements OnInit, OnDestroy {

  showPassword: boolean = false;
  passwordFormSubmitAttempt = false;
  setPasswordForm: any = undefined;
  errorMessage = '';
  pageTitle = "Set ";
  messageSection = " set. You may now log in.";
  linkExpired = false;
  routeData: any = {
    token: '',
    email: '',
  }
  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    public formBuilder: UntypedFormBuilder,
    public router: Router,
    public appapi: Appapi,
    private store: Store<appStore.AppViewState>,
    public auth: AuthProvider,
    private titleService:Title) 
  { }



  ngOnInit() {

    if (this.appapi.isLoggedIn()) {
      this.appapi.forgetAppUser(true);
    }

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData = JSON.parse(params.pageData);
      if(this.routeData.name === "ResetPasswordComponent" ){
        this.pageTitle = 'Reset ';
        this.messageSection = " reset. You may now log in.";
      }

      this.titleService.setTitle(this.pageTitle + " Password");
    });

    // define login form validation
    this.setPasswordForm = this.formBuilder.group({
      passwords: this.formBuilder.group({
        newPassword: ['', Validators.compose([Validators.maxLength(MyUtil.CONST.INPUT_MAX_LENGTH), Validators.required])],
        newPassword_confirmation: ['', Validators.compose([Validators.maxLength(MyUtil.CONST.INPUT_MAX_LENGTH), Validators.required])]
      }, {
        validator: (group: UntypedFormGroup) => {
          return (group.get('newPassword').value === group.get('newPassword_confirmation').value ? null : { 'mismatch': true });
        }
      })
    });
  }

  savePassword() {

    this.passwordFormSubmitAttempt = true;
    this.errorMessage = '';

    if (!this.setPasswordForm.valid) {
      this.errorMessage = 'Please revise input';
      if (this.setPasswordForm.value.passwords.newPassword !== this.setPasswordForm.value.passwords.newPassword_confirmation) {
        this.errorMessage = "Passwords do not match. ";
      }
      return;
    } else {
      let loading = MyUtil.presentLoading();
      let data = {
        email: this.routeData.params.email,
        token: this.routeData.params.token,
        password: this.setPasswordForm.value.passwords.newPassword,
        password_confirmation: this.setPasswordForm.value.passwords.newPassword_confirmation
      }
      
      this.appapi.post(this.routeData.params.reset_url, data, '', false).then(async result => {
        (await loading).dismiss();
        if (result['#status'] === 'success') {
            // remove local storage redirect
            MyUtil.removeFromLocalStorage("REDIRECT_TARGET");
            
            // remove redirect from ngrx store otherwise it re-appears when logging back in
            this.store.dispatch(new appStore.RedirectSet(null));

            //Don't log the user in at this point - safer go back to the login page and force user to follow login routes/rules
            this.router.navigate(['/LoginPage']);
            MyUtil.presentToast("Password has been " + this.messageSection, { cssClass: 'inkpath-toast', duration: MyUtil.CONST.DURATION_TOAST_LONG });
        } else {
          if(result['#message']?.password){
            this.errorMessage += "The password does not meet these requirements: "
            result['#message']?.password.forEach((v,k) => {
              this.errorMessage += v + ' ';
            });
          }
          
          if(result['#message']?.password_confirmation){
            result['#message']?.password_confirmation.forEach((v,k) => {
              this.errorMessage += v + ' ';
            });
          }
          
          if(result['#message']?.token){
            result['#message']?.token.forEach((v,k) => {
              this.errorMessage += v + ' ';
            });
          }

          if(this.errorMessage.indexOf('expired') !== -1) {
            this.linkExpired = true;
          }
        }
      }).catch(async err => {
        (await loading).dismiss();
        MyUtil.error(err);
      });
    }
  }


  resendLink() {

    let loading = MyUtil.presentLoading();
    let data = {
      email: this.routeData.params.email
    }

    this.appapi.post('/reset-password', data).then(async result => {
      (await loading).dismiss();
        MyUtil.firebaseLogEvent('reset-password', { 'email': data.email });
        MyUtil.presentToast(result['#message'], { cssClass: 'inkpath-toast', duration: MyUtil.CONST.DURATION_TOAST_LONG });
    });

  }


  /** 
  * Toggle password.
  */
  togglePassword(event) {
    event.preventDefault();
    this.showPassword = !this.showPassword;
  }
 

  // On destroy
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
