import { Component } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { Router } from '@angular/router';
@Component({
  selector: "reflection-complete-modal",
  templateUrl: "reflection-complete-modal.html",
  styleUrls: ["reflection-complete-modal.scss"],
})
export class ReflectionCompleteModal {

  // Component variables.
  public data: any;
  public label: any = 0;

  constructor(
    public router: Router,
    public params: NavParams,
    public viewCtrl: ModalController
  ) {
    if (params) {
      this.data = params.data;
    }
  }

  // Handle action.
  handleAction() {
    this.viewCtrl.dismiss('visitDiagnosticPage');
  }

  // Close Modal
  closeModal() {
    this.viewCtrl.dismiss();
    this.router.navigate(['/home']);
  }
}
