import { Directive, ElementRef, OnInit } from "@angular/core";

@Directive({
    selector: "[ModFocus]"
})

export class ModalFocus implements OnInit {

    constructor(private _el: ElementRef) {

    }

    ngOnInit(): any {
        // At this point, your element should be available.
        setTimeout(()=>{
            this._el.nativeElement.focus();
            console.log('focused')
        }, 500)
        
    }
}