import { Directive, ElementRef, Input } from '@angular/core';

import * as D3 from 'd3';
import { MyUtil } from 'src/libs/MyUtil';

@Directive({
  selector: '[pie-chart-hour]'
})
export class PieChartHour {
  @Input('pie-chart-hour') hours: any;
  @Input('pie-chart-color') color: any;
  @Input('pie-chart-width') width: any;
  @Input('pie-chart-animate') animate: number;
  canvas: any;

  constructor(public element: ElementRef) {
  }

  ngOnChanges(changes) {
    // render use cached data
    this.render();
  }

  render() {
    // prepare data
    let hours = (this.hours && this.hours > 0 ? this.hours : 0);
    let color = (this.color && hours > 0 ? this.color : '#555462');
    let data = [1, 0]; // always 100%

    let homeOrg = MyUtil.getRootOrganization();
    let hoursLabel = 'hours';
    if(homeOrg.override_activity_hours === 1) {
      hoursLabel = '';
    }

    // render chart
    let htmlElement = this.element.nativeElement,
      width = (this.width && this.width > 0 ? this.width : 60),
      height = width, //htmlElement.clientHeight,
      radius = width / 2,
      thick = radius;      ;

    if (!this.canvas) {
      this.canvas = D3.select(htmlElement)
        .append('svg')
    }
    this.canvas
      .attr('width', width)
      .attr('height', height);

    this.canvas.selectAll('g.pie-chart').remove();

    let group = this.canvas.append("g")
      .attr('transform', 'translate(' + radius + ', ' + radius + ')')
      .attr('class', 'pie-chart');

    let arc = D3.arc()
      .innerRadius(radius - thick)
      .outerRadius(radius);

    let pie = D3.pie().sortValues(null);

    let arcs = group.selectAll('.arc')
      .data(pie(data))
      .enter()
      .append('g')
      .attr('class', 'arc');

    arcs.append('path')
      .attr('d', arc)
      .attr('fill', (d) => {
        let color = '#363542';
        return color;
      });

    arcs.append('path')
      .attr('d', arc)
      .attr('fill', color)
      .transition()
      .delay((d, i) => {
        return i * 100;
      })
      .duration( this.animate && hours > 0 ? this.animate : 0)
      .attrTween('d', (d) => {
        let i = D3.interpolate(d.startAngle, d.endAngle);
        if (d.index < 1) {
          return (t) => {
            d.endAngle = i(t);
            return arc(d);
          };
        }

      });

    let strlen = hours.toString().length;
    let fontsize = width / 3;
    let idx = (strlen > 6 ? 6 : strlen);
    let xshift =  - (radius-thick)+fontsize*[1.3,1.1,0.85,0.66,0.4,0.32,0.16][idx];
    group.append('text')
      .attr('class', 'hour')
      .attr('fill', '#f4f4f4')
      .attr('font-size', fontsize)
      .attr('text-anchor', 'middle')
      .attr('x', 0 )
      .attr('y', 18)
      .text(hours);

    group.append('text')
      .attr('class', 'unit')
      .attr('fill', '#f4f4f4')
      .attr('font-size', fontsize * 0.4)
      .attr('text-anchor', 'middle')
      .attr('x',  0)
      .attr('y', 45)
      .text(homeOrg.override_activity_hours === 1 ? '' : (hours>1 ? 'HOURS' : 'HOUR'));

  }
}
