import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-general-modal',
  templateUrl: './general-modal.component.html',
  styleUrls: ['./general-modal.component.scss'],
})
export class GeneralModalComponent {

  // Component variables.
  public data: any;
  public label: any = 0;

  constructor(public params: NavParams, public viewCtrl: ModalController) {
    if (params) {
      this.data = params.data;
    }
  }

  // Handle action.
  handleAction() {
    this.viewCtrl.dismiss(this.label);
  }

  // Close Modal.
  closeModal() {
    this.viewCtrl.dismiss();
  }
}