import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { Appapi } from 'src/app/providers/appapi';
import { DesktopChecker } from 'src/app/providers/desktopChecker';
import { MyUtil } from "../../../libs/MyUtil";


/**
 * Generated class for the PathwayManualPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

// @IonicPage()
@Component({
  selector: 'page-pathway-manual',
  templateUrl: 'pathway-manual.html',
})
export class PathwayManualPage implements OnInit{

  careerPathTypes: Array<any>;

  constructor(public router: Router, public navParams: NavParams, public appapi: Appapi, public desktopChecker: DesktopChecker) {

  }

  ngOnInit() {
      let loading = MyUtil.presentLoading();
      this.appapi.getCareerPathsByType()
          .then(
              async res => {
                  this.careerPathTypes = res['#data']['types'];
                  (await loading).dismiss();
              }
          )
          .catch(async err => {
              (await loading).dismiss();
          })
  }

  goToType(type) {
      this.router.navigate(['/PathwayManualCareerTypePage', JSON.stringify({type: type})])
  }

}
