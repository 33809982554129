import { Directive, ElementRef, Input, OnInit } from '@angular/core';

import { MyUtil } from '../../../libs/MyUtil';

declare var google: any;

@Directive({
  selector: '[googleMap]' // Attribute selector
})
export class Map implements OnInit{
  @Input() venue: string;
  @Input() lat: number;
  @Input() long: number;
  map: any;
  pageData: any = {};

  constructor(public element: ElementRef) {
    this.pageData.mapElement = element.nativeElement;
  }

  ngOnInit() {
      this.loadSDKMap();
  }

  loadSDKMap() {
    // To be replaced by a geo coder service [short term] and in actual geolocation data from back end [long term].
    let latLng = new google.maps.LatLng(this.lat, this.long);

    let mapOptions = {
      center: latLng,
      zoom: 16,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: [
            {
              elementType: 'geometry',
              stylers: [{color: '#131222'}]
            },
            {
              elementType: 'labels.text.stroke',
              stylers: [{color: '#242f3e'}]
            },
            {
              elementType: 'labels.text.fill',
              stylers: [{color: '#746855'}]
            },
            {
              featureType: 'administrative.locality',
              elementType: 'labels.text.fill',
              stylers: [{color: '#d59563'}]
            },
            {
              featureType: 'poi',
              elementType: 'labels.text.fill',
              stylers: [{color: '#d59563'}]
            },
            {
              featureType: 'poi.park',
              elementType: 'geometry',
              stylers: [{color: '#263c3f'}]
            },
            {
              featureType: 'poi.park',
              elementType: 'labels.text.fill',
              stylers: [{color: '#6b9a76'}]
            },
            {
              featureType: 'road',
              elementType: 'geometry',
              stylers: [{color: '#38414e'}]
            },
            {
              featureType: 'road',
              elementType: 'geometry.stroke',
              stylers: [{color: '#212a37'}]
            },
            {
              featureType: 'road',
              elementType: 'labels.text.fill',
              stylers: [{color: '#9ca5b3'}]
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry',
              stylers: [{color: '#746855'}]
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry.stroke',
              stylers: [{color: '#1f2835'}]
            },
            {
              featureType: 'road.highway',
              elementType: 'labels.text.fill',
              stylers: [{color: '#f3d19c'}]
            },
          ]
    }

    this.map = new google.maps.Map(this.pageData.mapElement, mapOptions);

    // calculate the real uri for the icon, on device use file plugin, in build web assets fold same level of index.html
    let iconPath = MyUtil.context.API_SERVER_URL + '/assets/images/marker.png';
    let marker = new google.maps.Marker({
      map: this.map,
      icon: iconPath,
      animation: google.maps.Animation.DROP,
      position: this.map.getCenter()
    });

    let content = "<h4 class='marker'>"+this.venue+"</h4>";

    let infoWindow = new google.maps.InfoWindow({
      content: content
    });

    google.maps.event.addListener(marker, 'click', () => {
      infoWindow.open(this.map, marker);
    });

  }

}
