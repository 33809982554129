import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DesktopChecker } from 'src/app/providers/desktopChecker';

/**
 * Generated class for the CareerPathSkillTileDetailsPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

// @IonicPage()
@Component({
  selector: 'page-career-path-skill-tile-details',
  templateUrl: 'career-path-skill-tile-details.html',
})
export class CareerPathSkillTileDetailsPage implements OnInit{

  skill;
  showLinkToDetails: boolean;
  routeData:any = {};
  
  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(public router: Router, private route: ActivatedRoute, public navParams: NavParams, public desktopChecker: DesktopChecker) {

  }

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData =JSON.parse(params.pageData);
    });
    this.skill = this.routeData.skill;
    this.showLinkToDetails = this.routeData.showLinkToDetails;
  }

  goToSkillProfile() {
    this.router.navigate(['/SkillDetailsPage', JSON.stringify({id: this.skill.id})]);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
