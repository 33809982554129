import { Component } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";

@Component({
  selector: 'goal-reflection-modal',
  templateUrl: 'goal-reflection-modal.html'
})

export class GoalReflectionModalComponent {

  // component variables.
  public data: any;
  public reflection: any;

  constructor(public params: NavParams, public viewCtrl: ModalController) {
    if (params) {
      this.data = params.data;
    }
  }

  // Handle action.
  handleAction() {
    this.viewCtrl.dismiss(this.reflection);
  }

  // Close modal.
  closeModal() {
    this.viewCtrl.dismiss('cancel');
  }
}
