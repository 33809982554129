import { Injectable } from '@angular/core';
import { InAppBrowser, InAppBrowserEvent } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { MyUtil } from 'src/libs/MyUtil';
import { Store, State } from '@ngrx/store';
import * as appStore from '../../store';
import { Router } from '@angular/router';
import { AuthProvider } from '../auth/auth';
import { Observable, Subject } from 'rxjs';
import { getSSO } from 'src/app/store/selectors/view.selector';
import { takeUntil } from 'rxjs/operators';

/*
  Generated class for the SsoLoginProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: 'root',
})
export class SsoLoginProvider {
  sso$: Observable<string> = this.store.select(getSSO);
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public iab: InAppBrowser,
    public router: Router,
    public auth: AuthProvider,
    private store: Store<appStore.AppViewState>, private state: State<any>,
  ) {

  }


  initializeSsoLogin(organization) {

    //Initialise sso to null to prevent login before redirect
    this.store.dispatch(new appStore.SSOSet(null));

    //Listen for change in sso
    if (!MyUtil.context.isDevice) {
      this.sso$.pipe(takeUntil(this.destroy$)).subscribe(userData => {
        if(userData !== null) {
          this.auth.loginUser(userData);
        }
      })
    }

    this.showLogin(organization.sso);
  }


  showLogin(ssoId: string) {
    
    let loginUrl = MyUtil.context.API_SERVER_URL + '/' + ssoId + '/login';

    if (MyUtil.context.isDevice) {
      MyUtil.debug('device mode sso login');

      let startTime = MyUtil.getMoment().unix();
      const options = 'location=yes,zoom=no,hideurlbar=yes,hidenavigationbuttons=yes';
      const loginWindow = this.iab.create(loginUrl, '_blank', options);
     
      loginWindow.on('exit').subscribe((event: InAppBrowserEvent) => {
        MyUtil.debug('in app browser exit');
        MyUtil.debug(event);
      });

      loginWindow.on('loadstop').subscribe((event: InAppBrowserEvent) => {

        MyUtil.debug('in app browser loadstop');
        MyUtil.debug(event);

        let url = event.url;

        if (url.indexOf('sso-login-handler') !== -1) {

          const userDataParams = new URLSearchParams(event.url.substring(event.url.indexOf("?")));
          const userData = {
            api_token: userDataParams.get('api_token'),
            email: userDataParams.get('email'),
            id: userDataParams.get('id'),
            name: userDataParams.get('name'),
            oid: userDataParams.get('oid'),
            status: userDataParams.get('status')
          };
          
          this.store.dispatch(new appStore.SSOSet(userData));
          this.auth.loginUser(userData);


          // When we get the access token fast, the login window (inappbrowser) is still opening with animation
          // in the Cordova app, and trying to close it while it's animating generates an exception. Wait a little...
          let timeout = 300 - (MyUtil.getMoment().unix() - startTime);
          setTimeout(function () {

            loginWindow.close();

          }, timeout > 0 ? timeout : 0);
        }
      });

    } else {

      MyUtil.debug('web mode sso login');

      window.onmessage = (event) => {
        let origin = event.origin;
        MyUtil.debug(origin);

        // only handle the event from the SSO login window
        if (event.data.api_token) {
          this.store.dispatch(new appStore.SSOSet(event.data));
        }
      };
      
      window.open(loginUrl, '_blank');
    }
  }
}
