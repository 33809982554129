import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DesktopChecker } from 'src/app/providers/desktopChecker';

/**
 * Generated class for the ChooseAPathwayPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

// @IonicPage()
@Component({
  selector: 'page-choose-a-pathway',
  templateUrl: 'choose-a-pathway.html',
})
export class ChooseAPathwayPage {

  constructor(public router: Router, public desktopChecker: DesktopChecker) {
  }

  ionViewDidLoad() {
  }

  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  */
  process(action: string) {
    switch(action) {
      case 'pathway-skills-based':
        this.router.navigate(['/PathwaySuggestedPage']);
        break;
      case 'pathway-manual':
        this.router.navigate(['/PathwayManualPage']);
        break;
      case 'skip':
        this.router.navigate(['/ActivitiesPage']);
        break;
      default:
        return;
    }
  }

}
