import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getBrandName, getDisplayMode } from 'src/app/store/selectors/view.selector';
import * as appStore from '../../store';

@Component({
  selector: 'app-login-branding-logo',
  templateUrl: './login-branding-logo.component.html',
  styleUrls: ['./login-branding-logo.component.scss'],
})
export class LoginBrandingLogoComponent implements OnInit {

  // Get brand name from store.
  brandName$: Observable<string> = this.store.select(getBrandName);
  displayMode$: Observable<string> = this.store.select(getDisplayMode);

  constructor(
    private store: Store<appStore.AppViewState>,
  ) { }

  ngOnInit() {}

}
