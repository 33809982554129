import { Component } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";

@Component({
  selector: "cancel-booking-modal",
  templateUrl: "cancel-booking-modal.html",
})
export class CancelBookingModal {

  // Component variables.
  public data: any;
  public label: any = 0;

  constructor(public params: NavParams, public viewCtrl: ModalController) {
    if (params) {
      this.data = params.data;
    }
  }

  // Handle action
  handleAction() {
    this.viewCtrl.dismiss(this.label);
  }

  // Close modal
  closeModal() {
    this.viewCtrl.dismiss();
  }
}
