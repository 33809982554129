import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActivityListItemVO } from 'src/app/valueObjects/lists.vo';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {

  constructor(
  ) { }

  @Input() list$: Observable<ActivityListItemVO[]>;

  /** 
  * The form group the search button is linked to.
  */
  @Input() searchForm: UntypedFormGroup;

  ngOnInit() {}
}
