import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GoalReflectionModalComponent } from 'src/app/components/goal-reflection-modal/goal-reflection-modal';
import { Appapi } from 'src/app/providers/appapi';
import { Appcalc } from 'src/app/providers/appcalc';
import { DesktopChecker } from 'src/app/providers/desktopChecker';
import { MyDb } from '../../../libs/MyDb';
import { MyUtil } from '../../../libs/MyUtil';
import { Title } from '@angular/platform-browser';
import { ActivityService } from 'src/app/providers/activity-service';

@Component({
  selector: 'page-goal-detail',
  templateUrl: 'goal-detail.html',
  styleUrls: ['goal-detail.scss']
})
export class GoalDetailPage implements OnInit, OnDestroy{

  // Component variables.
  @ViewChild('Content', { static: true }) content;
  pageData: any = {};
  id: any;
  public tabIndex = 0;

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public router: Router,
    public navParams: NavParams,
    public appapi: Appapi,
    private route: ActivatedRoute,
    public appcalc: Appcalc,
    public desktopChecker: DesktopChecker,
    public activityService: ActivityService,
    private titleService:Title) {
      this.titleService.setTitle("Goal Details"); }
  
  async ngOnInit() {

    this.pageData.loaded = false;

    if (!this.appapi.isLoggedIn()) {
      return;
    }

    let loading = MyUtil.presentLoading();

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.id = params.id;
    });

    this.pageData.homeOrg = MyUtil.getRootOrganization();
    this.pageData.orgDetails = MyUtil.getAppOrg(this.pageData.homeOrg.id);
    this.pageData.dateFormat = MyUtil.formatPHPDateForPipe(this.pageData.orgDetails[0].org_date_format);

    // expand help for first time landing on the page
    if (MyUtil.context.helpStatus[MyUtil.HELP_ID.GOAL_DETAIL]) {
      this.pageData.helperToggle = false;
    } else {
      this.pageData.helperToggle = true;
      this.appapi.setAppHelpStatus(MyUtil.HELP_ID.GOAL_DETAIL, true);
    }

    let id = this.id;
    this.pageData.goal = MyUtil.getGoal(id);
    
    this.pageData.goalDisplay = {};
    this.pageData.goalActivitiesDisplay = [];

    this.pageData.userGoalDoc = null;

    // check for id - also blocks users from accessing other data
    if( !this.pageData.goal || !('id' in this.pageData.goal) ) {
      this.router.navigate(['/GoalsPage']);
    }

    this.queryUserGoal(this.pageData.goal.id).then((doc) => {
      if (doc) {
        this.pageData.userGoalDoc = doc;
        this.refreshForm();
        this.tabIndex = 2;
      }
        
    });

    MyUtil.firebaseSetScreenName('goal-detail');

    MyUtil.firebaseLogEvent('view_did_enter', { name: 'goal-detail', data: this.navParams.data });

    this.refreshGoals();
    this.appcalc.refreshPerformancePerGoals().subscribe(async () => {
     
      this.renderGoal();
      (await loading).dismiss();
      this.pageData.loaded = true;
      
    });

  }

  ionViewDidLeave() {
    MyUtil.firebaseLogEvent('view_did_leave', { name: 'goal-detail', data: this.navParams.data });
  }

  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  * @param item     Parameter to process in action.
  */
  async process(action: string, item?: any) {
    switch (action) {
      case 'activity-detail':
        this.router.navigate(['/ActivityDetailPage', JSON.stringify({ id: item.id })]);
        return;
      case 'add-goal':
        this.addGoal();
        this.tabIndex = 2;
       
        return;
      case 'remove-goal':
        this.removeGoal();
        return;
      case 'complete-goal':
        let goalReflectionModal = MyUtil.createModal(GoalReflectionModalComponent, {
          title: "Add a reflection",
        });
        (await goalReflectionModal).onDidDismiss().then(ref => {
          if (ref.data == undefined) {
            MyUtil.presentAlert({
              title: 'Missing Reflection',
              message: 'Please enter your reflection.',
              buttons: [
                {
                  text: 'Dismiss',
                  handler: () => {
                    return;
                  }
                }
              ],
            });
            return;
          }

          if (ref.data === 'cancel') {
            return;
          }

          if (ref != null && ref.data !== 'cancel') {
            this.completeUserGoal(ref);
          }
        });
        (await goalReflectionModal).present();
        return;
      case 'un-complete-goal':
        MyUtil.presentAlert({
          title: 'Confirm un-completion of my goal',
          message: 'This goal has been un-marked as finished. Is this correct?',
          buttons: [
            {
              text: 'Cancel',
              handler: () => {
                return;
              }
            },
            {
              text: 'Confirm',
              handler: () => {
                this.unCompleteUserGoal();
              }
            }
          ],
        });
        return;
      case 'change-started-at':
      case 'change-completed-at':
        // trick to avoid ionChange fired twice, @TODO remove when ionic fix this
        let changedTimestamp = MyUtil.getUnixTimeStamp();
        if (this.pageData.changedTimestamp && this.pageData.changedTimestamp >= changedTimestamp - 1) {
          return;
        }
        this.pageData.changedTimestamp = changedTimestamp;

        this.updateUserGoal();
        return;
      case 'filter-activity-list-to-skill':
        this.filterActivitiesBySkill();
        return;
      case 'edit-user-generated-goal':
        this.editUserGeneratedGoal(item);
        return;
      case 'remove-user-generated-goal':
        this.removeUserGeneratedGoal();
        return;
      default:
        MyUtil.presentToast('"' + action + '" is not handled', { cssClass: 'inkpath-toast' });
        return;
    }
  }

  /** 
  * Query user goal.
  * @param id (any)
  */
  private queryUserGoal(id): any {
    let queryOptions: any = {
      key: [MyUtil.DOC_TYPE.USER_GOAL, id],
      include_docs: true
    };

    return MyDb.userQuery('by_type_goal_id', queryOptions).then(queryResult => {
      let docs = MyDb.flatQueryResult(queryResult);
      let resultDoc = null;

      if (docs && docs.length > 0) {
        resultDoc = docs[0];
      }

      return resultDoc;
    });
  }

  /** 
  * Render goal.
  */
  private renderGoal() {
    
    let orgDetails = MyUtil.getAppOrg(this.pageData.homeOrg.id);
    this.pageData.dateFormat = MyUtil.formatPHPDateForPipe(orgDetails[0].org_date_format);

    this.pageData.goalDisplay = {
      profile_id: this.pageData.goal.profile_id,
      id: this.pageData.goal.id,
      name: this.pageData.goal.name,
      description: this.pageData.goal.description,
      reflection: this.pageData.userGoalDoc?.data.reflection,
      oid: MyUtil.getOrgnizationChainName(this.pageData.goal.oid),
      priority: MyUtil.cache[MyUtil.DOC_ID.APP_META]['GOAL_PRIORITY'][this.pageData.goal.priority],
      calc_type: MyUtil.cache[MyUtil.DOC_ID.APP_META]['GOAL_CALC_TYPE'][this.pageData.goal.calc_type],
      time_effort: (this.pageData.goal.calc_type == MyUtil.CONST.APP_META.GOAL_CALC_TYPE_ATTEND_COUNT ? null : this.pageData.goal.time_effort / 60 + ' hour(s)'),
      skills: MyUtil.lodash.chain(this.pageData.goal.skills).map((id) => {
        return MyUtil.getSkill(id).name;
      }).value().join(','),
      attend_count: (this.pageData.goal.calc_type == MyUtil.CONST.APP_META.GOAL_CALC_TYPE_ATTEND_COUNT ? this.pageData.goal.attend_count : null),
      badge_details: this.pageData.goal.badge_details ? this.pageData.goal.badge_details : null,
      activities: MyUtil.lodash.chain(this.pageData.goal.activities).map((id) => {

        let data = MyUtil.getActivity(id);
        if (!data) {
          return []
        }

        data.period = this.activityService.getShortDisplayDateTime(orgDetails[0], 
          data.start_at, 
          data.start_time, 
          data.end_at, 
          data.end_time);

        return {
          id: data.id,
          name: data.name,
          provider: data.provider,
          period: data.period,
          venue: data.venue,
          activity_template_id: data.activity_template_id,
          start_at: data.start_at,
          start_time: data.start_time,
          badge_details: data.badge_details ? data.badge_details : null,
        };
      }).orderBy(['start_at', 'start_time'], ['asc', 'asc']).value()
    };
    
    if (this.pageData.userGoalDoc && this.pageData.userGoalDoc.data) {
      let chartInfo = this.appcalc.getGoalChartData(this.pageData.userGoalDoc.data.id);
      if (chartInfo) {
        this.pageData.goalDisplay.percent_complete = chartInfo['completeness'];
        this.pageData.goalActivitiesDisplay = MyUtil.lodash.chain(chartInfo['items']).filter((item) => {
          // avoid invalid activity id
          return MyUtil.getActivity(item.id);
        }).map((item) => {

          let data = MyUtil.getActivity(item.id);

          data.period = this.activityService.getShortDisplayDateTime(orgDetails[0], 
            data.start_at, 
            data.start_time, 
            data.end_at, 
            data.end_time);

          return {
            id: data.id,
            name: data.name,
            provider: data.provider,
            period: data.period,
            venue: data.venue,
            completed: item.completed,
            percent_complete: item.completed ? 1 : 0,
            time_logged: item.time_logged ? Math.round(10 * item.time_logged / 60) / 10 : '',
            activity_template_id: data.activity_template_id,
            start_at: data.start_at,
            start_time: data.start_time,
            badge_details: data.badge_details ? data.badge_details : null,
          };
        }).orderBy(['start_at', 'start_time'], ['asc', 'asc']).value();
      }
    }
    
  }

  /** 
  * Add goal.
  */
  private async addGoal() {

    if (!this.pageData.userGoalDoc) {
      this.pageData.userGoalDoc = {};
    } else {
      if (!this.pageData.userGoalDoc.delete) {
        MyUtil.presentToast('Goal already in personal list', { cssClass: 'inkpath-toast' });
        return;
      }
    }
    // remove delete flag if any
    delete (this.pageData.userGoalDoc.delete);

    // set type and data
    this.pageData.userGoalDoc.type = MyUtil.DOC_TYPE.USER_GOAL;
    this.pageData.userGoalDoc.data = {
      goal_id: this.pageData.goal.id,
      status: MyUtil.CONST.APP_META.USER_GOAL_STATUS_ACTIVE,
      started_at: MyUtil.getUnixTimeStamp()
    }

    let loading = MyUtil.presentLoading();
    this.pageData.loaded = false;

    await this.saveUserGoal('This goal has been added to your personal goal list', true).then(()=>{
      this.queryUserGoal(this.pageData.goal.id).then(async (doc) => {
        if (doc) {
          this.pageData.userGoalDoc = doc;
          this.refreshForm();
          this.tabIndex = 2;
        }

        (await loading).dismiss();
        this.pageData.loaded = true;
      });
    });

    
  }

  /** 
  * Remove Goal.
  */
  private removeGoal() {
    if (this.isValidUserGoal()) {
      // set delete flag
      this.pageData.userGoalDoc.delete = true;

      this.saveUserGoal('This goal has been removed from your personal goal list');
    }
  }

  /** 
  * Refresh form.
  */
  private refreshForm() {

    let orgDetails = MyUtil.getAppOrg(this.pageData.homeOrg.id);
    this.pageData.form = null;

    if (this.pageData.userGoalDoc && !this.pageData.userGoalDoc.delete && this.pageData.userGoalDoc.data) {
      let item = this.pageData.userGoalDoc.data;
      this.pageData.form = {
        started_at: (item.started_at ? MyUtil.formatUnixTimeStampDate(item.started_at, orgDetails[0].org_date_format) : null),
        completed_at: (item.completed_at ? MyUtil.formatUnixTimeStampDate(item.completed_at, orgDetails[0].org_date_format) : null),
      };
    }
  }

  /** 
  * Complete user goal.
  * @param reflection 
  */
  private completeUserGoal(reflection) {
    if (this.isValidUserGoal()) {
      // update values
      this.pageData.userGoalDoc.data.completed_at = MyUtil.getUnixTimeStamp(this.pageData.form.completed_at);
      this.pageData.userGoalDoc.data.status = MyUtil.CONST.APP_META.USER_GOAL_STATUS_DONE;
      this.pageData.userGoalDoc.data.reflection = reflection.data;
      this.saveUserGoal('Goal updated in personal list', false, reflection);
    }
  }

  /** 
  * Uncomplete user goal.
  */
  private unCompleteUserGoal() {
    if (this.isValidUserGoal()) {
      // update values
      this.pageData.userGoalDoc.data.completed_at = null;
      this.pageData.userGoalDoc.data.status = MyUtil.CONST.APP_META.USER_GOAL_STATUS_ACTIVE;
      this.saveUserGoal('Goal updated in personal list');
    }
  }

  /** 
  * Update user goal.
  */
  private updateUserGoal() {
    if (this.isValidUserGoal()) {
      // update values
      this.pageData.userGoalDoc.data.started_at = (this.pageData.form.started_at ? MyUtil.getUnixTimeStamp(this.pageData.form.started_at) : null);
      this.pageData.userGoalDoc.data.completed_at = (this.pageData.form.completed_at ? MyUtil.getUnixTimeStamp(this.pageData.form.completed_at) : null);
      this.saveUserGoal('Goal updated in personal list');
    }
  }

  // general validate before save function
  private isValidUserGoal() {
    if (this.pageData.userGoalDoc && !this.pageData.userGoalDoc.delete && this.pageData.userGoalDoc.data) {
      return true;
    } else {
      MyUtil.presentToast('Goal is not in personal list', { cssClass: 'inkpath-toast' });
      return false;
    }
  }

  // general save function
  private saveUserGoal(successMsg: any, updateProgress = false, reflection = null) {
    // remove ts if any for sync again
    delete (this.pageData.userGoalDoc.ts);

    if (reflection) {
      this.pageData.segment = 'details';
    }

    return MyDb.userSave(this.pageData.userGoalDoc).then((doc) => {
      this.pageData.userGoalDoc = doc;

      // attempt server update
      return this.appapi.saveUserActivities().then(() => {
        return this.appapi.saveUserGoals().then(() => {
          MyUtil.presentToast(successMsg, { cssClass: 'inkpath-toast' });

          // refresh to show progress segment
          if (updateProgress) {
            this.appcalc.refreshPerformancePerGoals().subscribe(() => {
              this.renderGoal();
            });
          }
          this.refreshForm();
        });
      });
    }).catch(() => {
      this.pageData.userGoalDoc = null;
    });
  }

  /** 
  * Filter activities by skill.
  */
  private filterActivitiesBySkill() {
    this.router.navigate(['/ActivityListPage',  JSON.stringify({
      start_date: this.pageData.userGoalDoc.data.started_at,
      skills: this.pageData.goal.skills,
    })]);
  }

  /** 
  * Edit user generated Goal.
  * @param id
  */
  private editUserGeneratedGoal(id) {
    this.router.navigate(['/GoalEditPage', JSON.stringify({ id: id })]);
  }

  /** 
  * Remove user generated goal.
  */
  private removeUserGeneratedGoal() {
    MyUtil.presentAlert({
      title: 'Confirm removal of goal',
      message: 'This goal will be completely removed. Are you sure?',
      buttons: [
        {
          text: 'Cancel',
          handler: () => {
            return;
          }
        },
        {
          text: 'Confirm',
          handler: () => {
            this.deleteUserGeneratedGoal();
          }
        }
      ],
    });
  }

  /** 
  * Delete user generated goal.
  */
  private deleteUserGeneratedGoal(): void {
    let goal = this.pageData.goal;
    if (!goal || !goal.id) {
      return;
    }

    // remove goal locally
    return MyDb.userLoad(MyUtil.DOC_ID.GOALS).then((goalsDoc: any) => {
      delete goalsDoc.data[goal.id]
      return MyDb.userSave(goalsDoc).then((goalsDoc: any) => {
        // update cache
        MyUtil.cache[goalsDoc._id] = goalsDoc.data;

        if (!this.pageData.userGoalDoc) {
          this.pageData.userGoalDoc = {};
          // init type and data
          this.pageData.userGoalDoc.type = MyUtil.DOC_TYPE.USER_GOAL;
          this.pageData.userGoalDoc.data = {
            goal_id: this.pageData.goal.id,
            status: MyUtil.CONST.APP_META.USER_GOAL_STATUS_ACTIVE
          }
        }

        // set delete flag
        this.pageData.userGoalDoc.delete = true;

        // remove ts if any for sync again
        delete (this.pageData.userGoalDoc.ts);

        // cache for data sync
        this.pageData.userGoalDoc.data.updated_goal = {
          id: goal.id,
          delete: true
        };

        return MyDb.userSave(this.pageData.userGoalDoc).then((doc) => {
          this.pageData.userGoalDoc = doc;
          // this.navCtrl.pop();
          // try to update server
          return this.appapi.saveUserActivities().then((result1) => {
            if(!!result1) {
              return this.appapi.saveUserGoals().then((result2) => {
                if(!!result1) {
                  MyUtil.presentToast('This goal has been removed from your personal goal list', { cssClass: 'inkpath-toast' });
                }
              });
            }
          });
        }).catch((err) => {
          MyUtil.error(err);
        });
      });
    });
  }

  /** 
  * Refresh goals.
  */
  refreshGoals() {
    this.appapi.syncAllGoals().then(() => {
      this.appapi.syncAllActivities();
    });
  }
  
  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
