import { Injectable } from "@angular/core";
import { MyDb } from "src/libs/MyDb";
import { MyUtil } from "src/libs/MyUtil";
import { Appapi } from "./appapi";

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  constructor(public appapi: Appapi) {}

  public queryUserActivity(id: string): Promise<any> {
    let queryOptions: any = {
      key: [MyUtil.DOC_TYPE.USER_ACTIVITY, id],
      include_docs: true,
    };

    return MyDb.userQuery("by_type_activity_id", queryOptions).then(
      (queryResult: any) => {
        let docs = MyDb.flatQueryResult(queryResult);
        let resultDoc = null;

        if (docs && docs.length > 0) {
          resultDoc = docs[0];
        }

        return resultDoc;
      }
    );
  }

  public getUserActivity(id: string): Promise<any> {
    let data = [];
    return this.appapi
      .post("/sync/user-activity/" + id, {})
      .then((result) => {
        if (result["#status"] === "success") {
          data = result["#data"]['data'];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
        return data;
      });
  }

  public addToUserActivity(id: string, bookingType: any = null, completing: boolean = false): Promise<any> {
    return this.queryUserActivity(id).then((doc) => {
      let userActivityDoc = doc;
      if (!userActivityDoc) {
        userActivityDoc = {};
      } else {
        if(userActivityDoc.data.completed_at && completing) {
          MyUtil.presentToast("Activity is already recorded as complete", { cssClass: 'inkpath-toast' });
          return;
        } else if (!userActivityDoc.delete) {
          if(!completing) {
            MyUtil.presentToast("Activity is already in your personal list", { cssClass: 'inkpath-toast' });
          }
          return;
        }
      }

      // remove delete flag if any
      delete userActivityDoc.delete;

      // set type and data
      userActivityDoc.type = MyUtil.DOC_TYPE.USER_ACTIVITY;
      userActivityDoc.data = {
        activity_id: id,
        status: MyUtil.CONST.APP_META.USER_ACTIVITY_STATUS_ACTIVE,
      };

      // remove ts if any for sync again
      delete userActivityDoc.ts;

      return MyDb.userSave(userActivityDoc)
        .then((doc) => {
          // attempt server update
          return this.appapi.saveUserActivities().then((result)=> {
            if(!!result) {
              MyUtil.presentToast("This activity has been added to your personal activity list", {
                duration: MyUtil.CONST.DURATION_TOAST_LONG,
                cssClass: 'inkpath-toast'
              });
            }
          });
        })
        .catch(() => {
          // do something
        });
    });
  }

  public deleteFromUserActivity(userActivityDoc: any): Promise<any> {
    // set delete flag
    userActivityDoc.delete = true;

    // remove ts if any for sync again
    delete userActivityDoc.ts;

    return MyDb.userSave(userActivityDoc)
      .then((doc) => {
        // try to update server
        return this.appapi.saveUserActivities().then(()=> {
          MyUtil.presentToast(
            "This activity has been removed from your personal activity list", { cssClass: 'inkpath-toast' }
          );
        });
      })
      .catch(() => {
        // do something
      });
  }

  public listBookingStatus(futureOnly: boolean = false): Promise<any> {
    let data = [];
    let apiUrl = "/list/booking-status";
    if(futureOnly) {
      apiUrl = apiUrl + '/1';
    }

    return this.appapi
      .post(apiUrl, {})
      .then((result) => {
        if (result["#status"] === "success") {
          data = result["#data"];
        } else {
/*           MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          ); */
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
        return data;
      });
  }


  public listBookingClashes(activityId: number): Promise<any> {
    let data = [];
    return this.appapi
      .post("/list/" + activityId + "/booking-clashes", {})
      .then((result) => {
        if (result["#status"] === "success") {
          data = result["#data"];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
        return data;
      });
  }


  


  public loadBookingMetadata(id: any): Promise<any> {
    let params = {
      bookable_id: id,
    };
    return this.appapi
      .post("/load/booking-metadata", params)
      .then((result) => {
        let data = [];
        if (result["#status"] === "success") {
          data = result["#data"];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
          return null;
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
       /*  MyUtil.presentToast(
          "Unable to contact server. Check your connection or try again later.", { cssClass: 'inkpath-toast' }
        ); */
      });
  }

  public loadActivityBooking(id): Promise<any> {
    let params = {
      bookable_id: id,
    };
    return this.appapi
      .post("/load/booking", params)
      .then((result) => {
        let data = [];
        if (result["#status"] === "success") {
          data = result["#data"];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
          return null;
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
      });
  }

  public saveBooking(formData: any): Promise<any> {
    let apiUrl = "/save/booking";
    if(formData['convert_booking']) {
      apiUrl = "/convert/booking";
    }
    return this.appapi
      .post(apiUrl, formData)
      .then((result) => {
        let data = [];
        if (result["#status"] === "success") {
          data = result["#data"];
        } else if (result["#status"] === "failed") {
          MyUtil.presentToast(
            MyUtil.responseToMessage(result["#message"], result["#errors"]), { cssClass: 'inkpath-toast' }
          );
          return null;
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
/*         MyUtil.presentToast(
          "Unable to contact server. Check your connection or try again later.", { cssClass: 'inkpath-toast' }
        ); */
      });
  }

  public cancelBooking(id, labelKey): Promise<any> {
    return this.appapi
      .post("/cancel/booking", {
        bookable_id: id,
        cancel_reason: labelKey,
      })
      .then((result) => {
        let data = [];
        if (result["#status"] === "success") {
          data = result["#data"];
        } else if (result["#status"] === "failed") {
          MyUtil.presentToast(
            MyUtil.responseToMessage(result["#message"], result["#errors"]), { cssClass: 'inkpath-toast' }
          );
          return null;
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
/*         MyUtil.presentToast(
          "Unable to contact server. Check your connection or try again later.", { cssClass: 'inkpath-toast' }
        ); */
      });
  }

  public isValidUrl(joiningLink: string) {
    let isUrl = false;
    try {
      let url = new URL(joiningLink);
      if (url) {
        isUrl = true;
      }
    } catch (error) {
      //do nothing
    }
    return isUrl;
  }

  public openJoiningLink(joiningLink: string) {
    if (this.isValidUrl(joiningLink)) {
      let browser = MyUtil.createInAppBrowser(joiningLink, "_system");
      browser.show;
    }
  }

  public getShortDisplayDateTime(orgDetails: any, startAt: number, startTime: string, endAt: number, endTime: string) : string
  {
    let startDisplay = MyUtil.formatUnixTimeStampDate(startAt, orgDetails.org_date_format);
    let endDisplay = MyUtil.formatUnixTimeStampDate(endAt, orgDetails.org_date_format);

    if(startTime) {
      startTime = MyUtil.formatHISTimeFormatToAMPM(startTime);
      if(startTime === "00:00") {
        startTime = null;
      }
    }
    
    if(endTime) {
      endTime = MyUtil.formatHISTimeFormatToAMPM(endTime);
      if(endTime === "00:00") {
        endTime = null;
      }
    }

    if(orgDetails.display_timezone_abbr === 1) {
      let endAbbr = MyUtil.calculateTimezoneAbbr(endAt, orgDetails.timezone_code);
      let startAbbr = MyUtil.calculateTimezoneAbbr(startAt, orgDetails.timezone_code);

      if(endTime) {
        endTime += " " + endAbbr;
      }

      //Display start timezone only if the dates are the same (as we only show the start time, not the end time) 
      //or if the abbreviations are different (if same, only show at the end)
      //or the end time is blank
      if(startTime && (startDisplay === endDisplay || startAbbr !== endAbbr || !endTime)) {
        startTime += " " + startAbbr;
      }
    }

    return (startDisplay === endDisplay ? startDisplay + (startTime ? ' ' + startTime : '') : 
      'from ' + startDisplay + (startTime ? ' ' + startTime : '') + ' to ' + endDisplay + (endTime ? ' ' + endTime : ''));
  }



  public getLongDisplayDateTime(orgDetails: any, startAt: number, startTime: string, endAt: number, endTime: string) : string
  {
    let startDisplay = MyUtil.formatUnixTimeStampDate(startAt, orgDetails.org_date_format);
    let endDisplay = MyUtil.formatUnixTimeStampDate(endAt, orgDetails.org_date_format);

    if(startTime) {
      startTime = MyUtil.formatHISTimeFormatToAMPM(startTime);
      if(startTime === "00:00") {
        startTime = null;
      }
    }
    
    if(endTime) {
      endTime = MyUtil.formatHISTimeFormatToAMPM(endTime);
      if(endTime === "00:00") {
        endTime = null;
      }
    }

    if(startTime || endTime) {
      //If either start time or end time set, display both
      if(!startTime) {
        startTime = "00:00";
      }

      if(!endTime) {
        endTime = "00:00";
      }
    }

    // do we display the 3 letter timezone?
    if(orgDetails.display_timezone_abbr === 1) {
      let endAbbr = MyUtil.calculateTimezoneAbbr(endAt, orgDetails.timezone_code);
      let startAbbr = MyUtil.calculateTimezoneAbbr(startAt, orgDetails.timezone_code);

      if(endTime) {
        endTime += " " + endAbbr;
      }

      //Display start timezone only if there is no end time, or the start and end abbreviation is different (one date is in daylight saving time and the other isn't)
      if(startTime && (!endTime || (startAbbr !== endAbbr))) {
        startTime += " " + startAbbr;
      }
    }

    return (startDisplay === endDisplay ? startDisplay + (startTime || endTime ? ' ' : '') + (startTime ? startTime : '') + (startTime && endTime ? '&#8209;' : '') + (endTime ? endTime : '') : startDisplay + (startTime ? ' ' + startTime : '') + ' to ' + endDisplay + (endTime ? ' ' + endTime : ''));
    
  }
}
