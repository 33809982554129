import PouchDB from 'pouchdb';

import { MyUtil } from '../libs/MyUtil';
import { environment } from '../environments/environment';

declare var appConfig: any;
declare var emit: any;


export class MyDb {
  static DESIGN_PREFIX: string = '_design';
  static INDEX_PREFIX: string = 'index';
  static appDb: any;
  static userDb: any;
  static initialized: boolean = (() =>{

   
      MyDb.appDb = new PouchDB(environment.APP_DB_NAME, {
        auto_compaction: true,
        cache : false,
        adapter: null,
        revs_limit: 1
      });


      return true;
  })();

  static info(db: any) {
    return db.info().then(info => {
      return info;
    });
  }

  static load(db: any, docId: string) {
    //MyUtil.debug(['Load doc', docId]);
    return db.get(docId).then(doc => {
      //MyUtil.debug(['Loaded doc', docId]);
      return doc;
    }).catch(err => {

      MyUtil.error(err);
      throw err;
    })
  }

  static remove(db: any, doc: any) {
    MyUtil.debug(['Remove doc', doc._id]);
    return db.remove(doc).then(() => {
      MyUtil.debug(['Removed doc', doc._id]);
      return doc;
    }).catch(err => {
      MyUtil.error(err);
      throw err;
    })
  }

  static loadWithDefault(db: any, docId: string, defaultDoc: any) {
    return MyDb.load(db, docId).catch(err => {
      if (err.status === 404) { // use default value if not found
        defaultDoc._id = docId;
        MyUtil.debug(['Default value used', defaultDoc]);
        return defaultDoc;
      } else { // throw other error
        MyUtil.error(err);
        throw err;
      }
    });
  }

  static save(db: any, newDoc: any) {
    MyUtil.debug(['Save doc', newDoc._id]);

    if (newDoc._id) {
      return MyDb.load(db, newDoc._id).then(oldDoc => {
        newDoc._rev = oldDoc._rev;

        return db.put(newDoc).then(response => {
          MyUtil.debug(['Updated doc', newDoc._id]);
          return newDoc;
        });
      }).catch(err => {
        if (err.status === 404) { // create new if not found
          return db.put(newDoc).then(response => {
            newDoc._rev = response.rev;
            MyUtil.debug(['Created doc', newDoc._id]);
            return newDoc;
          });
        } else { // throw other error
          MyUtil.error(err);
          throw err;
        }
      });
    } else {
      return db.post(newDoc).then(response => {
        newDoc._id = response.id;
        newDoc._rev = response.rev;
        MyUtil.debug(['Posted doc', newDoc._id]);
        return newDoc;
      });
    }
  }

  static merge(db: any, newDoc: any) {
    MyUtil.debug(['Merge doc', newDoc._id]);
    return MyDb.load(db, newDoc._id).then(oldDoc => {
      delete newDoc._rev;
      let mergedDoc = MyUtil.lodash.merge(oldDoc, newDoc);

      return db.put(mergedDoc).then(response => {
        MyUtil.debug(['Merged doc', mergedDoc._id]);
        return mergedDoc;
      });
    }).catch(err => {
      if (err.status === 404) { // create new if not found
        return db.put(newDoc).then(response => {
          newDoc._id = response._id;
          newDoc._rev = response.rev;
          MyUtil.debug(['Created doc', newDoc._id]);
          return newDoc;
        });
      } else { // throw other error
        MyUtil.error(err);
        throw err;
      }
    });
  }

  static query(db: any, queryName: string, options: any) {
    queryName = MyDb.INDEX_PREFIX + '/' + queryName;
    MyUtil.debug(['Query start', queryName, options]);
    return db.query(queryName, options).then(result => {
      MyUtil.debug(['Query done', queryName, options, result.total_rows]);
      return result;
    }).catch(err => {
      if (err.status === 404) { // null if not found
        MyUtil.debug(['not found', queryName]);
        return null;
      } else { // throw other error
        MyUtil.error(err);
        throw err;
      }
    });
  }

  static flatQueryResult(result: any) {
    let data = [];
    if (result && result.rows) {
      return MyUtil.lodash.map(result.rows, item => {
        return item.doc;
      });
    }
    return data;
  }

  // app level stuffs
  static appDbInfo() {
    return MyDb.info(MyDb.appDb);
  }

  static async appLoad(docId: string) {
    return await  MyDb.load(MyDb.appDb, docId);
  }

  static appRemove(doc: any) {
    return MyDb.remove(MyDb.appDb, doc);
  }

  static appLoadWithDefault(docId: string, defaultDoc: any) {
    return MyDb.loadWithDefault(MyDb.appDb, docId, defaultDoc);
  }

  static appSave(newDoc: any) {
    return MyDb.save(MyDb.appDb, newDoc);
  }

  static appMerge(newDoc: any) {
    return MyDb.merge(MyDb.appDb, newDoc);
  }

  static appQuery(queryName: string, options: any) {
    return MyDb.query(MyDb.appDb, queryName, options);
  }

  static appInitViews(views: any) {
    return new Promise((resolve, reject) => {
      if (views) {
        let designDoc: any = {
          _id: MyDb.DESIGN_PREFIX + '/' + MyDb.INDEX_PREFIX,
          views: views
        };

        MyDb.appMerge(designDoc).then(doc => {
          MyUtil.debug('did init app views');
          resolve(doc);
        });
      } else {
        reject('no view found')
      }
    });
  }

  // user level stuffs
  static initUserDb(id: string) {
    MyDb.userDb = new PouchDB(environment.APP_DB_NAME + '_' + id, {
      auto_compaction: true,
      cache : false,
      adapter: null,
      revs_limit: 1
    });

    MyDb.prepareUserDbViews();
  }

  static forgetUserDb(destroy: boolean = false) {
    if (destroy) {
      return MyDb.userDb.destroy().then(function (response) {
        MyDb.userDb = null;
        MyUtil.debug('did destroyed user db');
      }).catch(function (err) {
        MyUtil.error(err);
      });
    } else {
      MyDb.userDb = null;
      MyUtil.debug('did forget user db');
      return Promise.resolve('did forget user db');
    }
  }

  static userDbInfo() {
    return MyDb.info(MyDb.userDb);
  }

  static userRemove(doc: any) {
    return MyDb.remove(MyDb.userDb, doc);
  }

  static userLoad(docId: string) {
    return MyDb.load(MyDb.userDb, docId);
  }

  static userLoadWithDefault(docId: string, defaultDoc: any) {
    return MyDb.loadWithDefault(MyDb.userDb, docId, defaultDoc);
  }

  static userSave(newDoc: any) {
    return MyDb.save(MyDb.userDb, newDoc);
  }

  static userMerge(newDoc: any) {
    return MyDb.merge(MyDb.userDb, newDoc);
  }

  static userQuery(queryName: string, options: any) {
    return MyDb.query(MyDb.userDb, queryName, options);
  }

  static userInitViews(views: any) {
    return new Promise((resolve, reject) => {
      if (views) {
        let designDoc: any = {
          _id: MyDb.DESIGN_PREFIX + '/' + MyDb.INDEX_PREFIX,
          views: views
        };

        MyDb.userMerge(designDoc).then(doc => {
          MyUtil.debug('did init user views');
          resolve(doc);
        });
      } else {
        reject('no view found')
      }
    });
  }

  // application related functions
  static prepareUserDbViews() {
    let views: any = {};

    views['by_type'] = {
      map: function(doc) {
        if (doc.type) {
          emit(doc.type, 1);
        }
      }.toString()
    };

    views['by_type_id'] = {
      map: function(doc) {
        if (doc.type) {
          if (doc.data && doc.data.id) {
            emit([doc.type, doc.data.id], 1);
          } else {
            emit([doc.type, null], 1);
          }
        }
      }.toString()
    };

    views['by_type_ts'] = {
      map: function(doc) {
        if (doc.type) {
          if (doc.ts) {
            emit([doc.type, doc.ts], 1);
          } else {
            emit([doc.type, null], 1);
          }
        }
      }.toString()
    };

    views['by_type_goal_id'] = {
      map: function(doc) {
        if (doc.type && doc.data && doc.data.goal_id) {
          emit([doc.type, doc.data.goal_id], 1);
        }
      }.toString()
    };

    views['by_type_activity_id'] = {
      map: function(doc) {
        if (doc.type && doc.data && doc.data.activity_id) {
          emit([doc.type, doc.data.activity_id], 1);
        }
      }.toString()
    };

    views['by_type_status'] = {
      map: function(doc) {
        if (doc.type) {
          if (doc.data && doc.data.status) {
            emit([doc.type, doc.data.status], 1);
          }
        }
      }.toString()
    };

    MyDb.userInitViews(views);
  }
}
