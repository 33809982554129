import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { RedirectProvider } from 'src/app/providers/redirect/redirect';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Appapi } from 'src/app/providers/appapi';
import { Store } from '@ngrx/store';
import * as appStore from '../../store';
// @IonicPage({
//   name: 'redirect',
//   segment: 'redirect/:target',
// })
@Component({
  selector: 'page-redirect',
  templateUrl: 'redirect.html',
})
export class RedirectPage implements OnDestroy {

  routeData: string = "";

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public router: Router, 
    public navParams: NavParams, 
    public redirectProvider: RedirectProvider,
    private route: ActivatedRoute,
    public appapi: Appapi,
    private store: Store<appStore.AppViewState>
  ) { }


  ngOnInit() {

    // process redirect request
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData = params.pageData;
      
    });

    this.store.dispatch(new appStore.RedirectSet(this.routeData));
    this.redirectProvider.setRedirectTarget(this.routeData);

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
