import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { DesktopChecker } from 'src/app/providers/desktopChecker';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

/**
 * Generated class for the PathwayManualCareerTypePage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

// @IonicPage()
@Component({
  selector: 'page-pathway-manual-career-type',
  templateUrl: 'pathway-manual-career-type.html',
})
export class PathwayManualCareerTypePage implements OnInit, OnDestroy{

  type: object;
  routeData:any = {};

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public router: Router, 
    public navParams: NavParams, 
    public desktopChecker: DesktopChecker,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData = params.pageData;
    });
    this.type = this.routeData.type;
  }

  ionViewDidEnter() {
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  
}
