import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-rsc-review-answers',
  templateUrl: './rsc-review-answers.component.html',
  styleUrls: ['./rsc-review-answers.component.scss'],
})
export class RscReviewAnswersComponent implements OnInit {

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  // Component variables.
  routeData: any = {};
  questionData: any;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private titleService:Title) {
      this.titleService.setTitle("Results Summary"); }

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData = JSON.parse(params.pageData);
    });
    this.questionData = this.routeData.questionData;
  }

  /** 
  * Open RSC review answers.
  */
  openRscReviewAnswers() {
    this.router.navigate(['/RscReviewAnswers', JSON.stringify(this.routeData)]);
  }

  /** 
  * Result summary.
  */
  resultSummary() {
    this.router.navigate(['/RscDiagnosticSummaryComponent', JSON.stringify(this.routeData)]);
  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}