import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { Clipboard } from '@capacitor/clipboard';
import { MyUtil } from '../../../libs/MyUtil';


@Component({
  selector: 'app-error-alert-modal',
  templateUrl: './error-alert-modal.component.html',
  styleUrls: ['./error-alert-modal.component.scss'],
})
export class ErrorAlertModalComponent implements OnInit {

  // Component variables.
  public data: any;
  public label: any = 0;
  enabled = false;

  constructor(public params: NavParams, public viewCtrl: ModalController) {
    if (params) {
      this.data = params.data;

      if(this.data.logCode) {
        if(this.data.logCode.indexOf('-') !== -1) {
          //this.data.logCode = this.data.logCode.substring(0, this.data.logCode.indexOf('-')).toUpperCase();
          this.data.logCode = this.data.logCode.toUpperCase();
        }
      }
    }
  }

  ngOnInit() {}

  /** Copy logCode to the clipboard */
  copyToClipboard(logCode: string) {
    Clipboard.write({ string: logCode }).then(() => {
      MyUtil.presentToast("Copied to clipboard");
    }).catch((err) => {
      MyUtil.error(err);
    });
  }

  // Close Modal.
  closeModal() {
    this.viewCtrl.dismiss();
  }

}
