import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { MyUtil } from '../../../libs/MyUtil';

@Component({
  selector: 'page-organizations',
  templateUrl: 'organizations.html',
  styleUrls: ['organizations.scss']
})
export class OrganizationsPage {
  pageData: any = {};

  constructor(public navParams: NavParams, public viewCtrl: ModalController) {
    this.pageData.top = navParams.get('top');
    this.pageData.parent = this.pageData.top;
    this.pageData.organizations = this.getChildren(this.pageData.parent);
    this.pageData.stack = [];
  }

  ionViewDidLoad() {
    MyUtil.firebaseSetScreenName('organizations');
  }

  ionViewDidEnter() {
    MyUtil.firebaseLogEvent('view_did_enter', {name: 'organizations', data: this.navParams.data});
  }

  ionViewDidLeave() {
    MyUtil.firebaseLogEvent('view_did_leave', {name: 'organizations', data: this.navParams.data});
  }

  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  * @param item     Parameter to process in action.
  */
  process(action, item) {
    switch (action) {
      case 'parent':
        if (this.pageData.stack.length > 0) {
          this.pageData.parent = this.pageData.stack.pop();
          this.pageData.organizations = this.getChildren(this.pageData.parent);
        }
        return;
      case 'select':
        if (item.children) {
          this.pageData.stack.push(this.pageData.parent);
          this.pageData.parent = item;
          this.pageData.organizations = this.getChildren(this.pageData.parent);
        } else {
          this.dismiss(item);
        }
        return;
      case 'cancel':
        this.dismiss(null);
        return;
      default:
        MyUtil.presentToast('"' + action + '" is not handled', { cssClass: 'inkpath-toast' });
        return;
    }
  }

  isTop() {
    return (this.pageData.stack.length < 1);
  }

  private getChildren (parent: any) {
    let result = [];

    if (parent.children) {
      result = MyUtil.lodash.orderBy(MyUtil.lodash.values(parent.children), [item => item.name.toLowerCase()], ['asc']);
    }

    return result;
  }

  private dismiss(data: any) {
    this.viewCtrl.dismiss(data);
  }
}
