import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { Subject } from 'rxjs';
import { Appapi } from 'src/app/providers/appapi';
import { QuestionnaireDataProvider } from 'src/app/providers/questionnaire-data/questionnaire-data';
/**
 * Generated class for the QuestionnaireIntroPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-questionnaire-select',
  templateUrl: 'questionnaire-select.html',
})
export class QuestionnaireSelectPage implements OnInit, OnDestroy{

  pageData: any = {};
  
  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public router: Router, 
    public navParams: NavParams, 
    public appapi:Appapi, 
    public questionnaireData: QuestionnaireDataProvider
    ) {

   
  }
  ngOnInit(): void {
    this.pageData.QuestionaireSectionList=[];
    this.getQuestionaireSections();
  }

  getQuestionaireSections() {
    this.questionnaireData.fetchData()
      .then(res => {


        this.questionnaireData.questionnaires.forEach(q=>{
          let sec =q.questionnaire.sections;
          sec.forEach(section => {
            section.id =  q.questionnaire.id;
          });
          this.pageData.QuestionaireSectionList.push(sec);
        });

        this.pageData.QuestionaireSectionList.forEach(sectionArr => {
          sectionArr.forEach(section => {
            section.questionaireComplete = true;
            section.questions.forEach(question => {
  
              if (question.complete == false)
                section.questionaireComplete = false;
  
            });
          });
         
      });

      this.condenseQuestionaireSectionList();

    });


  }



  condenseQuestionaireSectionList() {

    let newArr = [];

    this.pageData.QuestionaireSectionList.forEach(secArr => {

      secArr.forEach(section => {
        newArr.push(section);
      });

    });


    this.pageData.QuestionaireSectionList = newArr;


  }


  openQuestionnaire(qid){

    this.router.navigate(['/QuestionnaireIntroPage', JSON.stringify({ fromOnboarding: false , fromSelect:true, id:qid})]).then(() => {
    
    });


/*
    let questionIndex = -1;
    let questionsComplete = false;





    this.questionnaireData.questionnaire.sections[index].questions.forEach((element, forIndex) => {
      if (element.complete) {
        questionIndex = forIndex;

        if (forIndex == this.questionnaireData.questionnaire.sections[index].questions.length - 1) {
          questionsComplete = true;
        }

      }



    });

      this.router.navigate(QuestionnaireIntroPage, { fromOnboarding: false , fromSelect:true}).then(() => {
        this.questionnaireData.activeSectionIndex = index;



        if (!questionsComplete) {

          this.questionnaireData.activeSectionIndex = index;


          if (questionIndex > -1) {
            const startedAt = moment.utc(this.questionnaireData.questionnaire.started_at).fromNow();
            MyUtil.presentAlert({
              title: `You have an unfinished questionnaire that you started ${startedAt}`,
              message: 'Do you want to continue or start again?',
              buttons: [
                {
                  text: 'Continue',
                  handler: () => {
                    this.questionnaireData.activeQuestionIndex = questionIndex;
                  }
                },
                {
                  text: 'Start from the beginning',
                  handler: () => {
                    this.questionnaireData.activeQuestionIndex = 0;

                  }
                },
                {
                  text: 'Cancel',
                  handler: () => {

                  }
                },
              ]
            });
          }

      }else{
        this.router.navigate(QuestionnaireIntroPage, { fromOnboarding: false , inReview:true }).then(() => {
          this.questionnaireData.activeSectionIndex = index;
          this.questionnaireData.activeQuestionIndex = 0;
          const startedAt = moment.utc(this.questionnaireData.questionnaire.started_at).fromNow();

        MyUtil.presentAlert({
          title: `Questionnaire Complete ${startedAt}`,
          message: 'Questionnaire is finished, Do you want to review your answers ?',
          buttons: [
            {
              text: 'Continue',
              handler: () => {

              }
            },
            {
              text: 'Cancel',
              handler: () => {

              }
            },
          ]
        });
      });

    }



      });


*/

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
