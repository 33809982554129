import { Injectable } from '@angular/core';
import {Appapi} from "../appapi";

/*
  Generated class for the QuestionnaireDataProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: 'root',
})
export class QuestionnaireResultsProvider {

  constructor(public appapi: Appapi) {

  }

  public fetchData(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.appapi.getMostRecentQuestionnaireResults().then((res) => {
        if (res['#status'] ===  'success') {
          return resolve(res['#data']);
        } else {
          return reject(res['#message']);
        }
      })
    });
  }

  public requestEmail(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.appapi.requestQuestionnaireResultsEmail().then((res) => {
        if (res['#status'] ===  'success') {
          return resolve(res['#data']);
        } else {
          return reject(res['#message']);
        }
      })
    });  }
}
