import { Component, OnInit, ViewChild } from '@angular/core';
import { Appapi } from "../../providers/appapi";
import { DesktopChecker } from "../../providers/desktopChecker";
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NavController, NavParams, Platform } from '@ionic/angular';
import { takeUntil } from 'rxjs/operators';
import { DiagnosticCompleteModal } from 'src/app/components/diagnostic-complete-modal/diagnostic-complete-modal';
import { MyUtil } from 'src/libs/MyUtil';
import { DiagnosticService } from '../../providers/diagnostic/diagnostic-service';
import { Subject } from 'rxjs';
import { WalkthroughService } from 'src/app/services/walkthrough.service';
import { NgxCopilotService } from 'ngx-copilot';
import { Title } from '@angular/platform-browser';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { HttpClient } from '@angular/common/http';
import { MyDb } from 'src/libs/MyDb';
import { formatDate } from '@angular/common';

@Component({
  selector: 'page-diagnostic-summary',
  templateUrl: 'diagnostic-summary.html',
  styleUrls: ['diagnostic-summary.scss'],
})
export class DiagnosticSummaryPage implements OnInit {

  // Component variables.
  @ViewChild('Content', { static: true }) content;
  pageData: any = {};
  loading: any;
  questionData: any;
  skillStats: any;
  totalSkillCount = 0;
  skills = [];
  previousSubmissions: any;
  fromPrevious = false;
  pageLabel = 'DiagnosticSummaryPage';
  routeData: any = {};
  appUser: any;
  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public navCtrl: NavController,
    private copilot: NgxCopilotService,
    public appapi: Appapi,
    public router: Router,
    private route: ActivatedRoute,
    public navParams: NavParams,
    public httpClient: HttpClient,
    public desktopChecker: DesktopChecker,
    private nativeHTTP: HTTP,
    private file: File,
    public platform: Platform,
    private fileOpener: FileOpener,
    public diagnosticService: DiagnosticService,
    private titleService:Title) {
      this.titleService.setTitle("Questionnaire Summary"); 
    }

  async ngOnInit(): Promise<void> {

    await  MyDb.appLoad(MyUtil.DOC_ID.APP_USER).then(appUser =>{
      this.appUser = appUser;
    });
    this.pageData.displayHelpIcon = true;

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData = JSON.parse(params.pageData);
    });

    // Skills Stats
    this.questionData = this.routeData.questionData;
    this.fromPrevious = this.routeData.fromPrevious;

    // check if recommendations are active - hide next steps if not
    let userOrg = MyUtil.getRootOrganization();
    this.pageData.showNextSteps = false;

    if (userOrg.enable_recommendations && userOrg.enable_recommendations === 1) {
      this.pageData.showNextSteps = true;
    }

    let skillsToDisplay = [];

    this.questionData.forEach(q => {
      this.skills = this.skills.concat(q.skills)
    });
    var seen = {};
    let uniqueSkills = this.skills.filter(function (item) {
      if (item.is_operational == 0) {
        return seen.hasOwnProperty(item.name) ? false : (seen[item.name] = true);
      }
    }).map(r => r.name);
    let allSkillNames = this.skills.map(r => r.name);
    uniqueSkills.forEach((item) => {
      let skillCount = this.getOccurrence(allSkillNames, item);
      skillsToDisplay.push({ item: [item, skillCount] });
      this.totalSkillCount = (skillCount > this.totalSkillCount) ? skillCount : this.totalSkillCount;
    });
    this.skillStats = skillsToDisplay;

     

    this.startWalkthrough()
  }

  filterOutOperationalSkills(list){
    //console.log('list',list)
    let filteredList = list?.filter((skill)=>{
      return skill.is_operational === 0;
    })
    return filteredList;
  }

  /** 
  * Start walkthrough.
  */
  startWalkthrough() {
    if (!WalkthroughService.isWalkthroughComplete(this.pageLabel) && !WalkthroughService.allWalkthroughsDisabled()) {
      setTimeout(() => {
        this.copilot.checkInit('27');
      }, 1000);
    } 
    this.pageData.displayHelpIcon = !WalkthroughService.allWalkthroughsDisabled();
    
  }

  /** 
  * Re initialize and specify step.
  * @param stepNumber   stepNumber: string.
  */
  initPosition = (stepNumber: any) => this.copilot.checkInit(stepNumber);

  /** 
  * Next step.
  * @param stepNumber   stepNumber: string.
  */
  nextStep = (stepNumber: any) => this.copilot.next(stepNumber);

  /** 
  * Finish copilot walkthroughs.
  */
  done = () => this.copilot.removeWrapper();

  /** 
  * Set Walkthrough state. 
  * @param pageName   Name of page
  * @param value      Boolean - (Has been visited or not)
  */
  setWalkthroughStateHandler(pageName: string, value: boolean) {
    WalkthroughService.setWalkthroughState(pageName, value)
  }

  /** 
  * Percentage of element as pixels.
  */
  percentAsValue(percentage, element) { return (percentage * element) / 100 }

  /** 
  * Pixels of element as Percentage.
  */
  valueAsPercent(value1, value2) {
    return `${(value1 / value2) * 100}%`;
  }

  /** 
  * Get occurrance.
  */
  getOccurrence(array, value) {
    var count = 0;
    array.forEach((v) => (v === value && count++));
    return count;
  }

  /** 
  * Next steps.
  */
  async nextSteps() {
    let reflectionCompleteModal = MyUtil.createModal(DiagnosticCompleteModal, {
      title: "What would you like to do next?",
    });
    (await reflectionCompleteModal).onDidDismiss().then((data) => {
      if (data.data === 'visitRecomendedActivities') {
        this.router.navigate(['/RecommendedComponent']);
      }
      if (data.data === 'visitActivities') {
        this.router.navigate(['/']);
      }
    });
    (await reflectionCompleteModal).present();
  }

  /** 
  * Previous submissions.
  */
  viewPreviousSubmissions() {
    this.router.navigate(['/DiagnosticSummaryListPage', JSON.stringify({ id: this.routeData.id })]);
  }

  /** 
  * Retake diagnostic.
  */
  retakeDiagnostic() {
    this.diagnosticService.getDiagnosticTests().then(res => {
      this.pageData.diagnostics = res;
      let array = Object.keys(res)
        .map(function (key) {
          return res[key];
        });

      let selectedTest = array.find(o => o.id === this.routeData.id);
      this.router.navigate(['/DiagnosticQuestionsPage', JSON.stringify({ id: this.routeData.id, test: selectedTest, fromPrevious: false })]);
    });
  }

  /** 
  * Email summary.
  */
  emailSummary() {
    this.diagnosticService.sendSummaryEmail(this.routeData.id).then(res => {
      if (res === 'success') {
        MyUtil.presentToast('Questionnaire summary email sent - please check your Inbox.', { cssClass: 'inkpath-toast' });
      } else {
        //console.log("Error on server sending email");
      }
    });
  }
// export(testId, type)
  export(type){
    let profile = MyUtil.getProfile();
    let oid = profile.root_oid;
    let url = MyUtil.context.API_SERVER_URL + MyUtil.context.API_PATH +  '/diagnostic/submissions/export/'+this.routeData.id+'/'+ oid+'/'+type;
    this.exportDiagnostic(url, type, oid);
  }



  async exportDiagnostic(url, type, oid) {
    let loading = MyUtil.presentLoading();

    if (MyUtil.isMobileAppMode()) {

      var fileSpec = '';
      var fileName = '';
      if (type === 'doc') {
        fileName = 'questionnaire_export.docx';
        fileSpec = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      } else {
        fileName = 'questionnaire_export.xlsx';
        fileSpec = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      }

      let filePath = (this.platform.is('ios')) ? this.file.documentsDirectory + fileName : this.file.dataDirectory + fileName;

      let tokenUrl = url + "?uid=" + this.appUser.id + '&token=' + this.appUser.api_token + '&oid=' + oid;

      this.nativeHTTP.downloadFile(tokenUrl, {}, {}, filePath).then((entry) => {
        MyUtil.debug('download complete: ' + filePath);

        this.fileOpener.open(filePath, fileSpec).then(
          async () => {
            (await loading).dismiss();
            MyUtil.debug('File opened');
          }, async (err) => {
            (await (loading)).dismiss();
            MyUtil.debug('File to open');
            MyUtil.debug(JSON.stringify(err));
          }

        );
      }, async (error) => {
        (await loading).dismiss();
        MyUtil.debug(error);
      }
      );

    } else {
      // Browser download
      (await loading).dismiss();
      // https://backend.test/api/v1/diagnostic/submissions/export/2/1/xls ?uid=&token=
      let profile: any = MyUtil.getProfile();
      let oid = profile.root_oid;
      let tokenUrl = url + "?uid=" + this.appUser.id + '&token=' + this.appUser.api_token + '&oid=' + oid;
      this.httpClient.get(tokenUrl, {
        responseType: "blob"
      }).subscribe(
        (res) => {
          // var fileURL = window.URL.createObjectURL(res);
          // let tab = window.open();
          // tab.location.href = fileURL;
          let currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
          let downloadFileName = currentDate + "_" + profile.first_name + "_" + profile.last_name + "_Questionnaire_Export";
          this.downLoadFile(res, downloadFileName);
        },
        (err) => {
          console.log('HTTP Error', err)
        },
        () => console.log('HTTP request completed.')
      )

    }
  }



  downLoadFile(data, fileName) {
    const anchorElement = document.createElement('a');
  
    document.body.appendChild(anchorElement);
  
    anchorElement.style.display = 'none';

    const url = window.URL.createObjectURL(data);
  
    anchorElement.href = url;
    anchorElement.download = fileName;
    anchorElement.click();
  
    window.URL.revokeObjectURL(url);
  }


  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
