import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-recurring-events-modal',
  templateUrl: './recurring-events-modal.component.html',
  styleUrls: ['./recurring-events-modal.component.scss'],
})
export class RecurringEventsModalComponent implements OnInit {

  // Component variables.
  public data: any;
  public label: any = 0;
  enabled = false;

  constructor(public router: Router,
    public params: NavParams, 
    public viewCtrl: ModalController) {

    if (params) {
      this.data = params.data;
    }
  }

  // Handle action.
  handleAction() {
    this.viewCtrl.dismiss();
  }


  ngOnInit() {}

  // Close Modal.
  closeModal() {
    this.viewCtrl.dismiss();
  }


  navigateTo(eventId: number) {
    if(this.data.type == 'activity') {
      this.router.navigate(['/ActivityDetailPage', JSON.stringify({ id: eventId })]);
    } else if(this.data.type == 'course') {
      this.router.navigate(['/CourseDetailPage', JSON.stringify({ courseID: eventId })]);
    }
    
    this.viewCtrl.dismiss();
  }

}
