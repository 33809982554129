import { Component, EventEmitter, OnInit} from '@angular/core';

@Component({
  selector: 'rating',
  templateUrl: 'rating.html',
  styleUrls: ['rating.scss'],
  inputs: ['rating', 'default', 'range'],
  outputs: ['rateChange'],

})
export class RatingComponent implements OnInit{

  // Component variables.
  range: Array<number>;
  rating: number;
  default: number;
  rateChange: any;

  constructor() {
    this.rateChange = new EventEmitter();
  }

  ngOnInit() {
    this.rating = this.default;
    this.range = (this.range) ? Array.apply(null, {length: this.range}).map(Number.call, Number) : [1,2,3,4,5];
  }

  // Update rating.
  update(value) {
    this.rating = value;
    this.rateChange.emit(value);
  }
}
