import { Component } from "@angular/core";
import { NavParams, ModalController } from "@ionic/angular";

@Component({
  selector: "onboarding-reminder-modal",
  templateUrl: "onboarding-reminder-modal.html",
})
export class OnboardingReminderModal {

  // Component variables.
  public data: any;
  public label: any = 0;

  constructor(public params: NavParams, public viewCtrl: ModalController) {
    if (params) {
      this.data = params.data;
    }
  }

  // Handle action.
  handleAction() {
    this.viewCtrl.dismiss('revisitReflection');
  }

  // Close modal.
  closeModal() {
    this.viewCtrl.dismiss();
  }
}
