import { Directive, ElementRef, Input } from '@angular/core';
import { Store, State } from '@ngrx/store';

import * as D3 from 'd3';
import { Observable } from 'rxjs';
import { getDisplayMode } from 'src/app/store/selectors/view.selector';
import * as appStore from '../../store';

declare var app_branding: any;

@Directive({
  selector: '[pie-chart]'
})
export class PieChart {
  @Input('pie-chart-percentage') percentage: any;
  @Input('pie-chart-width') width: any;
  @Input('pie-chart-animate') animate: number;
  canvas: any;
  globalColors: any;
  mainColor = '#363542';


  displayMode$!: Observable<string>;


  constructor(
    private store: Store<appStore.AppViewState>, private state: State<any>,  
    public element: ElementRef) {

    // this.store.select(getDisplayMode).subscribe(mode =>{
    //   if(mode === 'light-mode'){
    //     this.mainColor = '#efefef';
    //   }
    // })


    this.globalColors = ['#890385', '#00afbc', '#028826'];
    try {
      if(typeof app_branding !== 'undefined') {
        if (app_branding && app_branding.profile_chart_colors && app_branding.profile_chart_colors.global) {
          this.globalColors = app_branding.profile_chart_colors.global;
        }
      }
    } catch (err) {
        console.log(err);
    }
  }

  ngOnChanges(changes) {
    // render use cached data
    this.render();
  }

  render() {
    // prepare data
    let percentage = (this.percentage && this.percentage > 0 ? this.percentage : 0);
    let data = [percentage, 1 - percentage];

    // render chart
    let htmlElement = this.element.nativeElement,
      width = (this.width && this.width > 0 ? this.width : 60),
      height = width, //htmlElement.clientHeight,
      radius = width / 2,
      thick = radius * 0.4;

    if (!this.canvas) {
      this.canvas = D3.select(htmlElement)
        .append('svg')
    }
    this.canvas
      .attr('width', width)
      .attr('height', height);

    this.canvas.selectAll('g.pie-chart').remove();

    let group = this.canvas.append("g")
      .attr('transform', 'translate(' + radius + ', ' + radius + ')')
      .attr('class', 'pie-chart');

    let arc = D3.arc()
      .innerRadius(radius - thick)
      .outerRadius(radius);

    let pie = D3.pie().sortValues(null);

    let arcs = group.selectAll('.arc')
      .data(pie(data))
      .enter()
      .append('g')
      .attr('class', 'arc');

    arcs.append('path')
      .attr('d', arc)
      .attr('fill', (d) => {
        let color = this.mainColor;
        return color;
      });

    arcs.append('path')
      .attr('d', arc)
      .attr('fill', (d) => {
        let color = this.mainColor;
        if (d.index < 1) {
          if (d.value < 0.25) {
            color = this.globalColors[0]; 
          } else if (d.value < 1) {
            color = this.globalColors[1]; 
          } else {
            color = this.globalColors[2]; 
          }
        }
        return color;
      })
      .transition()
      .delay((d, i) => {
        return i * 100;
      })
      .duration( (this.animate) ? this.animate : 0)
      .attrTween('d', (d) => {
        let i = D3.interpolate(d.startAngle, d.endAngle);
        if (d.index < 1) {
          return (t) => {
            d.endAngle = i(t);
            return arc(d);
          };
        }

      });

    group.append('text')
      .attr('fill', '#f4f4f4')
      .attr('class', 'pie-chart-label')
      .attr('font-size', (radius-thick) * 0.7)
      .attr('x', - (radius-thick) * (percentage >=1 ? 0.8 : (percentage >=0.1 ? 0.55 : 0.45) ) )
      .attr('y', (radius-thick) * 0.25)
      .text(() => {
        return Math.round(percentage * 100) + '%';
      });

  }
}
