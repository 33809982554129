import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivityListItemVO } from 'src/app/valueObjects/lists.vo';

@Component({
  selector: 'app-dash-button',
  templateUrl: './dash-button.component.html',
  styleUrls: ['./dash-button.component.scss'],
})
export class DashButtonComponent implements OnInit {

  // Inputs.
  @Input() label?: string;
  @Input() icon?: string;
  @Input() colour?: string;

  /**
  * Clicked event emitter.
  */
  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() { }

  /** 
  * Click handler.
  * @param item   Emits item data object that has been clicked.
  */
  clickHandler($event) {
    this.clicked.emit($event);
  }

}
