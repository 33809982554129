import { Component, OnDestroy, OnInit } from '@angular/core';
import { MyUtil } from "../../../libs/MyUtil";
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { DesktopChecker } from 'src/app/providers/desktopChecker';
import { QuestionnaireSection } from 'src/app/providers/questionnaire-data/models';
import { QuestionnaireResultsProvider } from 'src/app/providers/questionnaire-results/questionnaire-results.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';


/**
 * Generated class for the SkillsAnalysisSummaryPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

// @IonicPage()
@Component({
  selector: 'page-skills-analysis-summary',
  templateUrl: 'skills-analysis-summary.html',
  styleUrls: ['skills-analysis-summary.scss'],
})
export class SkillsAnalysisSummaryPage implements OnInit, OnDestroy{

  pageData:any ={};
  routeData:any ={};
  sections: Array<QuestionnaireSection>;
  
  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public router: Router,
    private route: ActivatedRoute, 
    public navParams: NavParams,
    public questionnaireResults: QuestionnaireResultsProvider, 
    public desktopChecker: DesktopChecker,
    private titleService:Title) {
      this.titleService.setTitle("Skills Analysis Summary");
  }

  ngOnInit() {

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData =JSON.parse(params.pageData);
    });

    this.pageData.fromSelect =this.routeData.fromSelect;

    
    let loading = MyUtil.presentLoading();
    this.questionnaireResults.fetchData()
        .then(async res => {
          this.sections = [];
          res.forEach(ques => {
            ques.sections.forEach(sec => {
            this.sections.push(sec);
            });
          });
          (await loading).dismiss();
        })
        .catch(async err => {
          (await loading).dismiss();
          MyUtil.presentToast(err, { cssClass: 'inkpath-toast' });
          this.router.navigate(['/UserPage']);
        })
  }

  goToPathway() {
    this.router.navigate(['/ChooseAPathwayPage', JSON.stringify({fromOnboarding: true})]);
  }

  goToSkill(question) {
      let skill = Object.assign(question.skill, {
          'skill_level': question.skill_level,
          'interest': question.interest,
          'comments': question.comments,
      });
      this.router.navigate(['/CareerPathSkillTileDetailsPage', JSON.stringify({skill: skill, showLinkToDetails: (question.type !== 'inkpath')})]);
  }

  async email() {
    await MyUtil.presentAlert({
      'message': `Send results to email?`,
      buttons: [
        {
          text: 'Cancel',
          handler: () => {

          }
        },
        {
          text: 'Continue',
          handler: () => {
            const loading = MyUtil.presentLoading();
            this.questionnaireResults.requestEmail()
              .then(async res => {
                (await loading).dismiss();
                await MyUtil.presentAlert({
                  'message': 'Please check your email, We have sent your Skills Analysis Summary to the email address linked to your user account.',
                  buttons: [
                    {
                      text: 'OK',
                      handler: () => {
                        return;
                      }
                    },
                  ]
                });
              })
              .catch(async err => {
                (await loading).dismiss();
                MyUtil.presentToast(err, { cssClass: 'inkpath-toast' });
              })
          }
        },
      ]
    });
  }

  skipToApp() {
    //this.navCtrl.setRoot(ActivitiesPage);
  }

  openSelector(){
    this.router.navigate(['/QuestionnaireSelectPage']);

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
