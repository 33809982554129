import { Component, NgZone, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { ActivityService } from 'src/app/providers/activity-service';
import { MyUtil } from '../../../libs/MyUtil';

@Component({
  selector: 'page-course-questions',
  templateUrl: 'course-questions.html',
  styleUrls: ['course-questions.scss']
})
export class CourseQuestionsPage {

  @ViewChild('Content', {static: true}) content;
  pageData: any = {
    bookingDetails: [],
    detailFormFields: {},
  };

  constructor(public router: Router,
    public navParams: NavParams,
    public viewCtrl: ModalController,
    public zone: NgZone,
    public activityService: ActivityService,
    public formBuilder: UntypedFormBuilder) {
      
    this.pageData.bookingFormSubmitAttempt = false;
    this.pageData.bookingFields = [];
    this.pageData.containsAccessibility = false;
    this.pageData.containsGeneral = false;
    this.pageData.bookingDetails.details = this.navParams.get("questions");
    this.pageData.containsAccessibility = false;
    this.pageData.removeComments = false;

    MyUtil.lodash.forEach(this.pageData.bookingDetails.details, (detail) => {
     // this.pageData.detailFormFields[detail.label] = ['', detail.type == 1 && detail.item != 5 && detail.label != 'require_comments' ? Validators.compose([Validators.required]) : null];
     let validation =  
     (
      detail.type === 1 && 
      detail.item !== 5 && 
      detail.label !== 'require_comments' 
      //|| detail.type === 2 && detail.label !== 'accessibility_free_text'
      ) ? Validators.compose([Validators.required]) : null;

     this.pageData.detailFormFields[detail.label] = ['', validation];
      if (detail.label == 'require_dietary' || detail.label == 'require_comments') {
        this.pageData.detailFormFields[detail.label + '_comment'] = [''];
      }
      if (detail.type == 1) {
        this.pageData.containsGeneral = true;
      }
      if (detail.type == 2) {
        this.pageData.containsAccessibility = true;
      }

      // if we have a custom question, we want to remove the general question
      if (detail.item == 5) {
        this.pageData.removeComments = true;
      }

    });

    if (this.pageData.containsAccessibility) {
      this.pageData.detailFormFields.accessibility = ['', Validators.compose([Validators.required])];
    }
    this.pageData.bookingForm = this.formBuilder.group(this.pageData.detailFormFields);
  }

  ionViewDidLoad() {
  }

  ionViewDidEnter() {

  }

  ionViewDidLeave() {
  }


  process() {

    // validate input and prepare api call
    let data;
    this.pageData.bookingFormSubmitAttempt = true;
    if (!this.pageData.bookingForm.valid) {
      MyUtil.presentToast('Please review and revise your input', { cssClass: 'inkpath-toast' });
      return;
    }
    
    data = this.pageData.bookingForm.value;
    data.details = [];
    //check to see if dietary requirements has been filled in if the requirement is yes
    if (data['require_dietary'] == 1 && data['require_dietary_comment'].length == 0) {
      MyUtil.presentToast('Please specify your dietary requirements', { cssClass: 'inkpath-toast' });
      return;
    }

    //Get the name of the free text comment field
    let freeTextField = 'require_comments_comment';
    MyUtil.lodash.forEach(this.pageData.bookingDetails.details, (detail) => {
      if(detail.item == 5) {
        freeTextField = detail.label;
      }
    });

    //reshape data into details format
    MyUtil.lodash.forEach(this.pageData.bookingDetails.details, (detail) => {

      if(detail.label != 'require_comments' && data[detail.label] == 1) {
        let reshapedDetail = {
          item: detail.item,
          comment: data[detail.label + '_comment'],
          type: detail.type
        }
        data.details.push(reshapedDetail);
      }

      if(detail.label == freeTextField && data[freeTextField] != "") {
        let reshapedDetail = {
          item: detail.item,
          comment: data[freeTextField],
          type: detail.type
        }
        data.details.push(reshapedDetail);
      }

      if(detail.label == 'accessibility_free_text' && data['accessibility_free_text'] != "") {
        let reshapedDetail = {
          item: detail.item,
          comment: data['accessibility_free_text'],
          type: detail.type
        }
        data.details.push(reshapedDetail);
      }

      MyUtil.lodash.unset(data, detail.label);
      MyUtil.lodash.unset(data, detail.label + '_comment');
    });

  /*  //reshape data into details format
    MyUtil.lodash.forEach(this.pageData.bookingDetails.details, (detail) => {

      if (data[detail.label] == 1 || (detail.label == 'require_comments' && data['require_comments_comment'] != "")) {
        let reshapedDetail = {
          item: detail.item,
          comment: data[detail.label + '_comment'],
          type: detail.type
        }
        data.details.push(reshapedDetail);
      }

      if (detail.item == 5) {
        let reshapedDetail = {
          item: detail.item,
          comment: data[detail.label],
          type: detail.type
        }
        data.details.push(reshapedDetail);
      }

      if (detail.item == 999) {
        let reshapedDetail = {
          item: detail.item,
          comment: data[detail.label],
          type: detail.type
        }
        data.details.push(reshapedDetail);
      }

      MyUtil.lodash.unset(data, detail.label);
      MyUtil.lodash.unset(data, detail.label + '_comment');
    }); */

    MyUtil.lodash.unset(data, 'accessibility');

    this.dismiss(data);

  }

  cancel() {
    this.dismiss(null);
  }

  private dismiss(data: any) {
    this.viewCtrl.dismiss(data);
  }

  public getHumanLabel(text: string) {

    if (MyUtil.BOOKING_LABELS[text]) {
      return MyUtil.BOOKING_LABELS[text];
    } else {
      return text;
    }

  }


}
