import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getBrandName } from 'src/app/store/selectors/view.selector';
import * as appStore from '../../store';

@Component({
  selector: 'app-login-bottom',
  templateUrl: './login-bottom.component.html',
  styleUrls: ['./login-bottom.component.scss'],
})
export class LoginBottomComponent implements OnInit {

  // Get brand name from store.
  brandName$: Observable<string> = this.store.select(getBrandName);

  constructor(
    private store: Store<appStore.AppViewState>,
  ) { }

  ngOnInit() {}

}
