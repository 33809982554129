import { Component } from '@angular/core';

/**
 * Generated class for the SkillProgressBarComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'skill-progress-bar',
  templateUrl: 'skill-progress-bar.html',
  styleUrls: ['skill-progress-bar.scss'],
  inputs: ['value', 'target', 'interest']
})
export class SkillProgressBarComponent {

  value: number;
  target: number;
  interest: number;

  constructor() {}

}
