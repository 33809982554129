import {Component, Input, SimpleChange, SimpleChanges} from '@angular/core';
import { QuestionnaireQuestion } from 'src/app/providers/questionnaire-data/models';

/**
 * Generated class for the QuestionComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'question',
  templateUrl: 'question.html'
})
export class QuestionComponent {

  @Input('question') question: QuestionnaireQuestion;
  @Input('number') number: number;

  commentInputShown = false;
  scores = [1, 2, 3, 4, 5];

  constructor() {
    
  }

  ngOnChanges(changes: SimpleChanges) {
    const question: SimpleChange = changes.question;
    if (question.previousValue && (question.previousValue.id !== question.currentValue.id)) {
      this.commentInputShown = !!question.currentValue.comments;
    }
  }

  skillLevelChange(score) {
    this.question.skill_level = score;
  }

  interestChange(score) {
    this.question.interest = score;
  }

  showCommentInput() {
    this.commentInputShown = true;
  }

}
