import { Component, ElementRef, Input ,ViewChild} from '@angular/core';
import { NavController, NavParams } from '@ionic/angular';
import { Appcalc } from 'src/app/providers/appcalc';
import { SkillDetailsPage } from 'src/app/pages/skill-details/skill-details'
import { Router } from '@angular/router';

declare var app_branding: any;

@Component({
    selector: "course-progress-bar",
    template: `
    <div #container style= ' margin:auto; width:80%; '>
          <div #barContainer style=' margin-top:10%; border-radius: 10px; width:100%; height:15px; background-color:black'>
            <div class= "barValue" [ngStyle]= "setBarValueStyle()">
            <p text-center>{{value}}%</p>
            </div>
          </div>
      </div>

  
    `,
  })
export class CourseProgressBar {

  @Input('value') value: number;
  @ViewChild('barContainer') el:ElementRef;
  constructor(public element: ElementRef, public appcalc: Appcalc, public router: Router, public navParams: NavParams) {
    

  }

setBarValueStyle() {
  let styles = {
    'border-radius': 10+"px",
    'height': 15+"px",
    'width': this.value +"%",
    'background': "repeating-linear-gradient(45deg, rgb(2 136 38), rgb(2 136 38) 10px, rgb(42 88 54) 10px, rgb(30 90 47) 20px)",
    'min-width':30+"px"
  };
  return styles;
}



  

}
