import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { Appapi } from 'src/app/providers/appapi';
import { DesktopChecker } from 'src/app/providers/desktopChecker';
import { MyUtil } from "../../../libs/MyUtil";

/**
 * Generated class for the PathwaySuggestedPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

// @IonicPage()
@Component({
  selector: 'page-pathway-suggested',
  templateUrl: 'pathway-suggested.html',
})
export class PathwaySuggestedPage implements OnInit{

  paths: Array<any> = [];

  constructor(public router: Router, public navParams: NavParams, public appapi: Appapi, public desktopChecker: DesktopChecker) {

  }

  ngOnInit() {
    let loading = MyUtil.presentLoading();
    this.appapi.getCareerPathsMatchingRequirements()
        .then(
            async res => {
                this.paths = Object.keys(res['#data']).map(function(key) {
                    return res['#data'][key];
                });
                (await loading).dismiss();
            }
        )
        .catch(async err => {
          (await loading).dismiss();
        })
  }
}
