import { AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-walkthrough',
  templateUrl: './walkthrough.component.html',
  styleUrls: ['./walkthrough.component.scss'],
})
export class WalkthroughComponent implements OnInit, 
 AfterViewInit//, 
// AfterViewChecked, 
// AfterContentChecked, 
// AfterContentInit 
{


  constructor() { 
    //this.setFocusState();
  }

  /**
  * Click event emitters.
  */
  @Output() skipClicked = new EventEmitter();
  @Output() nextClicked = new EventEmitter();
  @Output() prevClicked = new EventEmitter();
  @Output() closeClicked = new EventEmitter();

  @ViewChild('skip') skip: MatButton;

  /** 
  * Inputs.
  */
  @Input() next = false;
  @Input() prev = false;
  @Input() close = false;

  ngOnInit() {
   // this.setFocusState();
  }


  /** 
  * Skip handler.
  */
  skipHandler() {
    this.skipClicked.emit();
  }

  /** 
  * Next handler.
  */
  nextHandler() {
    this.nextClicked.emit();
  }

  /** 
  * Prev handler.
  */
  prevHandler() {
    this.prevClicked.emit();
  }

  /** 
  * Close handler.
  */
  closeHandler() {
    this.skipClicked.emit();
  }

  // After view init.
  ngAfterViewInit() {
    //this.setFocusState();
  }
  // ngAfterContentChecked(){
  //   this.setFocusState();
  // }
  // ngAfterContentInit(){
  //   this.setFocusState();
  // }
  // ngAfterViewChecked() {
  //   this.setFocusState();
  // }

 // setFocusState(){
    // if (document.activeElement instanceof HTMLElement) {
    //   document.activeElement.blur();
    // }
   // setTimeout(()=>{
      //document.getElementById('skip').focus();
      //this.skip?.nativeElement.focus();
   // },2000);

 // }

}
