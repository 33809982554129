import * as Lodash from "lodash";
import { UntypedFormGroup } from "@angular/forms";

import { Device } from "@awesome-cordova-plugins/device/ngx";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import { SplashScreen } from "@awesome-cordova-plugins/splash-screen/ngx";
import { Network } from "@awesome-cordova-plugins/network/ngx";
import { File } from "@awesome-cordova-plugins/file/ngx";
import {
  InAppBrowser,
  InAppBrowserObject,
  InAppBrowserOptions,
} from "@awesome-cordova-plugins/in-app-browser/ngx";
import { Calendar, CalendarOptions } from "@awesome-cordova-plugins/calendar/ngx";
import { UUID } from "angular2-uuid";

import * as Moment from "moment";
import 'moment-timezone';

import { MyStringUtil } from "./MyStringUtil";
import { ActionSheetController, AlertController, LoadingController, ModalController, ModalOptions, Platform, PopoverController, ToastController } from "@ionic/angular";
import { EventsService } from "src/app/providers/events-service";

import { environment } from '../environments/environment';

import { ErrorAlertModalComponent } from '../app/components/error-alert-modal/error-alert-modal.component';

declare var appConfig: any;


interface IonicContext {
  platform: Platform;
  events: EventsService;
  actionSheetCtrl: ActionSheetController;
  alertCtrl: AlertController;
  loadingCtrl: LoadingController;
  modalCtrl: ModalController;
  popoverCtrl: PopoverController;
  toastCtrl: ToastController;
  device: Device;
  statusBar: StatusBar;
  splashScreen: SplashScreen;
  network: Network;
  inAppBrowser: InAppBrowser;
  calendar: Calendar;
  file: File;
}


export class MyUtil {
  static lodash: any = Lodash;
  static cache: any = {}; // in memory repository
  static ionic: IonicContext;
  static platform: Platform;
  static context: any;
  static EVENT: any = {
    APP_UNAUTHORIZED: "app:unauthorized",
    APP_PAUSE: "app:pause",
    APP_RESUME: "app:resume",
    APP_TOGGLE_HELP: "app:toggle-help",
    APP_SCROLL_TO_TOP: "app:scroll-to-top",
    APP_APPLY_FILTER: "app:apply_filter",
    APP_TO_QUESTIONNAIRE: "app:to_questionnaire",
    APP_TO_PROFILE: "app:to_profile",
    APP_TO_ONBOARDING: "app:onboarding",
  };
  static CONST: any = {
    DURATION_TOAST: 1500,
    DURATION_TOAST_LONG: 3000,
    REGREX_EMAIL: "[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,3}$",
    INPUT_MAX_LENGTH: 250,
    INPUT_MAX_DESC_LENGTH: 65525,
    APP_META: {
      GOAL_PRIORITY_MUST: 1,
      GOAL_PRIORITY_RECOMMENDED: 2,
      GOAL_PRIORITY_OPTIONAL: 3,
      GOAL_CALC_TYPE_ATTEND_COUNT: 1,
      GOAL_CALC_TYPE_TIME_EFFORT: 2,
      USER_GOAL_STATUS_ACTIVE: 1,
      USER_GOAL_STATUS_DONE: 2,
      USER_ACTIVITY_STATUS_ACTIVE: 1,
      USER_ACTIVITY_STATUS_DONE: 2,
      ATTEND_STATUS_UNKNOWN: 0,
      ATTEND_STATUS_VALID: 1,
      BOOKING_TYPE_NONE: 0,
      BOOKING_TYPE_INTERNAL: 1,
      BOOKING_TYPE_EXTERNAL: 2,
      ATTENDANCE_TYPE_NONE: 0,
      ATTENDANCE_TYPE_LIVE_ONLINE: 1,
      ATTENDANCE_TYPE_LIVE_IN_PERSON: 2,
      ATTENDANCE_TYPE_LIVE_SELF_STUDY: 3,
      ATTENDANCE_TYPE_HYBRID: 4,
    },
  };
  static DOC_ID: any = {
    APP_SETTINGS: "app_settings",
    APP_META: "app_meta",
    APP_PAGES: "app_pages",
    APP_UNIVERSITIES: "app_universities",
    APP_USER: "app_user",
    APP_HELP_STATUS: "app_help_status",
    USER_ORGANIZATIONS: "user_organizations",
    USER_SKILLS: "user_skills",
    USER_PROGRAMS: "user_programs",
    USER_PHASES: "user_phases",
    ACTIVITIES: "user_activities",
    ACTIVITY_TEMPLATES: "user_activity_templates",
    GOALS: "user_goals",
    USER_PROFILE: "user_profile",
    USER_FUNDING_ORGANIZATIONS: "user_funding_organizations",
    USER_FUNDING_SKILLS: "user_funding_skills",
    USER_FUNDING_PROGRAMS: "user_funding_programs",
    USER_FUNDING_PHASES: "user_funding_phases",
    FUNDING_ACTIVITIES: "user_funding_activities",
    USER_FUNDING_GOALS: "user_funding_goals",
    USER_PROFILE_CHART_INTERVAL: "user_profile_chart_interval",
    USER_PROFILE_CHART_ORG_ID: "user_profile_chart_org_id",
    USER_PROFILE_CHART_PROGRAM_ID: "user_profile_chart_program_id",
    ALL_FUNDING_ORGANIZATIONS: "all_funding_organizations",
    ALL_FUNDING_PROGRAMS: "all_funding_programs",
    ALL_FUNDING_PHASES: "all_funding_phases",
    REPEATING_GROUPS: "recurring_groups",
  };
  // doc type for query
  static DOC_TYPE: any = {
    USER_ACTIVITY: "user_activity",
    USER_GOAL: "user_goal",
    ACTIVITY_NOTE: "activity_note",
  };

  static HELP_ID: any = {
    WELCOME: "welcome",
    GOALS: "goals",
    GOAL_LIST: "goal-list",
    GOAL_DETAIL: "goal-detail",
    GOAL_EDIT: "goal-edit",
    ACTIVITIES: "activities",
    ACTIVITY_LIST: "activity-list",
    ACTIVITY_DETAIL: "activity-detail",
    ACTIVITY_COMPLETE: "activity-complete",
    ACTIVITY_EDIT: "activity-edit",
    USER: "user",
    PDP: "pdp",
    BADGES: "badges",
    SKILL_DETAILS: "skill-details",
    MORE: "more",
    FUNDING_LIST: "funding-list",
    MANAGED_FILE: "managed-file",
    REFLECTION: "reflection",
    REFLECTION_DETAILS: "reflection-details",
    DIAGNOSTIC_LIST: 'diagnostic-list'
  };

  static BOOKING_LABELS: any = {
    require_accommodation: "Do you require accommodation?",
    require_parking: "Do you require parking?",
    require_dietary: "Do you have any dietary requirements?",
    require_comments: "Do you have anything else to add?",
    require_wheelchair: "Do you require wheelchair access?",
    require_hearing: "Do you require hearing impairment aid?",
    require_accessible_parking: "Do you require accessible parking?",
    require_visual: "Do you require visual impairment aid?",
    accessibility_free_text: "Please specify any particular accessibility needs.",
  };

  static ATTENDANCE_TYPE_LABELS: any = {
    0: "Other",
    1: "Live (online)",
    2: "Live (in person)",
    3: "Self Study",
    4: "Hybrid (online/in person)",
  };

  static SKILL_TAG_TYPE_SKILL_ID = 1;
  static SKILL_TAG_TYPE_SKILL_NAME = "Skill";
  static SKILL_TAG_TYPE_ONBOARDING_ID = 2;
  static SKILL_TAG_TYPE_ONBOARDING_NAME = "Onboarding";
  static SKILL_TAG_TYPE_DIAGNOSTIC_ID = 3;
  static SKILL_TAG_TYPE_DIAGNOSTIC_NAME = "Diagnostic";

  static SKILL_TAG_TYPES: any = [
    {
      key: 1,
      value: "Skill"
    },
    {
      key: 2,
      value: "Onboarding"
    },
    {
      key: 3,
      value: "Diagnostic"
    }
  ];

  static CANCEL_BOOKINGS_LABELS: any = [
    {
      key: 1,
      value: "Illness",
    },
    {
      key: 2,
      value: "Research pressures",
    },
    {
      key: 3,
      value: "No longer relevant",
    },
    {
      key: 4,
      value: "Personal diary conflict",
    },
    {
      key: 5,
      value: "Caring responsibilities",
    },
    {
      key: 6,
      value: "Incompatible time zone",
    },
    {
      key: 7,
      value: "Other reason",
    },
  ];

  /* Console log utilities */
  static debug(obj: any): void {
    if (MyUtil.context.DEBUG) {
      let msg = MyStringUtil.shallowStringify(obj);
      //console.debug(msg);
    }
  }

  static info(obj: any): void {
    let msg = MyStringUtil.shallowStringify(obj);
    console.info(msg);
  }

  static error(obj: any): void {
    let msg = MyStringUtil.shallowStringify(obj);
    console.log(msg);
  }

  static getUnixTimeStamp(dt?: string): number {
    let dateTime = new Date().getTime();

    if (dt) {
      dateTime = new Date(dt).getTime();
    }

    let timestamp = Math.floor(dateTime / 1000);
    return timestamp;
  }

  static addHoursToTimestamp(hrs: number, date = new Date()): number {
    let dateTime = date.setTime(date.getTime() + hrs * 60 * 60 * 1000);

    return dateTime;
  }

  //TODO: 20/05/22 temporary fix for activity completion date (until dates/times sorted properly)
  static getUnixTimeStampWithoutAdjustment(dt?: string): number {
    let dateTime = new Date().getTime();

    if (dt) {
      dateTime = new Date(dt).getTime();
    }

    let timestamp = Math.floor(dateTime / 1000);
    return timestamp;
  }
  
  static getUnixTimeStampFromString(dt: string, format: string): number {
    let dateTime = Moment(dt, format).valueOf();

    let timestamp = Math.floor(dateTime / 1000);
    return timestamp;
  }

  static formatUnixTimeStampDate(ts, format?): string {
    let result = "";
    let tz= "UTC";

    if (ts) {
      let moment = MyUtil.getMoementFromUnixTimeStamp(ts);
      if(format) {
        result = moment.tz(tz).format("ddd "+format.toUpperCase());
      } else {
        result = moment.tz(tz).format("ddd DD/MM/YYYY");
      }
    }

    return result;
  }

  static calculateTimezoneAbbr(ts, tz): string {

    let abbr = "";
    
    let moment = MyUtil.getMoementFromUnixTimeStamp(ts);

    abbr = moment.clone().tz(tz).zoneAbbr();

    return abbr;

  }

  static formatUnixTimestampToTimezoneDate(ts, format, tz): string {

    let result = "";

    if(ts) {

      let moment = MyUtil.getMoementFromUnixTimeStamp(ts);

      let abbr = moment.clone().tz(tz).zoneAbbr();

      result = moment.clone().tz(tz).format("ddd "+format.toUpperCase()) + " " + abbr;

    }

    return result;

  }

  static formatPHPDate(dateFormat: string): string {
    
    switch (dateFormat) {
      case "d/m/Y":
        return "dd/mm/yyyy";
        break;
      case "m/d/Y":
        return "mm/dd/yyyy";
        break;
    
      default:
        return "dd/mm/yyyy";
        break;
    }

  }

  // Pipe date formatting uses a slightly different format for some reason
  static formatPHPDateForPipe(dateFormat: string): string {
    
    switch (dateFormat) {
      case "d/m/Y":
        return "dd/MM/yyyy";
        break;
      case "m/d/Y":
        return "MM/dd/yyyy";
        break;
    
      default:
        return "dd/MM/yyyy";
        break;
    }

  }

  static formatUnixTimeStamp2ISO(ts): string {
    let result = "";

    if (ts) {
      let dateTime = new Date(ts * 1000);
      result = dateTime.toISOString();
    }

    return result;
  }

  static formatHISTimeFormatToAMPM(time: string): string {
    if(time === "" || time === null) {
      return "00:00";
    }

    let fragments = time.split(":");
    let hours = Number(fragments[0]);
    let minutes = Number(fragments[1]);
    let AMPM = "";

    //AMPM = hours >= 12 ? "PM" : "AM";
    //hours = hours > 12 ? hours - 12 : hours;

    let sHours = hours >= 10 ? hours.toString() : "0" + hours.toString();
    let sMinutes =
      minutes >= 10 ? minutes.toString() : "0" + minutes.toString();

    return sHours + ":" + sMinutes; // + AMPM;
  }

  static getMoment(dt?: string): Moment.Moment {
    return Moment.unix(MyUtil.getUnixTimeStamp(dt));
  }

  static getMoementFromUnixTimeStamp(ts: number): Moment.Moment {
    return Moment.unix(ts);
  }

  static getLocalNowAsUTCTimeStamp(): number {
    let utcDatetimeString = MyUtil.getMoment().format("YYYY-MM-DD HH:mm:ss");
    let utcNow = Moment.utc(utcDatetimeString);

    return utcNow.unix();
  }

  /**
   * Works out if the activity overlaps with any other activity the user has booked (exluding self-study)
   * 
   * @param activity 
   * @param bookingStatusList 
   * @returns boolean
   */
  static getBookingClashStatus(activity: any, bookingStatusList: Array<any>) : boolean {

    let bookingClash = false;
    const attendanceTypeSelfStudy = 3;
    
    if(activity.attendance_type != attendanceTypeSelfStudy) {
      for(const key in bookingStatusList) {
        if(activity.id != key) {
          let bookingStatus = bookingStatusList[key];
          if(bookingStatus.start_timestamp > 0 
              && bookingStatus.end_timestamp > 0 
              && bookingStatus.attendance_type != attendanceTypeSelfStudy
              && (bookingStatus.is_booked || bookingStatus.on_waiting_list)) {

            if(activity.start_timestamp < bookingStatus.end_timestamp && activity.end_timestamp > bookingStatus.start_timestamp) {
              bookingClash = true;
              break;
            }
          }
        }
      }
    }

    return bookingClash;
  }


  /**
   * Returns a list of booked activities that clash with this one (exluding self-study)
   * 
   * @param activity 
   * @param bookingStatusList 
   * @returns boolean
   */
  static getClashingActivities(activity: any, bookingStatusList: Array<any>) : Array<any> {

    let clashingActivities = [];
    const attendanceTypeSelfStudy = 3;
    
    if(activity.attendance_type != attendanceTypeSelfStudy) {
      for(const key in bookingStatusList) {
        if(activity.id != key) {
          let bookingStatus = bookingStatusList[key];
          if(bookingStatus.start_timestamp > 0 
                && bookingStatus.end_timestamp > 0 
                && bookingStatus.attendance_type != attendanceTypeSelfStudy
                && (bookingStatus.is_booked || bookingStatus.on_waiting_list)) {

            if(activity.start_timestamp < bookingStatus.end_timestamp && activity.end_timestamp > bookingStatus.start_timestamp) {
              activity = this.getActivity(key);
              if(activity) {
                clashingActivities.push(activity);
              }
            }
          }
        }
      }
    }

    return clashingActivities;
  }


  static copyAllowedProperties(
    source: any,
    target: any,
    allowed: Array<string>
  ): void {
    if (source && target) {
      MyUtil.lodash.forEach(allowed, (key) => {
        if (source[key]) {
          //@TODO consider clone here
          target[key] = source[key];
        }
      });
    }
  }

  static responseToMessage = function (
    message: string,
    errors: any = [],
    separator: string = "; "
  ) {
    let result = MyUtil.errorsToMessage(errors, separator);

    if (message) {
      result = message + (result ? separator + result : "");
    }

    return result;
  };

  static errorsToMessage = function (errors, separator: string = "; ") {
    let message = [];

    MyUtil.lodash.forEach(errors, function (value) {
      if (value) {
        if (MyUtil.lodash.isObject(value)) {
          message.push(MyUtil.errorsToMessage(value, separator));
        } else {
          message.push(value);
        }
      }
    });

    return message.join(separator);
  };

  static checkFormUnsavedChanges(
    form: UntypedFormGroup,
    resetValues?: any
  ): Promise<any> {
    if (!form.dirty) {
      return Promise.resolve("form unchanged");
    }

    return new Promise((resolve, reject) => {
      MyUtil.presentAlert({
        title: "Unsaved Changes",
        message:
          "Are you sure you want to continue without saving your changes?",
        buttons: [
          {
            text: "Cancel",
            handler: () => {
              reject("keep changes");
            },
          },
          {
            text: "Confirm",
            handler: () => {
              if (MyUtil.lodash.isObject(resetValues)) {
                form.reset(resetValues);
              }
              resolve("gave up form changes");
            },
          },
        ],
      });
    });
  }

  static getFormValidationErrors(
    form: UntypedFormGroup,
    messages?: { [key: string]: string }
  ): { [key: string]: any } {
    let result: { [key: string]: any } = null;

    if (form) {
      result = {};
      for (let controlKey in form.controls) {
        let control = form.get(controlKey);
        if (control && !control.valid) {
          result[controlKey] = {};
          for (let errorKey in control.errors) {
            if (messages && messages[controlKey + "." + errorKey]) {
              result[controlKey][errorKey] =
                messages[controlKey + "." + errorKey];
            } else if (messages && messages[controlKey]) {
              result[controlKey][errorKey] = messages[controlKey];
            } else {
              result[controlKey][errorKey] = control.errors[errorKey];
            }
          }
        }
      }
    }

    return result;
  }

  /* Device utilities */
  static initializeApp(ionicContext: IonicContext): Promise<void> {
    MyUtil.ionic = ionicContext;
    return MyUtil.ionic.platform.ready().then(() => {
      MyUtil.context = {
        initialized: false,
        isDevice: MyUtil.ionic.platform.is("cordova"),
        isIOS: MyUtil.ionic.platform.is("ios"),
        isAndroid: MyUtil.ionic.platform.is("android"),
        DEBUG: environment.DEBUG,
        APP_VERSION: environment.APP_VERSION,
        API_SERVER_URL: environment.API_SERVER_URL,
        API_PATH: environment.API_PATH,
        RSC_URL: environment.RSC_URL,
        RSC_AUTH_TOKEN: environment.RSC_AUTH_TOKEN,
        RSC_RESET_URL: environment.RSC_RESET_URL,
        API_TIMEOUT: 300000, //@TODO adjust timeout
      };

      if (MyUtil.context.isDevice) {
        MyUtil.debug("initialize context: device");

        // prepare device info
        MyUtil.context.device = {
          cordova: MyUtil.ionic.device.cordova,
          model: MyUtil.ionic.device.model,
          platform: MyUtil.ionic.device.platform,
          uuid: MyUtil.ionic.device.uuid,
          version: MyUtil.ionic.device.version,
          manufacturer: MyUtil.ionic.device.manufacturer,
          isVirtual: MyUtil.ionic.device.isVirtual,
          serial: MyUtil.ionic.device.serial,
        };

        // initialize StatusBar
        MyUtil.ionic.statusBar.styleBlackTranslucent();

        // initialize SplashScreen
        MyUtil.ionic.splashScreen.hide();

        // listen to events
        MyUtil.ionic.platform.pause.subscribe(() => {
          MyUtil.publishEvent(MyUtil.EVENT.APP_PAUSE);
        });

        MyUtil.ionic.platform.resume.subscribe(() => {
          MyUtil.publishEvent(MyUtil.EVENT.APP_RESUME);
        });
      } else {
        MyUtil.debug("initialize context: browser");

        // prepare device info
        MyUtil.context.device = {
          cordova: navigator
            ? navigator.appCodeName + " " + navigator.appName
            : "n/a",
          model: navigator ? navigator.userAgent : "n/a",
          platform: navigator ? navigator.platform : "n/a",
          version: navigator ? navigator.appVersion : "n/a",
          manufacturer: navigator
            ? navigator.vendor + " " + navigator.vendorSub
            : "n/a",
          isVirtual: navigator ? navigator.cookieEnabled : false,
          serial: navigator
            ? navigator.product + " " + navigator.productSub
            : "n/a",
        };

        let uuid = null;
        if (typeof window.localStorage !== "undefined") {
          // use existing if available
          let key = environment.APP_DB_NAME + "_uuid";
          uuid = window.localStorage.getItem(key);
          if (!uuid) {
            // generate and store
            uuid = UUID.UUID();
            window.localStorage.setItem(key, uuid);
          }
        } else {
          // generate
          uuid = UUID.UUID();
        }
        MyUtil.context.device.uuid = uuid;
      }

      MyUtil.context.initialized = true;

      MyUtil.debug(MyUtil.context);

      MyUtil.debug("initialized context");
    });
  }

  static appRequiresUpgrade() {

    let breakingVersion = MyUtil.cache[MyUtil.DOC_ID.APP_SETTINGS]["MOBILE_APP_BREAKING_VERSION"];
    if(!breakingVersion) {
      return false;
    }
    
    breakingVersion = breakingVersion.replace(/\./g,'');
    let breakingVersionNumber = Number(breakingVersion);
    if(!breakingVersionNumber) {
      return false;
    }

    // remove production text and '.' from version
    let appVersion = environment.APP_VERSION;
    appVersion = appVersion.replace('Production', '');
    appVersion = appVersion.replace('x', '');
    appVersion = appVersion.replace(/\./g,'');
    let appVersionNumber = Number(appVersion);
    if(!appVersionNumber) {
      return false;
    }
    
    return appVersionNumber < breakingVersionNumber;
  }


  static getUpgradeMessage() {

    if(this.appRequiresUpgrade()) {

      let msg = '';
      if(MyUtil.ionic.platform.is("cordova")) {
        // force an update
        let button = '';
        if(MyUtil.ionic.platform.is("ios")){
          button = "<a class='button tac' target='_blank' href='https://apps.apple.com/gb/app/inkpath/id1186232199'>Download for IOS</a>" 
        }
        
        if(MyUtil.ionic.platform.is("android")){
          button = "<a class='button tac' target='_blank' href='https://play.google.com/store/apps/details?id=uk.co.inkpath.app&hl=en&gl=US'>Download for Android</a>" 
        }

        // mobile app message
        msg = `
          <p class='mb15 tac'>
            There has been an update to the Inkpath service which requires you to use the latest version.
          </p>
          <p class='mb15 tac'>
            Please upgrade to the latest version in the app store. If the app store does not yet offer a newer version, you can use the web version (on any device)
            <a href="https://webapp.inkpath.co.uk">https://webapp.inkpath.co.uk</a> until the update is available. 
          </p>
          <p class='mb15 tac'>
            You may need to uninstall and reinstall the app. You will not lose any data.
          </p>
          <p class='tac'>
            ${button} 
          </p>`;

      } else {
        // web app message
        msg = `
          <p class='mb15 tac'>
            There has been an update to the Inkpath service which requires you to use the latest version.
          </p>
          <p class='mb15 tac'>
            Please refresh this page to reload Inkpath. You may need to perform a hard refresh: Ctrl+F5 (most Windows browsers) or CMD+Shift+R (Mac).
          </p>
          <p class='mb15 tac'>
           If you receive this message again, please wait 15 minutes and retry.
          </p>
          `;
      }

      return msg;
    }

    return null;
  };

  static getWindowSize = function () {
    return {
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
    };
  };

  static getNetwork = function () {
    let result: any = {};
    if (MyUtil.context.isDevice) {
      result.type = MyUtil.ionic.network.type; // unknown, ethernet, wifi, 2g, 3g, 4g, cellular, none
    } else {
      result.type = "web";
    }
    return result;
  };

  static isNetworkCellular() {
    let network = MyUtil.getNetwork();
    if (["2g", "3g", "4g", "cellular"].indexOf(network.type) !== -1) {
      return true;
    }

    return false;
  }

  static isNetworkWifi() {
    let network = MyUtil.getNetwork();
    if (["ethernet", "wifi", "web"].indexOf(network.type) !== -1) {
      return true;
    }

    return false;
  }

  static isNetworkConnected() {
    let network = MyUtil.getNetwork();
    if (
      ["ethernet", "wifi", "2g", "3g", "4g", "cellular", "web"].indexOf(
        network.type
      ) !== -1
    ) {
      return true;
    }

    return false;
  }

  /**
   * check whether app is running in mobile app mode
   */
  static isMobileAppMode() {
    let platform = MyUtil.ionic.platform;
    if (
      platform &&
      platform.is("cordova") &&
      (platform.is("android") || platform.is("ios"))
    ) {
      return true;
    }
    return false;
  }

  static newUUID(): string {
    return UUID.UUID();
  }

  static firebaseSetUserId = function (id) {
    MyUtil.debug("Firebase set user id: " + id);
    if (MyUtil.context.isDevice) {
      if ((<any>window).FirebasePlugin) {
        (<any>window).FirebasePlugin.setUserId(id);
      }
    }
  };

  static firebaseSetUserProperty = function (name, value) {
    MyUtil.debug("Firebase set user property " + name + ": " + value);
    if (MyUtil.context.isDevice) {
      if ((<any>window).FirebasePlugin) {
        (<any>window).FirebasePlugin.setUserProperty(name, value);
      }
    }
  };

  static firebaseSetScreenName = function (name) {
    MyUtil.debug("Firebase set screen name: " + name);
    if (MyUtil.context.isDevice) {
      if ((<any>window).FirebasePlugin) {
        (<any>window).FirebasePlugin.setScreenName(name);
      }
    }
  };

  static firebaseLogEvent = function (name, value) {
    MyUtil.debug(["Firebase log event", name, value]);
    if (MyUtil.context.isDevice) {
      if ((<any>window).FirebasePlugin) {
        (<any>window).FirebasePlugin.logEvent(name, value);
      }
    }
  };

  static getFilePathOnDevice(): string {
    if (MyUtil.context.isDevice) {
      return MyUtil.ionic.file.applicationDirectory;
    } else {
      return "";
    }
  }

  // static scanQr(options: BarcodeScannerOptions): Promise<any> {
  //   if (MyUtil.context.isDevice) {
  //     return Promise.reject("app mode");
  //     //return MyUtil.ionic.barcodeScanner.scan(options);
  //   } else {
  //     return Promise.reject("web mode");
  //   }
  // }

  static saveToLocalStorage(key: string, value: any) {
    if (typeof window.localStorage !== "undefined") {
      window.localStorage.setItem(key, value);
      return value;
    }
    return null;
  }

  static retrieveFromLocalStorage(key: string) {
    if (typeof window.localStorage !== "undefined") {
      return window.localStorage.getItem(key);
    }
    return null;
  }

  static removeFromLocalStorage(key: string) {
    let value = MyUtil.retrieveFromLocalStorage(key);
    if (typeof window.localStorage !== "undefined") {
      window.localStorage.removeItem(key);
      return null;
    }
    return value;
  }

  /* Ionic utilities */
  static subscribeEvent(topic: string, handler: any): void {
    MyUtil.debug("subscribe event: " + topic);
    MyUtil.ionic.events.sendMessage({topic, handler});
  }

 
  static unsubscribeEvent(topic: string, handler?: any) {
    MyUtil.debug("unsubscribe event: " + topic);
    //return MyUtil.ionic.events.unsubscribe(topic, handler);
  }
  
  static publishEvent(topic: string, eventData: any = null) {
    MyUtil.debug(["publish event: " + topic, eventData]);
    MyUtil.ionic.events.sendMessage({topic, eventData});
  }

  static async presentAlert(options: any = {}): Promise<any> {
    let finalOptions: any = {
      enableBackdropDismiss: false,
    };

    if (!MyUtil.lodash.isEmpty(options)) {
      MyUtil.lodash.merge(finalOptions, options);
    }

    let alert = MyUtil.ionic.alertCtrl.create(finalOptions);

    (await alert).present().catch((err) => {
      MyUtil.error(err);
    });

    return alert;
  }

  static async presentLoading(options: any = {}) {
    let finalOptions: any = {
      content: "Please wait ...",
    };

    if (!MyUtil.lodash.isEmpty(options)) {
      MyUtil.lodash.merge(finalOptions, options);
    }

    let loading = MyUtil.ionic.loadingCtrl.create(finalOptions);

    if (options && options.onDidDismiss) {
      (await loading).onDidDismiss(
      //   () => {
      //   MyUtil.debug("Dismissed loading");
      //   options.onDidDismiss();
      // }
      );
    }

    (await loading).present().catch((err) => {
      MyUtil.error(err);
    });

    return loading;
  }

 
  // static createModal(component: any, data?: any, opts?: ModalOptions): any {
  //   return MyUtil.ionic.modalCtrl.create({component, componentProps:data});
  // }

  static async createModal(component, data, backdropDismiss = true) {
    const modal = await MyUtil.ionic.modalCtrl.create({
      component: component,
      cssClass: 'inkpath-modal',
      componentProps: data,
      backdropDismiss: backdropDismiss
    });
    return await modal;
  }


  static createInAppBrowser(
    url: string,
    target?: string,
    options?: string | InAppBrowserOptions
  ): InAppBrowserObject {
    return MyUtil.ionic.inAppBrowser.create(url, target, options);
  }

  static calendarCreateEvent(
    title?: string,
    location?: string,
    notes?: string,
    startDate?: Date,
    endDate?: Date
  ): Promise<any> {
    return MyUtil.ionic.calendar.createEvent(
      title,
      location,
      notes,
      startDate,
      endDate
    );
  }

  static calendarCreateEventWithOptions(
    title?: string,
    location?: string,
    notes?: string,
    startDate?: Date,
    endDate?: Date,
    options?: CalendarOptions
  ): Promise<any> {
    return MyUtil.ionic.calendar.createEventWithOptions(
      title,
      location,
      notes,
      startDate,
      endDate,
      options
    );
  }

  static calendarGetOptions(): CalendarOptions {
    return MyUtil.ionic.calendar.getCalendarOptions();
  }

  static async presentToast(message: any, options: any = null) {
    let finalOptions: any = {
      message: message,
      duration: MyUtil.CONST.DURATION_TOAST,
      position: "middle",
      cssClass: 'inkpath-toast',
      closeButtonText: "X",
      dismissOnPageChange: false,
      showCloseButton: true,
    };

    if (!MyUtil.lodash.isEmpty(options)) {
      if (options.cssClass === "error") {
        finalOptions.duration = MyUtil.CONST.DURATION_TOAST_LONG;
      }
      MyUtil.lodash.merge(finalOptions, options);
    }

    let toast = MyUtil.ionic.toastCtrl.create(finalOptions);

   (await toast).present().catch((err) => {
      MyUtil.error(err);
    });

    return toast;
  }

  // App specific helper

  static getAppOrg(oid: number) {
    let organizations = MyUtil.cache[MyUtil.DOC_ID.APP_UNIVERSITIES];
    let org = organizations[oid];

    let result = [oid];
    if (org) {
      result = [org];
    } else {
      MyUtil.error("Org not found: " + oid);
      return [null];
    }

    while (org && org.oid) {
      let oid = org.oid;
      org = organizations[oid];
      if (org) {
        result.push(org);
      } else {
        MyUtil.error("Not found org: " + oid);
      }
    }

    return MyUtil.lodash.cloneDeep(result);
  }

  /**
   * get root organization of certain oid
   * default oid is implied as user profile rootOid
   * If we already know the rootOid, no need to get the whole chain
   */
  static getRootOrganization(oid?: string, searchChain: boolean = false) {

    let rootOrg = null;
    if(!searchChain) {

      let rootOid = null;

      //If no root oid supplied, get it from the profile
      if (oid) {
        rootOid = oid;
      } else {
        let profile = MyUtil.getProfile();
        if (profile) {
          rootOid = profile.root_oid;
        }
      }

      if(rootOid) {
        let organizations = MyUtil.cache[MyUtil.DOC_ID.USER_ORGANIZATIONS];
        rootOrg = organizations[rootOid];

        //If not found try the user's funding cache
        if (!rootOrg) {
          organizations = MyUtil.cache[MyUtil.DOC_ID.USER_FUNDING_ORGANIZATIONS];
          if(organizations !== undefined) {
            rootOrg = organizations[rootOid];
          }
        }

        //If not found try the the full funding cache
        if (!rootOrg) {
          organizations = MyUtil.cache[MyUtil.DOC_ID.ALL_FUNDING_ORGANIZATIONS];
          if(organizations !== undefined) {
            rootOrg = organizations[rootOid];
          }
        }
      }
    } else {
      //Still need to search chain for activity org etc, as the API isn't returning the activity root_oid yet
      //TODO: Sort this when we do the back-end refactor
      if(oid) {
        let chain = MyUtil.getOrgnizationChain(oid);
        rootOrg = chain[chain.length - 1];
      }
    }
    
    return rootOrg;
  }


  /**
   * Get all organisations that have shared content with the home org.
   * These are any orgs that have no parent but aren't the home org.
   */
  static getContentSharingOrganizations() {
    let rootOid = null;
    let result = [];
    let allOrganizations = MyUtil.cache[MyUtil.DOC_ID.USER_ORGANIZATIONS];

    let profile = MyUtil.getProfile();
    if (profile) {
      rootOid = profile.root_oid;
    }

    if(rootOid) {
      Object.values(allOrganizations).forEach(org => {
        if(!org['oid'] && org['id'] != rootOid) {
          result.push(org);
        }
      });
    }

    return result;
  }


  /**
   * get organization chain of cerntain oid
   * default oid is user profile oid
   */
  static getOrgnizationChain(oid?: string) {
    // oid in user profile as default
    if (!oid) {
      let profile = MyUtil.getProfile();
      if (profile) {
        oid = profile.oid;
      }
    }

    let organizations = MyUtil.cache[MyUtil.DOC_ID.USER_ORGANIZATIONS];
    let org = organizations[oid];

    // not found try the user's funding cache
    if (!org) {
      organizations = MyUtil.cache[MyUtil.DOC_ID.USER_FUNDING_ORGANIZATIONS];
      if(organizations !== undefined) {
        org = organizations[oid];
      }
    }

    // not found try the the full funding cache
    if (!org) {
      organizations = MyUtil.cache[MyUtil.DOC_ID.ALL_FUNDING_ORGANIZATIONS];
      if(organizations !== undefined) {
        org = organizations[oid];
      }
    }

    let result = [oid];
    if (org) {
      result = [org];
    } else {
      MyUtil.error("Org not found: " + oid);
      return [null];
    }

    while (org && org.oid) {
      let oid = org.oid;
      org = organizations[oid];
      if (org) {
        result.push(org);
      } else {
        MyUtil.error("Not found org: " + oid);
      }
    }

    return MyUtil.lodash.cloneDeep(result);
  }

  


  /**
   * get organization chain name of cerntain oid
   * default oid is user profile oid
   */
  static getOrgnizationChainName(oid?: string, separator: string = ", ") {
    // oid in user profile as default
    if (!oid) {
      let profile = MyUtil.getProfile();
      oid = profile.oid;
    }
    
    let organizations = MyUtil.cache[MyUtil.DOC_ID.USER_ORGANIZATIONS];
    let org = organizations[oid];

    // not found try funding cache
    if (!org) {
      organizations = MyUtil.cache[MyUtil.DOC_ID.USER_FUNDING_ORGANIZATIONS];
      if(organizations !== undefined) {
        org = organizations[oid];
      }
    }

    // not found try all funding cache
    if (!org) {
      organizations = MyUtil.cache[MyUtil.DOC_ID.ALL_FUNDING_ORGANIZATIONS];
      if(organizations !== undefined) {
        org = organizations[oid];
      }
    }

    let result = [oid];
    if (org) {
      result = [org.name];
    } else {
      MyUtil.error("Not found org: " + oid);
    }

    while (org && org.oid) {
      let oid = org.oid;
      org = organizations[oid];
      if (org) {
        result.push(org.name);
      } else {
        MyUtil.error("Not found org: " + oid);
      }
    }

    return result.join(separator);
  }

  /**
   * get the attendance types in the correct format for activity filter
   */
  static getAttendanceTypeChain() {
    let returnAttObj = [];
    MyUtil.lodash.forEach(MyUtil.ATTENDANCE_TYPE_LABELS, (element, index) => {
      let innerObj = {
        key: index,
        value: element,
        hidden: true,
      };
      returnAttObj.push(innerObj);
    });

    // move Other to last
    let first = returnAttObj.shift();
    returnAttObj.push(first);

    return returnAttObj;
  }

  /**
   * get user current active profile
   */
  static getProfile() {
    return MyUtil.cache[MyUtil.DOC_ID.USER_PROFILE];
  }

  /**
   * validate profile is completed
   */
  static validateProfile(): any {
    let result = null;

    let profile = MyUtil.getProfile();
    if(!profile){
      return false;
    }
    if (
      profile.first_name &&
      profile.program_id &&
      profile.phase_id &&
      profile.oid
    ) {
      let orgMap = MyUtil.cache[MyUtil.DOC_ID.USER_ORGANIZATIONS];
      if (orgMap && orgMap[profile.oid] && orgMap[profile.oid].children) {
        // not select the bottom org
      } else {
        // profile valid
        result = profile;
      }
    }

    return result;
  }

  /**
   * get all program ids attached to the user profile
   */
  static getProfileProgramIds() {
    let profile = MyUtil.getProfile();
    let ids = [];
    if (profile && profile.fundings && profile.fundings.length > 0) {
      profile.fundings.forEach(funding => {
        ids.push(funding.program_id);
      });
    }

    ids.push(profile?.program_id);
    return ids;
  }


  /**
   * get all phase ids attached to the user profile
   */
  static getProfilePhaseIds() {
    let profile = MyUtil.getProfile();
    let ids = [];
    if (profile && profile.fundings && profile.fundings.length > 0) {
      profile.fundings.forEach(funding => {
        ids.push(funding.phase_id);
      });
    }

    ids.push(profile?.phase_id);
    return ids;
  }


  /**
   * get all skills belonging to certain oid
   * default oid is implied as user profile oid
   */
  static getSkillsWithoutProgramFiltering(oid?: any) {
    //TODO: change to pass in root_oid when back-end API refactor sorted
    let rootOrg = null;
    if(oid) {
      rootOrg = MyUtil.getRootOrganization(oid, true);
    } else {
      rootOrg = MyUtil.getRootOrganization();
    }

    let skills = [];
    if (rootOrg) {
      skills = MyUtil.lodash
        .chain(MyUtil.cache[MyUtil.DOC_ID.USER_SKILLS])
        .cloneDeep()
        .merge(MyUtil.cache[MyUtil.DOC_ID.USER_FUNDING_SKILLS])
        .filter((item) => {
          return (item.oid == rootOrg.id);
        })
        .keyBy("id")
        .value();
    }
    return skills;
  }

  /**
   * get skills belong to cerntain oid
   * default oid is implied as user profile oid
   */
  static getSkills(oid?: string) {
    //TODO: change to pass in root_oid when back-end API refactor sorted
    let rootOrg = null;
    if(oid) {
      rootOrg = MyUtil.getRootOrganization(oid, true);
    } else {
      rootOrg = MyUtil.getRootOrganization();
    }

    let programIds = MyUtil.getProfileProgramIds();
    let skills = MyUtil.lodash
      .chain(MyUtil.cache[MyUtil.DOC_ID.USER_SKILLS])
      .cloneDeep()
      .merge(MyUtil.cache[MyUtil.DOC_ID.USER_FUNDING_SKILLS])
      .filter((item) => {
        // return (item.oid == rootOrg.id);
        return (item.oid == rootOrg.id || (!item.programs || item.programs.some(el => programIds.indexOf(el) >= 0) || item.programs.length === 0));
      })
      .keyBy("id")
      .value();
    return skills;
  }


  static getUserPrograms(oid?: string) {
    //TODO: change to pass in root_oid when back-end API refactor sorted
    let rootOrg = null;
    if(oid) {
      rootOrg = MyUtil.getRootOrganization(oid, true);
    } else {
      rootOrg = MyUtil.getRootOrganization();
    }
    
    let programs = MyUtil.lodash
      .chain(MyUtil.cache[MyUtil.DOC_ID.USER_PROGRAMS])
      .cloneDeep()
      .filter((item) => {
        if(!item){
          return;
        }
        return (item.oid == rootOrg.id);
      })
      .keyBy("id")
      .value();
    return programs;
  }


  /**
   * get programs belong to certain oid
   * default oid is implied as user profile oid
   */
  static getPrograms(oid?: string) {
    //TODO: change to pass in root_oid when back-end API refactor sorted
    let rootOrg = null;
    if(oid) {
      rootOrg = MyUtil.getRootOrganization(oid, true);
    } else {
      rootOrg = MyUtil.getRootOrganization();
    }

    let funding = MyUtil.lodash.cloneDeep(MyUtil.getProfile()).fundings;
    let fundingProgramIds = [];

    if (funding) {
      funding.forEach(element => {
        if (!fundingProgramIds.includes(element.program_id)) {
          fundingProgramIds.push(element.program_id);
        }
      });
    }

    let programs = MyUtil.lodash
      .chain(MyUtil.cache[MyUtil.DOC_ID.USER_PROGRAMS])
      .cloneDeep()
      .merge(MyUtil.cache[MyUtil.DOC_ID.USER_FUNDING_PROGRAMS])
      .filter((item) => {
        return (item.oid == rootOrg.id) || (fundingProgramIds.includes(item.id));
      })
      .keyBy("id")
      .value();
    return programs;
  }

  /**
   * get phases belong to cerntain oid
   * default oid is implied as user profile oid
   */
  static getPhases(oid?: string) {
    //TODO: change to pass in root_oid when back-end API refactor sorted
    let rootOrg = null;
    if(oid) {
      rootOrg = MyUtil.getRootOrganization(oid, true);
    } else {
      rootOrg = MyUtil.getRootOrganization();
    }
    
    let phases = MyUtil.lodash
      .chain(MyUtil.cache[MyUtil.DOC_ID.USER_PHASES])
      .cloneDeep()
      .merge(MyUtil.cache[MyUtil.DOC_ID.USER_FUNDING_PHASES])
      .filter((item) => {
        return item.oid == rootOrg.id;
      })
      .keyBy("id")
      .value();
    return phases;
  }

  /**
   * get fundings for user
   */
  static getUserFundings() {
    return MyUtil.lodash
      .chain(MyUtil.cache[MyUtil.DOC_ID.USER_FUNDING_ORGANIZATIONS])
      .filter((item: any) => {
        return item.oid == null && item.funding_source == 1;
      })
      .keyBy("id")
      .value();
  }

  /**
   * get fundings for user
   */
   static getAllFundings() {
    return MyUtil.lodash
      .chain(MyUtil.cache[MyUtil.DOC_ID.ALL_FUNDING_ORGANIZATIONS])
      .filter((item: any) => {
        return item.oid == null && item.funding_source == 1;
      })
      .keyBy("id")
      .value();
  }

  /**
   * get activity
   */
  static getActivity(id: string) {
    let result = MyUtil.cache[MyUtil.DOC_ID.ACTIVITIES]
      ? MyUtil.cache[MyUtil.DOC_ID.ACTIVITIES][id]
      : null;
    if (!result) {
      result = MyUtil.cache[MyUtil.DOC_ID.FUNDING_ACTIVITIES]
        ? MyUtil.cache[MyUtil.DOC_ID.FUNDING_ACTIVITIES][id]
        : null;
    }
    return result;
  }

  /**
   * get skill
   */
  static getSkill(id: string) {
    let result = MyUtil.cache[MyUtil.DOC_ID.USER_SKILLS]
      ? MyUtil.cache[MyUtil.DOC_ID.USER_SKILLS][id]
      : null;
    if (!result) {
      result = MyUtil.cache[MyUtil.DOC_ID.USER_FUNDING_SKILLS]
        ? MyUtil.cache[MyUtil.DOC_ID.USER_FUNDING_SKILLS][id]
        : null;
    }

    var array = this.getSkills();

    function* values(obj) {
        for (let prop of Object.keys(obj))
            yield obj[prop];
    }

    let arr = Array.from(values(array));

    if (result && arr.find(el => el.id === result.id)) {
      return result;
    } else {
      return false;
    }
  }

  /**
   * get skill without program filtering
   */
  static getSkillWithoutProgramFiltering(id: string) {
    let result = MyUtil.cache[MyUtil.DOC_ID.USER_SKILLS]
      ? MyUtil.cache[MyUtil.DOC_ID.USER_SKILLS][id]
      : null;
    if (!result) {
      result = MyUtil.cache[MyUtil.DOC_ID.USER_FUNDING_SKILLS]
        ? MyUtil.cache[MyUtil.DOC_ID.USER_FUNDING_SKILLS][id]
        : null;
    }

    return result;
  }

  /**
   * get goal
   */
  static getGoal(id: string) {
    let result = MyUtil.cache[MyUtil.DOC_ID.GOALS]
      ? MyUtil.cache[MyUtil.DOC_ID.GOALS][id]
      : null;
    if (!result) {
      result = MyUtil.cache[MyUtil.DOC_ID.USER_FUNDING_GOALS]
        ? MyUtil.cache[MyUtil.DOC_ID.USER_FUNDING_GOALS][id]
        : null;
    }
    return result;
  }

  /**
   * get activity templates
   */
  static getActivityTemplates() {
    let tempaltes = MyUtil.lodash
      .chain(MyUtil.cache[MyUtil.DOC_ID.ACTIVITY_TEMPLATES])
      .keyBy("id")
      .value();
    return tempaltes;
  }

  /**
   * get activity template
   */
  static getActivityTemplate(id: string) {
    let result = MyUtil.cache[MyUtil.DOC_ID.ACTIVITY_TEMPLATES]
      ? MyUtil.cache[MyUtil.DOC_ID.ACTIVITY_TEMPLATES][id]
      : null;
    return result;
  }

  /**
   * get recurring group
   */
  static getRecurringGroup(id: string) {
    let result = MyUtil.cache[MyUtil.DOC_ID.REPEATING_GROUPS]
      ? MyUtil.cache[MyUtil.DOC_ID.REPEATING_GROUPS][id]
      : null;
    return result;
  }

  /**
   * get goals referred to specific activity id
   */
  static getGoalsReferredActivityId(id: string) {
    let result = MyUtil.lodash
      .chain(MyUtil.cache[MyUtil.DOC_ID.GOALS])
      .filter((goal) => {
        return goal && goal.activities && goal.activities.indexOf(id) !== -1;
      })
      .value();
    return result;
  }

  /**
   * turn a unix timestamp representing a date, and a string representing the time ("12:25:33" / "12:25") into a JS datetime
   * @param datestamp
   * @param time
   */
  static createDate(datestamp, time: string) {
    let date = new Date(datestamp);
    let parts = time.split(":");

    // Set seconds part to "00" if the time string does not specify seconds (e.g. "12:25")
    parts[2] = parts[2] ? parts[2] : "00";

    date.setHours(parseInt(parts[0], 10));
    date.setMinutes(parseInt(parts[1], 10));
    date.setSeconds(parseInt(parts[2], 10));
    return date;
  }

  static async addOneTrustTags() {
    
    // OneTrust scripts
    var oneTrustAutoblock = document.getElementById("rsc-onetrust-autoblock");
    oneTrustAutoblock.setAttribute("type", "text/javascript");
    oneTrustAutoblock.setAttribute("src", "https://cdn-ukwest.onetrust.com/consent/e8a773f1-a4eb-4312-b1b9-72426df64821/OtAutoBlock.js");
    oneTrustAutoblock.setAttribute("async", "true");

    var onetrustOptanon = document.getElementById("rsc-onetrust-optanon");
    onetrustOptanon.setAttribute("type", "text/javascript");
    onetrustOptanon.setAttribute("src", "https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js");
    onetrustOptanon.setAttribute("charset", "UTF-8");
    onetrustOptanon.setAttribute("data-domain-script", "e8a773f1-a4eb-4312-b1b9-72426df64821");
    onetrustOptanon.setAttribute("async", "true");

    var optanonWrapper = document.getElementById("rsc-optanon-wrapper");
    optanonWrapper.textContent = "function OptanonWrapper() { }";

  }

  static async addRSCTags() {

    // GTM scripts
    var gtmNew = document.getElementById("rsc-gtm-new");
    
    gtmNew.textContent = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-TPNS26X');";
    
    var gtmNSNew = document.getElementById("rsc-gtm-noscript-new");
    gtmNSNew.textContent = "<iframe src='https://www.googletagmanager.com/ns.html?id=GTM-TPNS26X' height='0' width='0' style='display:none;visibility:hidden'></iframe>";

    var gtm = document.getElementById("rsc-gtm");
    gtm.textContent = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.id='rsc-gtm-tag';j.async=true;j.src='https://analytics.rsc.org/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-TPNS26X');";
    
    var gtmNS = document.getElementById("rsc-gtm-noscript");
    gtmNS.textContent = "<iframe src='https://analytics.rsc.org/ns.html?id=GTM-TPNS26X' height='0' width='0' style='display:none;visibility:hidden'></iframe>";
    
    
    // Hotjar scripts
    var hotJarScript = document.getElementById("rsc-hotjar");
    hotJarScript.setAttribute("type", "text/plain");
    hotJarScript.setAttribute("class", "optanon-category-C0002");
    hotJarScript.textContent = "(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:3155238,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');";
    

  }

  
  /**
   * Remove RSC tags from index.html when not RSC branded portal
   */
  static async removeRSCTags() {
    
    var oneTrustAutoblock = document.getElementById("rsc-onetrust-autoblock");
    var oneTrustOptanon = document.getElementById("rsc-onetrust-optanon");
    var oneTrustOptanonWrapper = document.getElementById("rsc-optanon-wrapper");
    var tag = document.querySelector('script[src*="otBannerSdk.js"]');
    var rscHotjar = document.getElementById("rsc-hotjar");
    var gtm = document.getElementById("rsc-gtm");
    var gtmTag = document.getElementById("rsc-gtm-tag");
    var gtmNoscript = document.getElementById("rsc-gtm-noscript");
    var onetrustConsentSdk = document.getElementById("onetrust-consent-sdk");
    
    setTimeout(() => {
      var onetrustConsentSdk = document.getElementById("onetrust-consent-sdk");
      if(onetrustConsentSdk) {
        onetrustConsentSdk.remove();
      }
    }, 200)

    var otStyles =  document.getElementById('onetrust-style');

    if(oneTrustAutoblock) {
      oneTrustAutoblock.remove();
    }

    if(oneTrustOptanon) {
      oneTrustOptanon.setAttribute("src", '')
      oneTrustOptanon.remove();
    }

    if(oneTrustOptanonWrapper) {
      oneTrustOptanonWrapper.remove();
    }
    
    if(onetrustConsentSdk) {
      onetrustConsentSdk.remove();
    }

    if(tag) {
      tag.remove();
    }

    if(rscHotjar) {
      rscHotjar.textContent = "";
      rscHotjar.remove();
    }

    if(gtm) {
      gtm.textContent = "";
      gtm.remove();
    }

    if(gtmTag) {
      gtmTag.textContent = "";
      gtmTag.remove();
    }

    if(gtmNoscript) {
      gtmNoscript.remove();
    }

    if(otStyles) {
      otStyles.remove();
    }
  }

  static async showErrorAlert(message: string, showHelpdeskMessage: boolean = false, logCode: string = '') {

    let modal = MyUtil.createModal(ErrorAlertModalComponent,
      {
        message: message,
        showHelpdeskMessage: showHelpdeskMessage,
        logCode: logCode,
      },
      false);

    (await modal).present();

  }
  
}
