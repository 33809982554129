import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';

/**
 * Generated class for the ScoreScaleComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'score-scale',
  templateUrl: 'score-scale.html',
  styleUrls: ['score-scale.scss'],
  inputs: ['score', 'min', 'max', 'disabled'],
  outputs: ['scoreChange'],
})
export class ScoreScaleComponent implements OnInit, OnDestroy{

  min: number;
  max: number;
  range: Array<number>;
  score: number;
  disabled: boolean = false;
  scoreChange: EventEmitter<number>;

  constructor() {
    this.scoreChange = new EventEmitter();
  }

  ngOnInit(): void {
    let list = [];
    for (let i = this.min; i <= this.max; i++) {
      list.push(i);
    }
    this.range = list;
  }

  onChange(score) {
    if (this.disabled) {
      return;
    }
    this.score = score;
    this.scoreChange.emit(score);
  }
  ngOnDestroy(): void {
    
  }

}
