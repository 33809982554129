import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';

@Injectable()

// Effect is a class with observable properties - returns an action - dispatch action back.
export class ViewEffects {
  constructor(
    private actions$: Actions,
    ) { }
}
