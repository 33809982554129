import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { Appapi } from 'src/app/providers/appapi';
import { MyUtil } from "../../../libs/MyUtil";
/**
 * Generated class for the QuestionnaireIntroPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-skip-questionnaire',
  templateUrl: 'skip-questionnaire.html',
})
export class SkipQuestionnaire implements OnInit, OnDestroy{

  constructor(public router: Router, public navParams: NavParams, public appapi: Appapi) {}

  ngOnInit() {
    // force to do the profile first
    if (!MyUtil.validateProfile()) {
      this.router.navigate(['/UserSettingsPage', '{}']);
    }
  }

  skip() {
    let loading = MyUtil.presentLoading();
    this.appapi.setQuestionnaireSkippedFlag().then(async res => {
      (await loading).dismiss();
      this.router.navigate(['/ActivitiesPage']);

    })
    .catch(async err => {
      (await loading).dismiss();
      //MyUtil.presentToast('An error occured, please try again', { cssClass: 'inkpath-toast' });
    });
  }

  continue() {
    this.router.navigate(['/QuestionnaireSelectPage']);
  }

  ngOnDestroy(): void {
    
  }
}
