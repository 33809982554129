import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { DesktopChecker } from 'src/app/providers/desktopChecker';

import { MyUtil } from '../../../libs/MyUtil';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'page-about',
  templateUrl: 'about.html'
})
export class AboutPage {
  pageData: any = {};

  constructor(public router: Router, public navParams: NavParams, public desktopChecker: DesktopChecker,
    private titleService:Title) {
      this.titleService.setTitle("About App");
    this.pageData.context = MyUtil.context;
    this.pageData.windowSize = MyUtil.getWindowSize();
    this.pageData.network = MyUtil.getNetwork();
  }

  ionViewDidLoad() {
    MyUtil.firebaseSetScreenName('about');
  }

  ionViewDidEnter() {
    MyUtil.firebaseLogEvent('view_did_enter', {name: 'about', data: this.navParams.data});
  }

  ionViewDidLeave() {
    MyUtil.firebaseLogEvent('view_did_leave', {name: 'about', data: this.navParams.data});
  }
}
