import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { Appapi } from 'src/app/providers/appapi';
import { QuestionnaireQuestion, QuestionnaireSection } from 'src/app/providers/questionnaire-data/models';
import { QuestionnaireDataProvider } from 'src/app/providers/questionnaire-data/questionnaire-data';
import { MyUtil } from "../../../libs/MyUtil";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

/**
 * Generated class for the QuestionnairePage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-questionnaire',
  templateUrl: 'questionnaire.html',
  styleUrls: ['questionnaire.scss'],
})
export class QuestionnairePage  implements OnInit{

  sections: Array<QuestionnaireSection> = [];
  pageData:any={};
  routeData:any={};
  
  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private route: ActivatedRoute,public router: Router, public navParams: NavParams, public appapi: Appapi, public questionnaireData: QuestionnaireDataProvider) {
   }
   ngOnInit(): void {
     
    // this.sections = new QuestionnaireSection();
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData =JSON.parse(params.data);
    });
    
    this.pageData.id =this.routeData.id;
    this.pageData.fromSelect = this.routeData.fromSelect;
  }

  getSection(): QuestionnaireSection {
    return this.questionnaireData.getSection(this.pageData.id);
  }

  getQuestion(): QuestionnaireQuestion {
    return this.questionnaireData.getQuestion(this.pageData.id);
  }

  getSectionQuestionsCount() {
    return this.getSection().questions.length;
  }

  getQuestionNumber() {
    let quesObj = this.questionnaireData.questionnaires.find( item=>{
      return item.questionnaire.id ==this.pageData.id ;
    });
    return quesObj.activeQuestionIndex + 1;
  }

  previousButton() {
    this.questionnaireData.previous(this.pageData.id);
  }

  nextButton(exit = false) {
    // Validate that the user has entered a skill level and interest for the question
    if (this.getQuestion().skill_level === null || this.getQuestion().interest === null) {
      if (exit) {
        // return this.navCtrl.setRoot(UserPage, {'segment': 'pdp'});
      }

      // TODO: show error to user
      return;
    }

    // Save answers to question
    let loading = MyUtil.presentLoading();
    this.appapi.saveQuestion(this.getQuestion()).then(async (res) => {
      (await loading).dismiss();

      if (exit) {
        // return this.navCtrl.setRoot(UserPage, {'segment': 'pdp'});
      }

      try {
        const newQuestionIndex = this.questionnaireData.next(this.pageData.id);

        if (newQuestionIndex === -1 ) {

          if(this.pageData.fromSelect){
            // return this.navCtrl.setRoot(UserPage).then(()=>{
               this.router.navigate(['/SkillsAnalysisSummaryPage',JSON.stringify({fromOnboarding: false, fromSelect :this.pageData.fromSelect})])
            // });
          }else{
          // return this.navCtrl.setRoot(QuestionnaireIntroPage,{fromSelect:this.pageData.fromSelect? true:false});
          }
        } else {
          // return this.navCtrl.setRoot(QuestionnairePage,{fromSelect:this.pageData.fromSelect? true:false, id:this.pageData.id});
        }
      } catch (e) {
        // No new question, end of questionnaire

      
          //return this.navCtrl.setRoot(UserPage).then(()=>{

            if(this.pageData.fromSelect){
              this.router.navigate(['SkillsAnalysisSummaryPage', JSON.stringify({fromOnboarding: false,fromSelect :this.pageData.fromSelect})])
            }else{
              //return this.navCtrl.setRoot(SkillsAnalysisSummaryPage, {fromOnboarding: true});
              this.router.navigate(['SkillsAnalysisSummaryPage', JSON.stringify({fromOnboarding: true})])
              
            }
         // });

      }
    })
      .catch(async err => {
        // app has gone offline
        (await loading).dismiss();
        //MyUtil.presentToast('No internet connection. Please try again later.', { cssClass: 'inkpath-toast' });
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
