import { Component } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";

@Component({
  selector: "diagnostic-complete-modal",
  templateUrl: "diagnostic-complete-modal.html",
  styleUrls: ["diagnostic-complete-modal.scss"],
})
export class DiagnosticCompleteModal {

  // Component variables.
  public data: any;
  public label: any = 0;

  constructor(public params: NavParams, public viewCtrl: ModalController) {
    if (params) {
      this.data = params.data;
    }
  }

  // Handle action.
  handleAction() {
    this.viewCtrl.dismiss('visitRecomendedActivities');
  }

  // Close modal.
  closeModal() {
    this.viewCtrl.dismiss('visitActivities');
  }
}
