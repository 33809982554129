import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { MyDb } from 'src/libs/MyDb';
import { MyUtil } from 'src/libs/MyUtil';
import { Appapi } from '../providers/appapi';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    public readonly appapi: Appapi,
    private readonly router: Router
  ) { }
   

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Observable<boolean> | boolean> {

    let canAccess = await  MyDb.appLoad(MyUtil.DOC_ID.APP_USER).then(appUser =>{


      let isLoggedIn = (appUser && appUser.api_token);
      if(isLoggedIn){

        // check session time for RSC
        let hostname = window.location.hostname;
                  
        if(hostname === MyUtil.context.RSC_URL) {
          // compare session start date/time plus 12 hours against time now
          let rscSession = MyUtil.retrieveFromLocalStorage('rsc-session');
          let sessionEnd: number = +rscSession;
          let currentTime = MyUtil.getUnixTimeStamp();

          if(currentTime > sessionEnd) {
            MyUtil.removeFromLocalStorage('rsc-session');
            this.appapi.forgetAppUser(true).then(() => {
              this.router.navigate(['/rsc/reload-auth']);
              return false;
            });
            
            return false;
          }

          return true;

        }

        return true;

      } else {
        
        this.router.navigate(['/reload-login']);
        return false;
      }
    }).catch(error =>{
      console.log(error)
    })
    
    if(!canAccess){
      
      let hostname = window.location.hostname;
      if(hostname === MyUtil.context.RSC_URL) {
        this.router.navigate(['/rsc/reload-auth']);
      }else{
        this.router.navigate(['/reload-login']);
      }
      //

      return false;
    }
    return canAccess;
  }

  
}
