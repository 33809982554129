import { Component, ViewChild, ViewChildren } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { MyUtil } from 'src/libs/MyUtil';
import { SwiperComponent } from "swiper/angular";
import { OnboardingService } from '../../providers/onboarding/onboarding-service';

// import Swiper core and required modules
import SwiperCore, { Swiper, Virtual, EffectFade } from 'swiper';
SwiperCore.use([EffectFade, Virtual]);

// install Swiper modules
SwiperCore.use([Virtual]);

@Component({
  selector: 'page-onboarding',
  templateUrl: 'onboarding.html',
  styleUrls: ['onboarding.scss']
})
export class OnboardingPage {

  // Component variables.
  pageData: any = {};
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  @ViewChildren('slide') slide;

  constructor(
    public router: Router,
    public onboardingService: OnboardingService, public navCtrl: NavController, public sanitiser: DomSanitizer,
    private titleService:Title) {
      this.titleService.setTitle("Onboarding");
    }

  ngOnInit() {
    this.pageData.mandatoryOnboarding = false;
    this.pageData.showWelcome = true;

    let profile = MyUtil.getProfile();
    if(profile) {
      this.pageData.profileName = profile.first_name;
    }
    this.onboardingService.getOnboardingPages().then( (response) => {
      if(response && response.pages && response.pages.length > 0) {
        response.pages.forEach( (element, i) => {
          if( i === (response.pages.length - 1) ) {
            element.next = () => { return this.process('skip', i); }
          } else {
            element.next = () => { return this.process('slide-next', i); }
          }
          if(i > 0) {
            element.prev = () => { return this.process('slide-prev', i); }
          }
          // sanitise any urls
          if(element.video_url) {
            element.video_url = this.sanitiser.bypassSecurityTrustResourceUrl(element.video_url);
          }

          element.content = this.sanitiser.bypassSecurityTrustHtml(element.content);

        });

        this.pageData.onboardingPages = response.pages;
      }else{
        MyUtil.presentToast('No onboarding content to display.', { cssClass: 'inkpath-toast' });
        this.router.navigate(['/']);
      }
      this.pageData.mandatoryOnboarding = response.mandatory;
    }).catch(async err => {
      MyUtil.error(err);
    });
  }

  /** 
  * Set scrollTop.
  */
  setScrollTop(){
    this.slide._results.forEach((v, k)=>{
      v.nativeElement.scrollTop = 0;
    })
  }

/** 
  * Actions.
  * @param action   Case for action in switch statement.
  * @param i        (number)
  */
  process(action, i = 1) {
    switch (action) {
      case 'slide-prev':
        if(i-1 > 0) {
          this.pageData.showWelcome = false;
        } else {
          this.pageData.showWelcome = true;
        }
        this.setScrollTop();
        this.swiper.swiperRef.slidePrev(30);
        return;
      case 'slide-next':
        if(i+1 > 0) {
          this.pageData.showWelcome = false;
        } else {
          this.pageData.showWelcome = true;
        }
        this.setScrollTop();
        this.swiper.swiperRef.slideNext(30);
        return;
      case 'skip':
        // update seen onbaording flag
        let profile = MyUtil.getProfile();
        this.onboardingService.setUserHasViewedOnboardingPagesFlag(profile.id);
        
        // check if WAYH/Reflection page is hidden by org
        if(profile.hide_onboarding_reflection && profile.hide_onboarding_reflection === 1) {
          this.router.navigate(['/']);
        } else {
          this.router.navigate(['/ReflectionPage', JSON.stringify({skip: true})]);
        }
        return
      default:
        MyUtil.presentToast('"' + action + '" is not handled', { cssClass: 'inkpath-toast' });
        return;
    }
  }
}
