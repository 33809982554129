import { Component, NgZone, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ActivityService } from 'src/app/providers/activity-service';
import { MyUtil } from '../../../libs/MyUtil';
import { ActivityEditPage } from "../activity-edit/activity-edit";

@Component({
  selector: 'page-activity-select',
  templateUrl: 'activity-select.html',
  styleUrls: ['activity-select.scss']
})
export class ActivitySelectPage implements OnInit {
  pageData: any = {};

  constructor(public navParams: NavParams, public viewCtrl: ModalController, public zone: NgZone,
    public activityService: ActivityService) {

  }

  ngOnInit(): void {

    this.pageData.homeOrg = MyUtil.getRootOrganization();

    //global = false: this is a course, true: it's a goal
    this.pageData.global = !!this.navParams.get('global');

    if (this.pageData.global == true) {
      //Goal activities
      this.pageData.isMobileAppMode = MyUtil.isMobileAppMode();
      this.pageData.homeOrg = MyUtil.getRootOrganization();
      let orgDetails = MyUtil.getAppOrg(this.pageData.homeOrg.id);
      this.pageData.dateFormat = MyUtil.formatPHPDateForPipe(orgDetails[0].org_date_format);
      
      let preDefinedFilters: any = {};
      this.pageData.activitiesDisplay = [];
      this.pageData.clashingActivities = [];
      this.pageData.filter = {
        searchKey: '',
        skills: (preDefinedFilters.skills) ? preDefinedFilters.skills : null,
        organizations: MyUtil.getOrgnizationChain(),
        startAt: (preDefinedFilters.start_at) ? MyUtil.formatUnixTimeStamp2ISO(preDefinedFilters.start_at * 1000) : MyUtil.formatUnixTimeStamp2ISO(MyUtil.getUnixTimeStamp() - 86400 * 60),
        endAt: MyUtil.formatUnixTimeStamp2ISO(MyUtil.getUnixTimeStamp() + 86400 * 365),
        dateMin: MyUtil.formatUnixTimeStamp2ISO(MyUtil.getUnixTimeStamp() - 86400 * 365 * 3),
        dateMax: MyUtil.formatUnixTimeStamp2ISO(MyUtil.getUnixTimeStamp() + 86400 * 365 * 3),
      };
      this.pageData.filterDisplay = {};
      this.pageData.bookingStatus = null;
      
      this.pageData.selectedActivities = this.navParams.get('selected');
      if (!this.pageData.selectedActivities) {
        this.pageData.selectedActivities = [];
      }

      this.applyFilter();

    } else {
      //Course activities
      this.pageData.selectedActivities = [];
      this.pageData.activitiesDisplay = this.navParams.get('activities');
      this.pageData.activitiesDisplay.forEach(element => {
        if (element.is_required == 1) {
          element.selected = true;
        }

        // format date/time for display
        element.start_date_time = MyUtil.formatUnixTimeStampDate(element.start_at) + " " + MyUtil.formatHISTimeFormatToAMPM(element.start_time);
        
      });

      this.pageData.clashingActivities = this.navParams.get('clashingActivities');
    }
    // default to true if nav param is not set
    MyUtil.firebaseSetScreenName('activity-select');

    MyUtil.firebaseLogEvent('view_did_enter', { name: 'activity-select', data: this.navParams.data });
    this.activityService.listBookingStatus().then(result => {
      this.pageData.bookingStatus = result;
    });
  }

  ionViewDidLeave() {
    MyUtil.firebaseLogEvent('view_did_leave', { name: 'activity-select', data: this.navParams.data });
  }

  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  * @param item     Parameter to process in action.
  */
  async process(action: string, item?: any) {
    // trick to avoid ionChange fired twice, @TODO remove when ionic fix this
    let changedTimestamp = MyUtil.getUnixTimeStamp();

    switch (action) {
      case 'create-activity':
        let activityCreateModal = MyUtil.createModal(ActivityEditPage, { fromGoalEdit: true });
        (await activityCreateModal).onDidDismiss().then((data: any) => {
          if (data.data && data.data.hasOwnProperty('activity')) {
            let activity = data.data.activity;
            let activityForDisplay = this.decorateActivityForDisplay(activity);
            activityForDisplay.selected = true;
            this.pageData.activitiesDisplay = MyUtil.lodash.concat([activityForDisplay], this.pageData.activitiesDisplay);
            this.pageData.selectedActivities.push(activity.id);
          }
        });
        (await activityCreateModal).present();
        return;
      case 'toggle-activity':
        this.zone.run(() => {
          let idx = this.pageData.selectedActivities.indexOf(item.id);
          if (idx !== -1) {
            this.pageData.selectedActivities.splice(idx, 1);
            item.selected = false;
          } else {
            this.pageData.selectedActivities.push(item.id);
            item.selected = true;
          }
        })
        return;

      case 'search-key':
        this.pageData.filter.toggle = false;
        this.applyFilter();
        return;

      case 'search-skill':
        this.showSkillCheckbox('Choose Skills', this.pageData.filter.skills);
        return;

      case 'search-organizations':
        this.showOrganizationCheckbox();
        return;

      case 'search-start':
        // trick to avoid ionChange fired twice, @TODO remove when ionic fix this
        if (this.pageData.changedTimestamp && this.pageData.changedTimestamp >= changedTimestamp - 1) {
          return;
        }
        this.pageData.changedTimestamp = changedTimestamp;

        let sts1 = MyUtil.getUnixTimeStamp(this.pageData.filter.startAt);
        let sts2 = MyUtil.getUnixTimeStamp(this.pageData.filter.endAt);
        if (sts1 > sts2) {
          this.pageData.filter.endAt = this.pageData.filter.startAt
        }
        this.applyFilter();
        return;

      case 'search-end':
        // trick to avoid ionChange fired twice, @TODO remove when ionic fix this
        if (this.pageData.changedTimestamp && this.pageData.changedTimestamp >= changedTimestamp - 1) {
          return;
        }
        this.pageData.changedTimestamp = changedTimestamp;

        let ets1 = MyUtil.getUnixTimeStamp(this.pageData.filter.startAt);
        let ets2 = MyUtil.getUnixTimeStamp(this.pageData.filter.endAt);
        if (ets1 > ets2) {
          this.pageData.filter.startAt = this.pageData.filter.endAt
        }
        this.applyFilter();
        return;

      case 'submit':
        this.dismiss(this.pageData.selectedActivities);
        return;
      case 'cancel':
        this.dismiss(null);
        return;

      default:
        MyUtil.presentToast('"' + action + '" is not handled', { cssClass: 'inkpath-toast' });
        return;
    }
  }

  /** 
  * Scroll handler.
  */
  scrollHandler(event) {
    this.zone.run(() => {
      //close filter when scrolling
      let threshold = (this.pageData.isMobileAppMode ? 160 : 300);
      if (this.pageData.filter.toggle && event && event.scrollTop > threshold) {
        this.pageData.filter.toggle = false;
      }
    })
  }

  /** 
  * Render filter.
  */
  private renderFilter() {
    this.pageData.filterDisplay = {
      organizations: MyUtil.lodash.chain(this.pageData.filter.organizations).filter((item) => {
        return !item.hidden;
      }).map('name').value().join(','),
      skills: MyUtil.lodash.chain(MyUtil.getSkills()).filter((item) => {
        return (this.pageData.filter.skills && this.pageData.filter.skills.length > 0 && this.pageData.filter.skills.indexOf(parseInt(item.id)) !== -1);
      }).map('name').value().join(',')
    };

    if (!this.pageData.filterDisplay.skills) {
      this.pageData.filterDisplay.skills = '(all)';
    }
  }

  /** 
  * Apply filter.
  */
  private async applyFilter() {
    this.renderFilter();

    let loading = MyUtil.presentLoading();

    let searchKey = (this.pageData.filter.searchKey && this.pageData.filter.searchKey.length > 0 ? this.pageData.filter.searchKey.toLowerCase() : null);

    let programIds = MyUtil.getProfileProgramIds();

    let activities = MyUtil.lodash.chain(MyUtil.cache[MyUtil.DOC_ID.ACTIVITIES]).filter(item => (!item.programs || item.programs.some(el => programIds.indexOf(el) >= 0) || item.programs.length === 0)).values().filter((item) => {
      let result = true;

      // make sure selected activities always included
      if ((this.pageData.selectedActivities.indexOf(item.id) !== -1)) {
        return result;
      }

      if (result && searchKey) {
        // set null values as empty string
        item.name = (item.name) ? item.name : '';
        item.code = (item.code) ? item.code : '';
        item.provider = (item.provider) ? item.provider : '';

        result = result && (
          (item.name.toLowerCase().indexOf(searchKey.toLowerCase()) > -1)
          || (item.code.toLowerCase().indexOf(searchKey.toLowerCase()) > -1)
          || (item.provider.toLowerCase().indexOf(searchKey.toLowerCase()) > -1)
        );
      }

      if (result && this.pageData.filter.skills && this.pageData.filter.skills.length > 0) {
        let skill_match = MyUtil.lodash.filter(this.pageData.filter.skills, (id) => {
          return item.skills.indexOf(parseInt(id)) !== -1;
        })
        result = result && (skill_match.length > 0);
      }

      if (result && this.pageData.filter.organizations) {
        let organizationIds = MyUtil.lodash.chain(this.pageData.filter.organizations).filter((item) => {
          return !item.hidden;
        }).map('id').value();
        result = result && (organizationIds.indexOf(parseInt(item.oid)) !== -1);
      }

      if (result && this.pageData.filter.startAt) {
        let ts = MyUtil.getUnixTimeStamp(this.pageData.filter.startAt);
        result = result && (ts <= item.end_at);
      }

      if (result && this.pageData.filter.endAt) {
        let ts = MyUtil.getUnixTimeStamp(this.pageData.filter.endAt);
        result = result && (ts >= item.start_at);
      }

      return result;

    }).forEach((item) => {
      item = this.decorateActivityForDisplay(item);
    }).orderBy(['start_at'], ['desc']).value();

    // filter funding activities
    let fundingActivities = MyUtil.lodash.chain(MyUtil.cache[MyUtil.DOC_ID.FUNDING_ACTIVITIES]).filter(item => (!item.programs || item.programs.some(el => programIds.indexOf(el) >= 0) || item.programs.length === 0)).values().filter((item) => {
      let result = true;

      // make sure selected activities always included
      if ((this.pageData.selectedActivities.indexOf(item.id) !== -1)) {
        return result;
      }

      if (result && searchKey) {
        // set null values as empty string
        item.name = (item.name) ? item.name : '';
        item.code = (item.code) ? item.code : '';
        item.provider = (item.provider) ? item.provider : '';

        result = result && (
          (item.name.toLowerCase().indexOf(searchKey.toLowerCase()) > -1)
          || (item.code.toLowerCase().indexOf(searchKey.toLowerCase()) > -1)
          || (item.provider.toLowerCase().indexOf(searchKey.toLowerCase()) > -1)
        );
      }

      if (result && this.pageData.filter.skills && this.pageData.filter.skills.length > 0) {
        let skill_match = MyUtil.lodash.filter(this.pageData.filter.skills, (id) => {
          return item.skills.indexOf(parseInt(id)) !== -1;
        })
        result = result && (skill_match.length > 0);
      }

      if (result && this.pageData.filter.startAt) {
        let ts = MyUtil.getUnixTimeStamp(this.pageData.filter.startAt);
        result = result && (ts <= item.end_at);
      }

      if (result && this.pageData.filter.endAt) {
        let ts = MyUtil.getUnixTimeStamp(this.pageData.filter.endAt);
        result = result && (ts >= item.start_at);
      }

      return result;

    }).forEach((item) => {
      let orgDetails = MyUtil.getAppOrg(this.pageData.homeOrg.id);
      let dateFormat = MyUtil.formatPHPDate(orgDetails[0].org_date_format);

      item.startDisplay = MyUtil.formatUnixTimeStampDate(item.start_at, dateFormat);
      item.endDisplay = MyUtil.formatUnixTimeStampDate(item.end_at, dateFormat);

      item.period = this.activityService.getShortDisplayDateTime(orgDetails[0], 
        item.start_at, 
        item.start_time, 
        item.end_at, 
        item.end_time);

      item.organization = MyUtil.getOrgnizationChainName(item.oid);
      item.level = MyUtil.cache[MyUtil.DOC_ID.USER_FUNDING_ORGANIZATIONS][item.oid].level;
      
      item.selected = (this.pageData.selectedActivities.indexOf(item.id) !== -1);
    }).orderBy(['organization'], ['asc']).value();

    this.pageData.activitiesDisplay = MyUtil.lodash.concat(activities, fundingActivities);

    (await loading).dismiss();
  }

  /** 
  * Decorate activity for display..
  */
  private decorateActivityForDisplay(item) {
    let orgDetails = MyUtil.getAppOrg(this.pageData.homeOrg.id);
    let dateFormat = MyUtil.formatPHPDate(orgDetails[0].org_date_format);

    item.startDisplay = MyUtil.formatUnixTimeStampDate(item.start_at, dateFormat);
    item.endDisplay = MyUtil.formatUnixTimeStampDate(item.end_at, dateFormat);

    item.period = this.activityService.getShortDisplayDateTime(orgDetails[0], 
      item.start_at, 
      item.start_time, 
      item.end_at, 
      item.end_time);

    item.organization = MyUtil.getOrgnizationChainName(item.oid);
    item.level = MyUtil.cache[MyUtil.DOC_ID.USER_ORGANIZATIONS][item.oid].level;    
    item.selected = (this.pageData.selectedActivities.indexOf(item.id) !== -1);

    return item;
  }

  /** 
  * Show organisation checkbox.
  */
  private showOrganizationCheckbox() {
    let inputs = MyUtil.lodash.chain(this.pageData.filter.organizations).map((item) => {
      return {
        type: 'checkbox',
        label: item.name,
        value: item.id,
        checked: !item.hidden,
      };
    }).value();

    MyUtil.presentAlert({
      'title': 'Choose organisations to search',
      inputs: inputs,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            MyUtil.debug('Cancel clicked');
          }
        },
        {
          text: 'OK',
          handler: data => {
            MyUtil.debug(['Checkbox data:', data]);
            MyUtil.lodash.forEach(this.pageData.filter.organizations, (item) => {
              if (data.indexOf(item.id) !== -1) {
                item.hidden = false;
              } else {
                item.hidden = true;
              }
            });
            this.applyFilter();
          }
        }
      ]
    });
  }

  /** 
  * Show skill checkbox.
  */
  private showSkillCheckbox(title: string, filter: any) {
    let programIds = MyUtil.getProfileProgramIds();
    let inputs = MyUtil.lodash.chain(MyUtil.cache[MyUtil.DOC_ID.USER_SKILLS]).filter(item => (!item.programs || item.programs.some(el => programIds.indexOf(el) >= 0) || item.programs.length === 0)).map((item) => {
      let input = {
        type: 'checkbox',
        label: item.name,
        value: item.id,
        checked: (filter && filter.indexOf(item.id) !== -1),
      };
      return input;
    }).value();

    MyUtil.presentAlert({
      title: title,
      inputs: inputs,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            MyUtil.debug('Cancel clicked');
          }
        },
        {
          text: 'OK',
          handler: data => {
            MyUtil.debug(['Checkbox data:', data]);
            this.pageData.filter.skills = data;
            this.applyFilter();
          }
        }
      ]
    });
  }

  /** 
  * Toggle activity select course.
  */
  toggleActivitySelectCourse(activity: any) {
    if (activity.selected == true && activity.is_required != 1) {
      activity.selected = false;
    } else {
      activity.selected = true;
    }
  }

  /** 
  * Filter click handler.
  */
  filterClickHandler() {
    this.pageData.filter.toggle = !this.pageData.filter.toggle
  }

  /** 
  * Submit course activities.
  */
  submitCourseActivities() {
    this.pageData.activitiesDisplay.forEach(item => {
      if (item.selected) {
        this.pageData.selectedActivities.push(item.activity_id);
      }
    });
    this.dismiss(this.pageData.selectedActivities);
  }

  /** 
  * Dismiss.
  */
  private dismiss(data: any) {
    this.viewCtrl.dismiss(data);
  }
}
