import { AfterContentInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { NgxCopilotService } from 'ngx-copilot';
import { BehaviorSubject, combineLatest, Observable, scheduled, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { ListItemComponent } from 'src/app/common/list-item/list-item.component';
import { ActivityService } from 'src/app/providers/activity-service';
import { Appapi } from 'src/app/providers/appapi';
import * as appStore from '../../store';
import { CoursesService } from 'src/app/providers/courses-service';
import { DesktopChecker } from 'src/app/providers/desktopChecker';
import { UiuxService } from 'src/app/services/uiux.service';
import { WalkthroughService } from 'src/app/services/walkthrough.service';
import { BookingStatusVO, CourseVO, OrganisationVO, ServerAPIResponseVO, UserActivityVO } from 'src/app/valueObjects/core.vo';
import { ActivityListItemVO } from 'src/app/valueObjects/lists.vo';
import { MyUtil } from '../../../libs/MyUtil';
import { Store } from '@ngrx/store';
import { getActivityListFilterState, getBrandName } from 'src/app/store/selectors/view.selector';
import { FindActivitiesFilters } from 'src/app/store/reducers/view.reducer';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { FindActivitiesModalComponent, findActivitiesTabStateVO } from 'src/app/components/find-activities-modal/find-activities-modal.component';
import { DateAdapter, MatOptionSelectionChange } from '@angular/material/core';

@Component({
  selector: 'page-activity-list',
  templateUrl: 'activity-list.html',
  styleUrls: ['activity-list.scss']
})

export class ActivityListPage implements OnInit, OnDestroy, AfterContentInit {

  constructor(public router: Router,
    private route: ActivatedRoute,
    private copilot: NgxCopilotService,
    public navParams: NavParams,
    public formBuilder: UntypedFormBuilder,
    public zone: NgZone,
    public uiux: UiuxService,
    public appapi: Appapi,
    public desktopChecker: DesktopChecker,
    public activityService: ActivityService,
    public coursesService: CoursesService,
    private store: Store<appStore.AppViewState>,
    public courseService: CoursesService,
    private titleService: Title,
    private dateAdapter: DateAdapter<Date>) 
  {
    this.titleService.setTitle("Find Activities");
  }

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  menuOpened() {
    setTimeout(() => {
      document.getElementById('organisations').focus();
    }, 0);
  }

  // Component variables.
  component = ListItemComponent;
  @ViewChild('Content', { static: true }) content;
  routeData: any = {};
  pageLabel = "ActivityListPage";
  displayHelpIcon = true;
  isMobileView = this.uiux.isMobileView();
  brandName$: Observable<string> = this.store.select(getBrandName);
  activityFilterState$: Observable<FindActivitiesFilters> = this.store.select(getActivityListFilterState);
  activitiesList$: BehaviorSubject<UserActivityVO[]> = new BehaviorSubject([]);
  myActivitiesList$: BehaviorSubject<UserActivityVO[]> = new BehaviorSubject([]);
  tabSelection$: BehaviorSubject<string> = new BehaviorSubject('scheduled');
  loading = true;
  courseList$: BehaviorSubject<CourseVO[]> = new BehaviorSubject([]);
  bookingStatusList$: BehaviorSubject<BookingStatusVO[]> = new BehaviorSubject([]);
  courseBookingStatusList$: BehaviorSubject<BookingStatusVO[]> = new BehaviorSubject([]);

  filterForm = this.formBuilder.group({
    searchTerm: '',
    organisations: [],
    attendanceType: [],
    skills: [],
    start: '',
    end: '',
  });

  organisations = [];
  attendanceTypes = MyUtil.getAttendanceTypeChain();
  programIds = MyUtil.getProfileProgramIds();
  phaseIds = MyUtil.getProfilePhaseIds();
  skills = [];
  isDirtyForm = false;
  findActivitiesFilters: FindActivitiesFilters;
  scheduledActivitiesCount = 0;
  selfStudyActivitiesCount = 0;
  findActivitiesTabState:findActivitiesTabStateVO = JSON.parse(MyUtil.retrieveFromLocalStorage('findActivitiesTabState'));
  modalShown = false;
  homeOrg: OrganisationVO;
  orgFilterCount = 'all';
  attendanceFilterCount = 'all';
  skillsFilterCount = 'all';
  
  /** 
  * All ativites and courses as ActivityListItem[].
  */
  activityListItems$: Observable<ActivityListItemVO[]> = combineLatest([
    this.activitiesList$,
    this.courseList$,
    this.tabSelection$,
    this.filterForm.valueChanges.pipe(debounceTime(200)),
    this.bookingStatusList$,
    this.courseBookingStatusList$,
    this.myActivitiesList$,
    this.brandName$
  ]).pipe(takeUntil(this.destroy$), map(([activities, courses, tabSelection, formValues, bookingStatus, courseBookingStatus, myActivities, brandName]) => {

    const start = new Date(formValues.start).getTime() / 1000;
    const stillUtc = moment.utc(formValues.end).toDate();
    const local = moment(stillUtc).local().format('YYYY-MM-DD');
    const end = new Date(local).getTime() / 1000;
    const nowTimestamp = new Date();
    const stillUtcNow = moment.utc(nowTimestamp).toDate();
    const localNow = moment(stillUtcNow).local().format('YYYY-MM-DD');
    const now = new Date(localNow).getTime() / 1000;

    if (start > end) {
      MyUtil.presentToast('Date range invalid. End date must be greater than start date.', { cssClass: 'inkpath-toast' });
      return;
    }
    // Limit to 50 days
    let dateLimit = new Date();
    let days = 50;
    dateLimit.setDate(dateLimit.getDate() - days);
    let dateLimitTimestamp = dateLimit.getTime();
    if (Math.round(dateLimitTimestamp / 1000) > start) {
      MyUtil.presentToast('Historical activities are limited to 50 days unless they already exist in your activities list and goals.', { cssClass: 'inkpath-toast' });
    }

    let formValuesToStore = {
      searchTerm: formValues.searchTerm,
      organisations: formValues.organisations,
      attendanceType: formValues.attendanceType,
      skills: formValues.skills,
      start: formValues.start,
      end: formValues.end,
    }

    // Booking status list.
    const homeOrg: OrganisationVO = MyUtil.getRootOrganization();

    let myActivityIds = [];
    for (const myActivity of myActivities) {
      myActivityIds[myActivity.activity_id] = myActivity.activity_id;
    };

    // Set activities as ActivityListItem VO's.
    let activityList: ActivityListItemVO[] = []
    activities.forEach((activity: any) => {

      if (activity) {
        //Work out if this activity clashes with anything the user has already booked
        let activityBookingClash = MyUtil.getBookingClashStatus(activity, bookingStatus);
        let desc = (activity?.description) ? activity?.description : '';

        const userActivityVO: ActivityListItemVO = {
          id: activity.id,
          name: activity.name,
          booking_type: activity.booking_type,
          provider: activity.provider,
          period: activity.period,
          start_at: activity.start_at,
          description: desc,
          time_logged: activity.time_logged,
          end_at: activity.end_at,
          badge_details: activity.badge_details,
          price: activity.price,
          programs: activity.programs,
          phases: activity.phases,
          status: activity.status,
          internal_reference: activity.internal_reference,
          venue: activity.venue,
          visible: activity.visible,
          activity_template_id: activity.activity_template_id,
          attendance_type: activity.attendance_type,
          homeOrg: homeOrg,
          oid: activity.oid,
          skills: activity.skills,
          bookingStatus: bookingStatus[activity.id],
          bookingClash: activityBookingClash,
          added: !!myActivityIds[activity.id],
          code: activity.code,
          is_course: 0
        }
        activityList.push(userActivityVO)
      }
    })

    // Set courses as ActivityListItem VO's.
    let coursesList: ActivityListItemVO[] = [];
    for (const key in courses) {

      //Get the course booking status
      let courseId = courses[key].id;
      let bookingFound = courseBookingStatus[courseId] !== undefined;
      courses[key].is_booked = (bookingFound ? courseBookingStatus[courseId].is_booked : 0);
      courses[key].on_waiting_list = (bookingFound ? courseBookingStatus[courseId].on_waiting_list : 0);

      let desc = (courses[key].description) ? courses[key].description : '';

      //Work out if any of the course's activities clash with anything the user has already booked
      let courseBookingClash = false;
      for (const courseActivityId in courses[key].course_activities) {
        //If this course is booked, don't check for a clash if this activity isn't booked
        if ((courses[key].is_booked || courses[key].on_waiting_list) &&
          (!bookingStatus[courseActivityId] || !(bookingStatus[courseActivityId].is_booked == 1 || bookingStatus[courseActivityId].on_waiting_list == 1))) {
          continue;
        }
        courseBookingClash = MyUtil.getBookingClashStatus(courses[key].course_activities[courseActivityId], bookingStatus);
        if (courseBookingClash) {
          break;
        }
      }

      const userActivityVO: ActivityListItemVO = {
        id: courses[key].id,
        name: courses[key].name,
        booking_type: courses[key].id,
        provider: courses[key].provider,
        period: courses[key].period,
        start_at: courses[key].start_at,
        description: desc,
        end_at: courses[key].end_at,
        badge_details: courses[key].badge_details,
        price: courses[key].price,
        programs: courses[key].programs,
        phases: courses[key].phases,
        internal_reference: courses[key].internal_reference,
        code: courses[key].code,
        skills: courses[key].skills,
        visible: courses[key].visible,
        is_course: 1,
        is_hybrid: courses[key].is_hybrid == 1,
        homeOrg: homeOrg,
        oid: courses[key].oid,
        is_booked: courses[key].is_booked,
        on_waiting_list: courses[key].on_waiting_list,
        bookingClash: courseBookingClash,
        added: courses[key].is_booked,
        booking_opens_ts: courses[key].booking_opens_ts,
        booking_closes_ts: courses[key].booking_closes_ts,
        booking_opens_at: courses[key].booking_opens_at,
        booking_closes_at: courses[key].booking_closes_at,
        capacity: courses[key].capacity,
        online_capacity: courses[key].online_capacity,
        bookings: courses[key].bookings,
        onlineBookings: courses[key].online_bookings,
        terms_text: courses[key].terms_text,
      }
      coursesList.push(userActivityVO);
    }


    //Generate self-study list (attendance type = 3 and activity is currently ongoing ("now" is between the start and end dates))
    //Only filter on the activity list, not the courses
    let selfStudyList = Object.assign([], activityList);
    selfStudyList = selfStudyList.filter(activity => {

      if (activity.attendance_type != MyUtil.CONST.APP_META.ATTENDANCE_TYPE_LIVE_SELF_STUDY) {
        return false;
      }

      // Filter by: Filter out private activities.
      if(activity.visible !== 1) {
        return false;
      }

      // activity start <= today <= activity end
      if(parseInt(activity.start_at) > now || parseInt(activity.end_at) < now) {
        return false;
      }

      // Filter by: search term
      if (formValues.searchTerm && formValues.searchTerm !== null) {
        if (activity.name.toLowerCase().indexOf(formValues.searchTerm.toLowerCase()) === -1 && 
        activity?.description.toLowerCase().indexOf(formValues.searchTerm.toLowerCase()) === -1) {
          return false;
        }
      }

      // Filter by: selected organisations
      if (formValues.organisations && formValues.organisations.length !== 0 && brandName !== 'rsc') {
        if (formValues.organisations.indexOf(activity.oid) === -1) {
          // Org name doesn't match. Try filter by: "provided by" organisation
          let activityProviderUppercase = activity.provider.toUpperCase();
          if(formValues.organisations.filter(org => {
            if (activity.provider && org.name) {
              return (activityProviderUppercase == org.name.toUpperCase());
            }
          }).length === 0) {
            return false;
          };
        }
      }

      // Filter by: program.
      if (activity.programs.length > 0) {
        if (activity.programs.filter(programId => {
          return this.programIds.indexOf(programId) !== -1;
        }).length === 0) {
          return false;
        }
      }

      // Filter by: phase.
      if (activity.phases.length > 0) {
        if (activity.phases.filter(phaseId => {
          return this.phaseIds.indexOf(phaseId) !== -1;
        }).length === 0) {
          return false;
        }
      }

      // Filter by: selected skills
      if (formValues.skills && formValues.skills.length !== 0) {
        if (activity.skills) {
          if (formValues.skills.filter((skillId) => {
            return (activity.skills.indexOf(parseInt(skillId)) !== -1);
          }).length === 0) {
            return false;
          };
        }
      }

      //All the above conditions met - activity is a match
      return true;
    });


    //Refactored activity and course filter for scheduled events, done in a single loop
    let scheduledList = [...activityList, ...coursesList];
    scheduledList = scheduledList.filter(event => {

      //Filter out self-study (don't do check if it's a course)
      if (!event.is_course) {
        if (event.attendance_type == MyUtil.CONST.APP_META.ATTENDANCE_TYPE_LIVE_SELF_STUDY) {
          return false;
        }
      }

      // Filter by: selected attendance types 
      if (formValues.attendanceType) {
        // Stop from filtering out courses, as courses do not have an attendance type.
        if (!event.is_course) {
          if (formValues.attendanceType.indexOf(`${event.attendance_type}`) === -1) {
            return false;
          }
        }
      }

      // Filter by: Filter out private activities.
      if(event.visible !== 1) {
        return false;
      }

      // Filter by: date range.
      //SEARCH START <= ACTIVITY END <= SEARCH END 
      if (!((start <= parseInt(event.end_at) && parseInt(event.end_at) <= end) 
          || (start <= parseInt(event.start_at) && parseInt(event.start_at) <= end))) {
        return false;
      }

      // Filter by: search term
      if (formValues.searchTerm && formValues.searchTerm !== null) {
        if (event.name.toLowerCase().indexOf(formValues.searchTerm.toLowerCase()) === -1 && 
        event?.description.toLowerCase().indexOf(formValues.searchTerm.toLowerCase()) === -1) {
          return false;
        }
      }

      // Filter by: selected organisations
      if (formValues.organisations && formValues.organisations.length !== 0 && brandName !== 'rsc') {
        if (formValues.organisations.indexOf(event.oid) === -1) {
          // Org name doesn't match. Try filter by: "provided by" organisation
          let activityProviderUppercase = event.provider.toUpperCase();
          if(formValues.organisations.filter(org => {
            if (event.provider && org.name) {
              return (activityProviderUppercase == org.name.toUpperCase());
            }
          }).length === 0) {
            return false;
          };
        }
      }

      // Filter by: program.
      if (event.programs.length > 0) {
        if (event.programs.filter(programId => {
          return this.programIds.indexOf(programId) !== -1;
        }).length === 0) {
          return false;
        }
      }

      // Filter by: phase.
      if (event.phases.length > 0) {
        if (event.phases.filter(phaseId => {
          return this.phaseIds.indexOf(phaseId) !== -1;
        }).length === 0) {
          return false;
        }
      }

      // Filter by: selected skills
      if (formValues.skills && formValues.skills.length !== 0) {
        if (event.skills) {
          if (formValues.skills.filter((skillId) => {
            return (event.skills.indexOf(parseInt(skillId)) !== -1);
          }).length === 0) {
            return false;
          };
        }
      }

      //All the above conditions met - activity is a match
      return true;
    });
    
    this.selfStudyActivitiesCount = selfStudyList.length;
    this.scheduledActivitiesCount = scheduledList.length;

    let activitiesAndCoursesList = null;
    if (tabSelection === "self-study") {
      activitiesAndCoursesList = selfStudyList;
    } else {
      activitiesAndCoursesList = scheduledList;
    }

    // Show Tab selection modal if not shown.
    if(!this.modalShown) {
      this.tabSelectModalHandler(tabSelection);
      this.modalShown = true;
    }

    // Filtering ends here... lets get on with something else...
    // Set form as dirty.
    this.setFormDirtyState(formValuesToStore);

    // Sort by alphabetically at - Or change to what ever you want... (Sorts all lists).
    return activitiesAndCoursesList.sort((a: any, b: any) => a.start_at - b.start_at)
  }))

  /** 
  * Set walkthrough state. 
  * @param pageName   Name of page.
  * @param value      Boolean - (Has been visited or not).
  */
  setWalkthroughStateHandler(pageName: string, value: boolean) {
    WalkthroughService.setWalkthroughState(pageName, value)
  }

  /** 
  * Re initialize and specify step.
  * @param stepNumber   stepNumber: string.
  */
  initPosition = (stepNumber: any) => this.copilot.checkInit(stepNumber);

  /** 
  * Next step.
  * @param stepNumber   stepNumber: string.
  */
  nextStep = (stepNumber: any) => this.copilot.next(stepNumber);

  /** 
  * Finish copilot walkthroughs.
  */
  done = () => this.copilot.removeWrapper();

  /** 
  * Start walkthroughs.
  */
  startWalkthrough() {
    if (!WalkthroughService.isWalkthroughComplete(this.pageLabel) && !WalkthroughService.allWalkthroughsDisabled()) {
      setTimeout(() => {
        this.copilot.checkInit('18');
      }, 1000);
    }
    this.displayHelpIcon = !WalkthroughService.allWalkthroughsDisabled();
  }

  /** 
  * Resets form.
  */
  resetForm() {
    this.store.dispatch(new appStore.FindActivitiesFilterStateSet(undefined));
    this.filterForm.reset()
    this.setDefaultFormValues();
  }

  /** 
  * Sets form dirty state.
  */
  setFormDirtyState(formValuesToStore) {
    Object.values(this.filterForm.controls).map(value => {
      if (value.dirty) {
        this.store.dispatch(new appStore.FindActivitiesFilterStateSet(formValuesToStore));
        this.isDirtyForm = true;
      }
    });
  }

  /** 
  * Sets skills list
  */
  setSkillsList() {
    let skills = MyUtil.getSkills()
    for (const key in skills) {
      if (skills.hasOwnProperty(key)) {
        this.skills.push(skills[key])
      }
    }
  }

  /*
  Tab select modal handler.
  */
  public async tabSelectModalHandler(currentTab: string = '') {

    if(this.scheduledActivitiesCount > 0 && this.selfStudyActivitiesCount > 0) {
      let fortnightAwayFromTabStateSet = new Date(this.findActivitiesTabState?.timestamp + 12096e5).getTime();
      let now = Date.now();

      // Open modal if less that two weeks from last set.
      if(now > fortnightAwayFromTabStateSet || !this.findActivitiesTabState?.timestamp){     

        let modal = MyUtil.createModal(FindActivitiesModalComponent, {
          scheduledCount: this.scheduledActivitiesCount,
          selfStudyCount: this.selfStudyActivitiesCount
        });

        (await modal).onDidDismiss().then((data: any) => { 
          if (data.data !== undefined) {
            this.modalShown = true;
            this.setSelectedTabModal(data.data);
            this.findActivitiesTabState = JSON.parse(MyUtil.retrieveFromLocalStorage('findActivitiesTabState'));
          } 
        });

        (await modal).present();
      }
    } else if(currentTab == 'scheduled' && this.selfStudyActivitiesCount > 0) {
      this.setSelectedTabModal('self-study');
    } else if (currentTab == 'self-study' && this.scheduledActivitiesCount > 0) {
      this.setSelectedTabModal('scheduled');
    }
    // Update current view when selected
  }

  // On init.
  async ngOnInit() {

    this.loading = true;

    if (!this.appapi.isLoggedIn()) {
      return;
    }

    this.homeOrg = MyUtil.getRootOrganization();
    let orgDetails = MyUtil.getAppOrg(this.homeOrg.id);
    let dateFormat = MyUtil.formatPHPDate(orgDetails[0].org_date_format);
    if(dateFormat == 'mm/dd/yyyy') {
      this.dateAdapter.setLocale('en-US');
    }

    this.activityListItems$.pipe(takeUntil(this.destroy$)).subscribe();

    if(this.findActivitiesTabState){
      this.setSelectedTab(this.findActivitiesTabState.tab);
    }

    this.activityFilterState$.pipe(takeUntil(this.destroy$)).subscribe((findActivitiesFilters: FindActivitiesFilters) => {
      this.findActivitiesFilters = findActivitiesFilters;
    });

    // Set activity booking status.
    this.activityService.listBookingStatus().then((bookingStatusList: BookingStatusVO[]) => {
      this.bookingStatusList$.next(bookingStatusList);
    });

    // Set activity booking status.
    this.courseService.listBookingStatus().then((courseBookingStatusList: BookingStatusVO[]) => {
      this.courseBookingStatusList$.next(courseBookingStatusList);
    });

    // need to remove the self-study option
    this.attendanceTypes = this.attendanceTypes.filter(item => {
      if(item.key != 3) {
        return item;
      }
    });

    // Sync and get activities and courses.
    await this.appapi.checkAndRefreshProfile().then(async () => {
      await this.appapi.syncAllActivities().then(async () => {
        /// Fill course list.
        await this.courseService.getAvailableCourses().then(async (res: ServerAPIResponseVO) => {
          let courseList = [];
          let inList = [];
          if (res.data) {
            Object.keys(res.data).forEach((prop) => {
              if (!inList.includes(res.data[prop].id)) {
                inList.push(res.data[prop].id)

                // Object display setup.
                res.data[prop].period = this.activityService.getShortDisplayDateTime(orgDetails[0], 
                  res.data[prop].activityDateRange.start_date, 
                  res.data[prop].start_time, 
                  res.data[prop].activityDateRange.end_date, 
                  res.data[prop].end_time)

                res.data[prop].start_at = res.data[prop].activityDateRange.start_date;
                res.data[prop].end_at = res.data[prop].activityDateRange.end_date;
                res.data[prop].attendance_type = res.data[prop].attendance_type;
                res.data[prop].courseFlag = true;

                courseList.push(res.data[prop]);
              }
            });
          }

          await this.courseService.getAvailableAffiliateCourses().then(async (res) => {

            if (res.data) {
              Object.keys(res.data).forEach((prop) => {
                if (!inList.includes(res.data[prop].id)) {
                  inList.push(res.data[prop].id)
                  
                  // Object display setup for affiliate courses.
                  res.data[prop].period = this.activityService.getShortDisplayDateTime(orgDetails[0], 
                    res.data[prop].activityDateRange.start_date, 
                    res.data[prop].start_time, 
                    res.data[prop].activityDateRange.end_date, 
                    res.data[prop].end_time)
                  
                  res.data[prop].start_at = res.data[prop].activityDateRange.start_date;
                  res.data[prop].end_at = res.data[prop].activityDateRange.end_date;
                  res.data[prop].courseFlag = true;
                  res.data[prop].affFlag = true;

                  courseList.push(res.data[prop]);
                }
              });
            }
          });
          this.courseList$.next(courseList);
        });

        // Fill activity lists.
        let mergedActivities: any[] = Object.values({ ...MyUtil.cache[MyUtil.DOC_ID.ACTIVITIES], ...MyUtil.cache[MyUtil.DOC_ID.FUNDING_ACTIVITIES] });
        mergedActivities.map((item, k) => {

          item.period = this.activityService.getShortDisplayDateTime(orgDetails[0], 
            item.start_at, 
            item.start_time, 
            item.end_at, 
            item.end_time)

          item.start_time_date = MyUtil.formatUnixTimeStampDate(item.start_at, dateFormat);
          item.end_time_date = MyUtil.formatUnixTimeStampDate(item.end_at, dateFormat);

          item.organization = MyUtil.getOrgnizationChainName(item.oid);
          let org = MyUtil.cache[MyUtil.DOC_ID.USER_ORGANIZATIONS][item.oid];
          if (org) {
            item.level = org.level;
          } else {
            item.level = 0;
            MyUtil.error('Not found org: ' + item.oid + ' for activity: [' + item.id + '] ' + item.name);
          }
          
          return item;
        });

        this.activitiesList$.next(mergedActivities);

        await this.appapi.queryUserActivity().then((result: UserActivityVO[]) => {
          this.myActivitiesList$.next(result);
        });
      })

      let fundingOrgs = (MyUtil.cache[MyUtil.DOC_ID.USER_FUNDING_ORGANIZATIONS]) ? MyUtil.cache[MyUtil.DOC_ID.USER_FUNDING_ORGANIZATIONS] : {};
      this.organisations = [...MyUtil.getOrgnizationChain(), ...MyUtil.getContentSharingOrganizations(), ...Object.values(fundingOrgs)].filter((a) => a.id);

    }).then(async () => {
      this.loading = false;
      // Open tab selection popup
    
    });

    MyUtil.firebaseSetScreenName('activity-list');
    MyUtil.firebaseLogEvent('view_did_enter', { name: 'activity-list', data: this.navParams.data });

    // Set route data.
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData = JSON.parse(params.pageData);
      // Set the skills array ready for populating the lists.
      this.setSkillsList();
    });

    // Start walkthrough.
    this.startWalkthrough();

    // Set Form Default Values.
    //this.resetForm();
    this.setFormValues();
  }

  ngAfterContentInit(): void {
   
  }

  ionViewDidLeave() {
    MyUtil.firebaseLogEvent('view_did_leave', { name: 'activity-list', data: this.navParams.data });
  }

  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  * @param item     Parameter to process in action.
  */
  process(action: string, item?: any) {
    switch (action) {
      case 'view-activity':
        this.router.navigate(['/ActivityDetailPage', JSON.stringify({ id: item.id })]);
        return;
      case 'create-activity':
        this.router.navigate(['/ActivityEditPage', '{}']);
        return;
      case 'view-course':
        this.router.navigate(['/CourseDetailPage', JSON.stringify({ courseID: item.id })]);
        return;
      default:
        MyUtil.presentToast('"' + action + '" is not handled', { cssClass: 'inkpath-toast' });
        return;
    }
  }


  // Set selected tab
  setSelectedTab(tabName) {
    this.tabSelection$.next(tabName);

    // Set tab in storage but keep timestamp of modal click.
    let findActivitiesTabState:findActivitiesTabStateVO = {
      tab: tabName,
      timestamp: this.findActivitiesTabState?.timestamp
    }

    MyUtil.saveToLocalStorage('findActivitiesTabState', JSON.stringify(findActivitiesTabState));
    this.findActivitiesTabState = JSON.parse(MyUtil.retrieveFromLocalStorage('findActivitiesTabState'));
  }


  // Set selected tab
  setSelectedTabModal(tabName) {
    this.tabSelection$.next(tabName);
  }

  // Set Default Form Values.
  setDefaultFormValues() {

    let defaultOrganisations = ["all"];
    this.organisations.forEach((organisation) => {
      defaultOrganisations.push(organisation.id)
    })

    let defaultAttendance = ["all"];
    this.attendanceTypes.forEach((attendance) => {
      defaultAttendance.push(attendance.key)
    })

    let defaultSkills = ["all"];
    this.isDirtyForm = false;
    this.skills.forEach((skill) => {
      defaultSkills.push(skill.id)
    });

    // Set form controls.
    this.filterForm.controls.organisations.setValue(defaultOrganisations);
    this.filterForm.controls.attendanceType.setValue(defaultAttendance);
    this.filterForm.controls.skills.setValue(defaultSkills);

    const nowTimestamp = Math.floor(MyUtil.getUnixTimeStamp() / 86400) * 86400;
    // let defaultStart = (this.routeData.start_at) ? MyUtil.formatUnixTimeStamp2ISO(this.routeData.start_at * 1000) : MyUtil.formatUnixTimeStamp2ISO(MyUtil.getUnixTimeStamp() - (86400 * 60));
    let defaultStart = (this.routeData.start_at) ? MyUtil.formatUnixTimeStamp2ISO(this.routeData.start_at * 1000) : MyUtil.formatUnixTimeStamp2ISO(nowTimestamp);
    let defaultEnd = MyUtil.formatUnixTimeStamp2ISO(MyUtil.getUnixTimeStamp() + (86400 * 365 * 75));
    this.filterForm.controls.start.setValue(defaultStart);
    this.filterForm.controls.end.setValue(defaultEnd);
    this.filterForm.markAsDirty();
  }


  setDefaultFormValuesFromRouteData() {

    let defaultOrganisations = ["all"];
    this.organisations.forEach((organisation) => {
      defaultOrganisations.push(organisation.id)
    })

    let defaultAttendance = ["all"];
    this.attendanceTypes.forEach((attendance) => {
      defaultAttendance.push(attendance.key)
    })

    let defaultSkills = [];
    if (this.routeData.skills.length > 0) {
      defaultSkills = this.routeData.skills;
      defaultSkills.unshift("all");
    }

    // Set form controls.
    this.filterForm.controls.organisations.setValue(defaultOrganisations);
    this.filterForm.controls.attendanceType.setValue(defaultAttendance);
    this.filterForm.controls.skills.setValue(defaultSkills);

    const nowTimestamp = Math.floor(MyUtil.getUnixTimeStamp() / 86400) * 86400;

    // let defaultStart = (this.routeData.start_at) ? MyUtil.formatUnixTimeStamp2ISO(this.routeData.start_at * 1000) : MyUtil.formatUnixTimeStamp2ISO(MyUtil.getUnixTimeStamp() - (86400 * 60));
    let defaultStart = (this.routeData.start_at) ? MyUtil.formatUnixTimeStamp2ISO(this.routeData.start_at * 1000) : MyUtil.formatUnixTimeStamp2ISO(nowTimestamp);
    let defaultEnd = MyUtil.formatUnixTimeStamp2ISO(MyUtil.getUnixTimeStamp() + (86400 * 365 * 3));
    this.filterForm.controls.start.setValue(defaultStart);
    this.filterForm.controls.end.setValue(defaultEnd);
    this.filterForm.markAsDirty();
  }


  setDirtyFormValues() {
    this.isDirtyForm = true;
    this.filterForm.controls.searchTerm.setValue(this.findActivitiesFilters.searchTerm);
    this.filterForm.controls.organisations.setValue(this.findActivitiesFilters.organisations);
    this.filterForm.controls.attendanceType.setValue(this.findActivitiesFilters.attendanceType);
    this.filterForm.controls.skills.setValue(this.findActivitiesFilters.skills);
    this.filterForm.controls.start.setValue(this.findActivitiesFilters.start);
    this.filterForm.controls.end.setValue(this.findActivitiesFilters.end);
  }

  setFormValues() {

    switch (true) {
      case !MyUtil.lodash.isEmpty(this.routeData):
        this.setDefaultFormValuesFromRouteData();
        break;

      case !this.findActivitiesFilters:
        this.setDefaultFormValues();
        break;

      default:
        this.setDirtyFormValues();
        break;
    }

  }

  toggleSelectAllOrgs(event: MatOptionSelectionChange) {

    if(event.isUserInput) {
      let defaultOrganisations = [];
      if(event.source.selected) {
        defaultOrganisations = ["all"];
        this.organisations.forEach((organisation) => {
          defaultOrganisations.push(organisation.id)
        });
      }

      this.filterForm.controls.organisations.setValue(defaultOrganisations);
      this.filterForm.markAsDirty();
      this.setFilterCount(defaultOrganisations, 'organisations');
    }
  }

  toggleSelectAllAttendance(event: MatOptionSelectionChange) {

    if(event.isUserInput) {
      let defaultAttendance = [];
      if(event.source.selected) {
        defaultAttendance = ["all"];
        this.attendanceTypes.forEach((attendance) => {
          defaultAttendance.push(attendance.key)
        });
      }
        
      this.filterForm.controls.attendanceType.setValue(defaultAttendance);
      this.filterForm.markAsDirty();
      this.setFilterCount(defaultAttendance, 'attendanceType');
    }
  }

  toggleSelectAllSkills(event: MatOptionSelectionChange) {
    if(event.isUserInput) {
      let defaultSkills = [];
      if(event.source.selected) {
        if (this.skills.length > 0) {
          defaultSkills = ["all"];
          this.skills.forEach((skill) => {
            defaultSkills.push(skill.id)
          });
        }
      }

      this.filterForm.controls.skills.setValue(defaultSkills);
      this.filterForm.markAsDirty();
      this.setFilterCount(defaultSkills, 'skills');
    }
  }

  unsetSelectAll(event: MatOptionSelectionChange, type: string) {

    if(event.isUserInput) {
      let control = null;
      if(type === 'organisations') {
        control = this.filterForm.controls.organisations;
      } else if(type == 'attendanceType') {
        control = this.filterForm.controls.attendanceType;
      } else if(type === 'skills') {
        control = this.filterForm.controls.skills;
      }


      let selectedValues : Array<any> = [].concat(control.value);
      if(!event.source.selected) {
        if(selectedValues.indexOf('all') !== -1) {
          selectedValues.splice(selectedValues.indexOf('all'), 1);
        };
      
        if(selectedValues.indexOf(event.source.value) !== -1) {
          selectedValues.splice(selectedValues.indexOf(event.source.value), 1);
        }

        control.setValue(selectedValues);
      } else {
        selectedValues.push(event.source.value)
      }
      
      this.setFilterCount(selectedValues, type);
    }
  }

  private setFilterCount(selectedValues, type: string) {

    let filterCount = 'all';
    if(selectedValues.indexOf('all') === -1) {
      if(selectedValues.length === 0) {
        filterCount = 'none';
      } else if(selectedValues.length === 1) {
        filterCount = '1';
      } if(selectedValues.length > 1) {
        filterCount = '1+';
      }
    }

    if(type === 'organisations') {
      this.orgFilterCount = filterCount;
    } else if(type == 'attendanceType') {
      this.attendanceFilterCount = filterCount;
    } else if(type === 'skills') {
      this.skillsFilterCount = filterCount;
    }
  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}